import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		GrossMargin: {
			addcomhq: 0,
			basicgrossmargin: 0,
			calculation_id: 1,
			carriage: 0,
			carriagecurrency_id: 2,
			comcurrency_id: 2,
			commission: 0,
			contract_id: null,
			currency_id: 2,
			currencydate: null,
			currencyrate: 1,
			customer_id: null,
			debitnote_id: null,
			etadate: null,
			etddate: null,
			freightcurrency_id: 2,
			freightstorage: 0,
			grossmargin: 0,
			hasNewAgentCommission: false,
			id: null,
			inputtype_id: 1,
			latepaycurrency_id: 2,
			latepaymentcost: 0,
			localsale_id: null,
			mincomhq: 0,
			mincomsub: 0,
			office_id: 1,
			othercost: 0,
			overdue: null,
			paymenttermcost: 0,
			paytermcurrency_id: 2,
			purchase: 0,
			purchasediscount: 0,
			salescontact_id: null,
			selling: 0,
			sellingdiscount: 0,
			shortpaycurrency_id: 2,
			shortpayment: 0,
			source: 'invoice',
			supplier_id: null,
			title: null,
			usdrate: 1,
			version: null,
			volume: 0,
			warehousecost: 0,
			whcostcurrency_id: 2,
			Contract: {
				id: null,
				title: null,
			},
			Invoice: {
				id: null,
				invno: null
			},
			Localsale: {
				id: null,
				title: null
			},
			Payment: {
				id: null
			},
			Species: {
				names: []
			},
			Var: {
				banklendingratepa: 0.07,
				latepayment_14_days: 0,
				latepayment_14_to_30_days: 0.02,
				latepayment_30_days: 0.010,
				paymentterm_30_days: 0.005,
				paymentterm_45_days: 0.010,
				paymentterm_60_days: 0.015,
				warehousecost: 0.05,

				//added 2022-08-01
				latepayment_v2_14_days: 0,
				latepayment_v2_15_30_days: 0.005,
				latepayment_v2_31_60_days: 0.01,
				latepayment_v2_61_days: 0.02
			},
			Agent: {
				addcomhq: 0,
				has_commission: 0,
				mincomhq: 0,
				mincomsub: 0,
				title: null,
				CommissionValue: {
					addcomhqamount: 0,
					addcomsubamount: 0,
					gmpct: 0,
					grossmarginhq: 0,
					grossmarginsub: 0,
					minbalance: 0,
					mincomhq: 0,
					mincomhqamount: 0,
					mincomsub: 0,
					mincomsubamount: 0
				}
			},
		}
	}
}

const state = {
	current: {
		GrossMargin: {
			// insurance,
			// insurancecurrency_id,
			// othercurrency_id,
			addcomhq: 0,
			basicgrossmargin: 0,
			calculation_id: 1,
			carriage: 0,
			carriagecurrency_id: 2,
			comcurrency_id: 2,
			commission: 0,
			contract_id: null,
			currencydate: null,
			currency_id: 2,
			currencyrate: 1,
			customer_id: null,
			debitnote_id: null,
			etadate: null,
			etddate: null,
			freightcurrency_id: 2,
			freightstorage: 0,
			grossmargin: 0,
			hasNewAgentCommission: false,
			id: null,
			inputtype_id: 1,
			latepaycurrency_id: 2,
			latepaymentcost: 0,
			localsale_id: null,
			mincomhq: 0,
			mincomsub: 0,
			office_id: 1,
			othercost: 0,
			overdue: null,
			paymenttermcost: 0,
			paytermcurrency_id: 2,
			purchase: 0,
			purchasediscount: 0,
			salescontact_id: null,
			selling: 0,
			sellingdiscount: 0,
			shortpaycurrency_id: 2,
			shortpayment: 0,
			source: 'invoice',
			supplier_id: null,
			title: null,
			usdrate: 1,
			version: null,
			volume: 0,
			warehousecost: 0,
			whcostcurrency_id: 2,
			Contract: {
				id: null,
				title: null,
			},
			Invoice: {
				id: null,
				invno: null
			},
			Localsale: {
				id: null,
				title: null
			},
			Payment: {
				id: null
			},
			Species: {
				names: []
			},
			Var: {
				banklendingratepa: 0.07,
				latepayment_14_days: 0,
				latepayment_14_to_30_days: 0.02,
				latepayment_30_days: 0.010,
				paymentterm_30_days: 0.005,
				paymentterm_45_days: 0.010,
				paymentterm_60_days: 0.015,
				warehousecost: 0.05,

				//added 2022-08-01
				latepayment_v2_14_days: 0,
				latepayment_v2_15_30_days: 0.005,
				latepayment_v2_31_60_days: 0.01,
				latepayment_v2_61_days: 0.02
			},
			Agent: {
				addcomhq: 0,
				has_commission: 0,
				mincomhq: 0,
				mincomsub: 0,
				title: null,
				CommissionValue: {
					addcomhqamount: 0,
					addcomsubamount: 0,
					gmpct: 0,
					grossmarginhq: 0,
					grossmarginsub: 0,
					minbalance: 0,
					mincomhq: 0,
					mincomhqamount: 0,
					mincomsub: 0,
					mincomsubamount: 0
				}
			}
		}
	},
	update: {
		GrossMargin: {
			addcomhq: 0,
			basicgrossmargin: 0,
			calculation_id: 1,
			carriage: 0,
			carriagecurrency_id: 2,
			comcurrency_id: 2,
			commission: 0,
			contract_id: null,
			currency_id: 2,
			currencydate: null,
			currencyrate: null,
			customer_id: null,
			debitnote_id: null,
			etadate: null,
			etddate: null,
			freightcurrency_id: 2,
			freightstorage: 0,
			grossmargin: 0,
			hasNewAgentCommission: false,
			id: null,
			inputtype_id: 1,
			latepaycurrency_id: 2,
			latepaymentcost: 0,
			localsale_id: null,
			mincomhq: 0,
			mincomsub: 0,
			office_id: 1,
			othercost: 0,
			paymenttermcost: 0,
			paytermcurrency_id: 2,
			purchase: 0,
			purchasediscount: 0,
			salescontact_id: null,
			selling: 0,
			sellingdiscount: 0,
			shortpaycurrency_id: 2,
			shortpayment: 0,
			source: null,
			supplier_id: null,
			title: null,
			usdrate: 1,
			version: null,
			volume: 0,
			warehousecost: 0,
			whcostcurrency_id: 2,
			Invoice: {
				currencyrate: 1,
				id: null,
				invno: null
			},
			Localsale: {
				currencyrate: 1,
				id: null,
				title: null
			},
			Payment: {
				id: null
			},
			Species: {
				names: []
			},
			Var: {
				banklendingratepa: 0.07,
				latepayment_14_days: 0,
				latepayment_14_to_30_days: 0.02,
				latepayment_30_days: 0.010,
				paymentterm_30_days: 0.005,
				paymentterm_45_days: 0.010,
				paymentterm_60_days: 0.015,
				warehousecost: 0.05
			},
			Agent: {
				addcomhq: 0,
				has_commission: 0,
				mincomhq: 0,
				mincomsub: 0,
				title: null,
				CommissionValue: {
					addcomhqamount: 0,
					addcomsubamount: 0,
					gmpct: 0,
					grossmarginhq: 0,
					grossmarginsub: 0,
					minbalance: 0,
					mincomhq: 0,
					mincomhqamount: 0,
					mincomsub: 0,
					mincomsubamount: 0
				}
			}
		}
	},
	newGrossMarginSaved: false,
	newGrossMarginUnsaved: false,
	updatedGrossMarginUnsaved: false,
	updatedGrossMarginSaved: false
}

const getters = {
	getField
}

const mutations = {
	resetCurrentState (state) {
		console.log('Resetting this Gross Margin Calculation')
		state.current.GrossMargin = getDefaultState().GrossMargin
		state.newGrossMarginUnsaved = false
		state.newGrossMarginSaved = false
	},
	resetState (state){
		console.log('Resetting Gross Margin Calculations...')
	},
	setAgentCommissionValues(state, payload) {
		let prefix = payload.prefix
		let agentCommissionValues = payload.agentCommissionValues
		state[prefix].GrossMargin.Agent.CommissionValue = agentCommissionValues
		if(agentCommissionValues['grossmarginhq'] > 0 && agentCommissionValues['grossmarginhq'] != state[prefix].GrossMargin.grossmargin){
			state[prefix].GrossMargin.grossmargin = agentCommissionValues['grossmarginhq']
		}
		if(agentCommissionValues['mincomhq'] > 0 && agentCommissionValues['mincomhq'] != state[prefix].GrossMargin.mincomhq){
			state[prefix].GrossMargin.mincomhq = agentCommissionValues['mincomhq']
		}
		if(agentCommissionValues['mincomsub'] > 0 && agentCommissionValues['mincomsub'] != state[prefix].GrossMargin.mincomsub){
			state[prefix].GrossMargin.mincomsub = agentCommissionValues['mincomsub']
		}
		if(agentCommissionValues['addcomhq'] > 0 && agentCommissionValues['addcomhq'] != state[prefix].GrossMargin.addcomhq){
			state[prefix].GrossMargin.addcomhq = agentCommissionValues['addcomhq']
		}
	},
	setGrossMarginToBeUpdated (state, data) {
		state.update.GrossMargin = data.GrossMargin
	},
	updateField,
	updateGrossMarginField(state, payload) {
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix][keys[0]][keys[1]] = payload['value']
	}
}

const actions = {
	calculateAgentCommission({commit},payload) {
		let grossMarginId = payload.grossMarginId
		return new Promise((resolve, reject) => {
			api
				.get('/grossmargins/' + grossMarginId + '/agent-commission')
				.then(response => {
					let commitPayload = {
						prefix: payload.prefix,
						agentCommissionValues: response.data.data
					}
					commit('setAgentCommissionValues', commitPayload);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	createGrossMargin ({state, rootState}, source){
		let grossMargin = _.cloneDeep(state.current.GrossMargin)
		//remove unneeded properties
		delete grossMargin.Agent
		delete grossMargin.Invoice
		delete grossMargin.Localsale
		delete grossMargin.Payment
		delete grossMargin.Species
		delete grossMargin.Var
		delete grossMargin.basicgrossmargin

		return new Promise((resolve, reject) => {
			api
				.post('/grossmargins',{
						grossmargin: grossMargin,
						source: source,
					}
				)
				.then((response) => {
					if(response.data.status == 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllGrossMargins ({commit}, payload) {
		let filterOptions = payload.filterOptions
		let conditions = []

		if (filterOptions.buyer) {
			let obj = {
				field: 'customer_id',
				value: filterOptions.buyer
			}
			conditions.push(obj)
		}
		if(filterOptions.month && filterOptions.year){
			let endDate = new Date(filterOptions.year, filterOptions.month,0)
			let obj = {
				field: 'date',
				value: {
					from: [filterOptions.year.toString(),filterOptions.month.toString().padStart(2,'0'),'01'].join('-'),
					to: [endDate.getFullYear(),(endDate.getMonth() + 1).toString().padStart(2,'0'), endDate.getDate().toString().padStart(2,'0')].join('-')
				}
			}
			conditions.push(obj)
		} else if(filterOptions.month == null && filterOptions.year){
			let obj = {
				field: 'date',
				value: {
					from: [filterOptions.year.toString(),'01','01'].join('-'),
					to: [filterOptions.year.toString(),'12','31'].join('-')
				}
			}
			conditions.push(obj)
		}
		if (filterOptions.office) {
			let obj = {
				field: 'office_id',
				value: filterOptions.office
			}
			conditions.push(obj)
		}

		return new Promise((resolve, reject) => {
			api
				.get("/grossmargins",{
					params: {
						conditions: conditions
					}
				})
				.then(response => {
					let tableData = {
						rows: response.data.data,
						totalRows: response.data.totalRows
					}
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getGrossMarginToUpdateById ({commit},grossmargin_id) {
		return new Promise((resolve, reject) => {
			api
				.get('/grossmargins/' + grossmargin_id)
				.then(response => {
					let grossMargin = response.data.data[0]
					commit('setGrossMarginToBeUpdated', grossMargin);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	resetCurrentGrossMargin ({commit}){
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	updateGrossMargin ({state, rootState}){
		let grossMargin = _.cloneDeep(state.update.GrossMargin)
		//remove unneeded properties
		delete grossMargin.Agent
		delete grossMargin.Contract
		delete grossMargin.Invoice
		delete grossMargin.Localsale
		delete grossMargin.Payment
		delete grossMargin.Species
		delete grossMargin.Var
		delete grossMargin.basicgrossmargin

		return new Promise((resolve, reject) => {
			api
				.put("/grossmargins/" + state.update.GrossMargin.id,{
					grossmargin: grossMargin,
				})
				.then(response => {
					this.updatedGrossMarginSaved = true
					this.updatedGrossMarginUnsaved = false
					resolve('done')
				})
				.catch(error => {
					reject(error)
					resolve(error)
				})
				.then(()=>{
					resolve('done')
				});
		})
	}
}

export const grossmargin = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}