import { api } from "../../../api";
import {getField, updateField} from "vuex-map-fields";
import {isNumeric} from "Helpers/helpers";
import _ from 'lodash';

const namespaced = {
	namespaced: true
}

const getOtherChargesRow = () => {
	return {
		index: 0,
		lineNumber: 1,
		Grade: {
			id: 39,
			title: 'Other Charges'
		},
		Lmeasurement: {
			id: null,
			title: ''
		},
		Options: {
			grades: [],
			species: [],
			specs: []
		},
		Smeasurement: {
			id: null,
			title: ''
		},
		Soldmeasurement: {
			id: null,
			title: ''
		},
		Sosalesdescription: {
			id: null,
			title: ''
		},
		Spec: {
			id: null,
			title: null,
		},
		Species: {
			id: null,
			title: ''
		},
		Stock: {
			adjusted_grading_cost: 0,
			bundleno: null,
			costamount: 0,
			costamount_converted: 0,
			costprice: 0,
			forwardingcharge: 0,
			fsc: null,
			fsc_description: null,
			grade_id: 39,
			id: null,
			invoiceamount: 0,
			invoiceamount_converted: 0,
			length: null,
			piece: 0,
			productgroup_id: null,
			sellingprice: 0,
			sellingprice_converted: 0,
			sheets: 0,
			size: 'Other Charges',
			size_id: null,
			species_id: null,
			thick: null,
			totalcost: 0,
			volume: 1,
			warehousecost:0,
			wh_pallet_no: null,
			width: null
		},
		Stockorder: {
			id: null,
			currency_id: 1,
			currencyrate: 1,
			title: ''
		},
		Wmeasurement: {
			id: null,
			title: ''
		},
		type: 'othercharge'
	}
}

const getDefaultState = () => {
	return {
		Stock: {
			id: null,
			adjusted_grading_cost: 0,
			bundleno: null,
			container_id: null,
			costprice: 0,
			forwardingcharge: 0,
			fsc: null,
			fsc_description: null,
			grade_id: null,
			grossweight: 0,
			length: null,
			lmeasurement_id: null,
			localsale_id: null,
			localsalesorder_id: null,
			margin: 0,
			mc_id: null,
			measurement_id: null,
			measurementrate: 1.0,
			netweight: 0,
			parent_id: null,
			piece: 0,
			productgroup_id: null,
			salesdescription: null,
			salesdescription_id: null,
			salesitem_id: null,
			sellingprice: 0,
			sheets: 0,
			size: null,
			size_id: null,
			smeasurement_id: null,
			soldmeasurement_id: null,
			sosalesdescription_id: null,
			species_id: null,
			speciesgroup_id: null,
			stock_country_id: null,
			stock_id: null,
			stockdescription: null,
			stockorder_id: null,
			stockstate_id: null,
			thick: null,
			tonnage: 0,
			unitvolume: 0.0,
			volume: 0,
			warehousecost: 0,
			width: null,
			wh_pallet_no: null,
			wmeasurement_id: null,
			SalesDescription: {
				id: null,
				title: null,
				localsalesorder_id: null
			}
		},
		Options: {
			grades: [],
			species: [],
			specs: []
		}
	}
}

const state = {
	current: {
		SalesOrderItems: [
			{
				Stock: {
					StockOrder: {
						currency_id: 2
					}
				},
				Productgroup: {
					id: null,
					title: null
				}
			}
		],
		itemsTotalAmount: 0,
		itemsTotalPieces: 0,
		itemsTotalSheets: 0,
		itemsTotalQty: []
	},
	update: {
		SalesOrderItems: [],
		itemsTotalAmount: 0,
		itemsTotalPieces: 0,
		itemsTotalSheets: 0,
		itemsTotalQty: []
	}
}

const getters = {
	getField
}

const mutations = {
	addOtherChargesItemRow (state, prefix) {
		let i = 0;
		let newItem = getOtherChargesRow()
		newItem.Stock.stock_id = null
		newItem['Options'] = {
			grades: [],
			species: [],
			specs: []
		}
		state[prefix].SalesOrderItems.push(newItem)
		state[prefix].SalesOrderItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	addSalesOrderItemRows( state, payload ) {
		let i = 0;
		let bundles = payload.bundles
		let prefix = payload.prefix
		let salesOrder = payload.salesOrder
		bundles.forEach( bundle => {
			bundle.type = 'default'
			bundle.Stock.localsalesorder_id = salesOrder.SalesOrder.id
			bundle.Stock.stock_id = bundle.Stock.id
			bundle.Stock.id = null
			bundle.Stock.sosalesdescription_id = null
			bundle.Stock.salesdescription = null

			//add missing fields 2023-11-16
			//stupid but necessary for the meantime.
			bundle['Productgroup'] = {
				title: bundle.Product.title
			}
			bundle['Certification'] = {
				title: bundle.certification
			}
			
			bundle['Options'] = {
				grades: [],
				species: [],
				specs: []
			}
			state[prefix].SalesOrderItems.push(bundle)
		})
		state[prefix].SalesOrderItems.forEach(item => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	deleteSalesOrderItemRow( state, payload ) {
		state[payload.prefix].SalesOrderItems.splice(payload.index, 1)
		let i = 0;
		state[payload.prefix].SalesOrderItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			i++
		})
	},
	resetState( state, prefix ){
		console.log('Resetting Sales Order items')
		state[prefix].SalesOrderItems = []
		state[prefix].itemsTotalAmount = 0
		state[prefix].itemsTotalPieces = 0
		state[prefix].itemsTotalSheets = 0
		state[prefix].itemsTotalQty = []
	},
	setSalesOrderItems ( state, payload ) {
		state[payload.prefix].SalesOrderItems = payload.data
		let i = 0;
		state[payload.prefix].SalesOrderItems.forEach((item) => {
			item.index = i
			item.lineNumber = i + 1
			item['Options'] = {
				grades: [],
				species: [],
				specs: []
			}
			i++
		})
	},
	updateField,
	updateSalesOrderItems (state, payload){
		let keys = payload['field'].split('.')
		let prefix = payload['prefix']
		state[prefix].SalesOrderItems[payload['index']][keys[0]][keys[1]] = payload['value']
		let numberFields = ['volume','sellingprice']
		if(keys[0] === 'Stock' && numberFields.indexOf(keys[1]) !== -1){
			state[prefix].SalesOrderItems[payload['index']]['Stock']['amount'] = parseFloat(state[prefix].SalesOrderItems[payload['index']]['Stock']['volume']) * parseFloat(state[prefix].SalesOrderItems[payload['index']]['Stock']['sellingprice']);
		}
	},
	updateSalesOrderItemRow (state, payload) {
		const numberFields = ['volume','sellingprice']
		const prefix = payload['prefix']
		const itemIndex = payload['index']
		const stock = payload['stock']
		const stockProperties = Object.keys(stock)

		const spec = payload['spec']
		const species = payload['species']
		const mc = payload['mc']
		const grade = payload['grade']
		const certification = payload['certification']
		const productGroup = payload['productGroup']

		stockProperties.forEach((stockProperty) => {
			state[prefix].SalesOrderItems[itemIndex]['Stock'][stockProperty] = stock[stockProperty]
			if(numberFields.indexOf(stockProperty) !== -1){
				const amount = parseFloat(state[prefix].SalesOrderItems[itemIndex]['Stock']['volume']) * parseFloat(state[prefix].SalesOrderItems[itemIndex]['Stock']['sellingprice'])
				state[prefix].SalesOrderItems[itemIndex]['Stock']['amount'] = amount
			}
		})
		if(spec.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Spec'] = spec
		}
		if(species.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Species'] = species
		}
		if(mc.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Mc'] = mc
		}
		if(grade.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Grade'] = grade
		}
		if(certification.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Certification'] = certification
		}
		if(productGroup.id != null) {
			state[prefix].SalesOrderItems[itemIndex]['Productgroup'] = productGroup
		}
	},
	updateSalesOrderItemRows (state, payload) { //multiple rows. used for bulk price updates 2023-11-10
		const itemIndexes = payload['indexes']
		const prefix = payload['prefix']
		const values = payload['values']
		const stockProperties = Object.keys(values)
		itemIndexes.forEach((itemIndex) => {
			stockProperties.forEach((stockProperty) => {
				state[prefix].SalesOrderItems[itemIndex]['Stock'][stockProperty] = values[stockProperty]
			})
			state[prefix].SalesOrderItems[itemIndex]['Stock']['amount'] = parseFloat(state[prefix].SalesOrderItems[itemIndex]['Stock']['volume']) * parseFloat(state[prefix].SalesOrderItems[itemIndex]['Stock']['sellingprice']);
		})
	},
	updateTotals ( state, prefix ) {
		let amount = 0, pieces = 0, sheets = 0, quantities = []
		state[prefix].SalesOrderItems.forEach((item) => {
			amount += isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0
			pieces += isNumeric(item.Stock.piece) ? parseInt(item.Stock.piece) : 0
			sheets += isNumeric(item.Stock.sheets) ? parseInt(item.Stock.sheets) : 0

			if(item.type === 'default') {
				const idx = quantities.findIndex(t => t.Total.uofm === item.Soldmeasurement.title);
				if (idx !== -1) {
					quantities[idx]['Total']['qty'] += isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
				} else {
					let obj = {'Total': {}}
					obj['Total']['uofm'] = item.Soldmeasurement.title;
					obj['Total']['qty'] = isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
					quantities.push(obj)
				}
			}
		})
		state[prefix].itemsTotalAmount = amount
		state[prefix].itemsTotalPieces = pieces
		state[prefix].itemsTotalSheets = sheets

		state[prefix].itemsTotalQty = quantities
		console.log('updating totals...')
	}
}

const actions = {
	addOtherChargesItemRow ({commit}, prefix) {
		return new Promise ((resolve, reject) => {
			commit('addOtherChargesItemRow',prefix)
			resolve('done')
		})
	},
	addSalesOrderItemRows ( {commit}, payload ) {
		return new Promise ((resolve, reject) => {
			commit('addSalesOrderItemRows', payload)
			resolve('done')
		})
	},
	deleteSalesOrderItem ({commit},payload){
		return new Promise((resolve, reject) => {
			let salesOrderId = payload.salesOrderId
			let itemId = payload.itemId
			api
				.delete('/localsalesorders/' + salesOrderId + '/salesitems/' + itemId)
				.then(response => {
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	deleteSalesOrderItemRow ({commit},payload){
		commit('deleteSalesOrderItemRow',payload)
	},
	getItemsBySalesOrder ( {commit}, payload ) {
		let salesOrderId = payload.salesOrderId
		let prefix = payload.prefix
		return new Promise((resolve, reject) => {
			api
				.get("/localsalesorders/" + salesOrderId + "/salesitems")
				.then(response => {
					let payload = {
						data: response.data.data,
						prefix: prefix
					}
					commit('setSalesOrderItems',payload)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
		})
	},
	resetSalesOrderItems ( {commit}, prefix ) {
		return new Promise ((resolve, reject) => {
			commit('resetState',prefix)
			resolve('done')
		})
	},
	updateTotals ( {commit}, prefix ) {
		return new Promise ((resolve, reject) => {
			commit('updateTotals',prefix)
			resolve('done')
		})
	}
}

export const salesorderitem = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}