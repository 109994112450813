//messages.js
let spreadElements = {
    aboutInstructor: "About Instructor",
    accountCreation: "Account Creation",
    activator: 'Activator',
    activeUsers: 'Active Users',
    activity: 'Activity',
    activityAroundWorld: 'Activity Around World',
    adCampaignPerformance: 'Ad Campaign Performance',
    add: 'Add',
    addNew: 'Add New',
    addNewBlog: 'Add New Blog',
    addNewCard: 'Add New Card',
    addNewCustomer: 'Add New Customer',
    addNewItems: 'Add New Items',
    addNewUser: 'Add New User',
    addToCart: "Add To Cart",
    addToDo: 'Add To Do',
    adsIncome: 'Ads Income',
    advance: 'Advance',
    advanced: 'Advanced',
    agency: 'Agency',
    allTime: 'All Time',
    androidUsers: 'Android Users',
    appBar: 'App Bar',
    appBarWithExtension: 'App Bar With Extension',
    applications: 'Applications',
    apply: 'Apply',
    arrowsAndDirectionsIcons: 'ARROWS & DIRECTION ICONS',
    articles: 'Articles',
    assignNow: 'Assign Now',
    asyncItems: "Async Items",
    audioTrackAndPlaysInline: "Audio Track And Plays Inline",
    averageSteps: 'Average Steps',
    backgroundImage: 'Background Image',
    backToSignIn: "Back To Sign In",
    bandwidthUsage: 'Bandwidth Usage',
    barChart: 'Bar Chart',
    baseConfig: "Base Config",
    basic: 'Basic',
    basicFree: 'Basic (Free)',
    basicValidation: 'Basic Validation',
    bestseller: "BestSeller",
    billingDetails: 'Billing Details',
    birthdays: 'Birthdays',
    blank: 'Blank',
    block: 'Block',
    blog: "Blog",
    bottom: 'Bottom',
    bounceRate: 'Bounce Rate',
    boxLayout: 'Box Layout',
    BRANDICONS: 'BRAND ICONS',
    browserStatics: 'Browser Statics',
    bubbleChart: 'Bubble Chart',
    buffer: 'Buffer',
    buttonDropdownVariants: 'Button Dropdown Variants',
    buttonGroups: "Button Groups",
    buttons: 'Buttons',
    buttonToggle: "Button Toggle",
    buyerFullPaymentNotReceived: "Payment not received",
    buyerTermMissing: "The payment term is missing. Please make sure it is set in the Contract or Sales Order.",
    bySigningUpYouAgreeTo: 'By signing up you agree to',
    calendar: 'Calendar',
    calories: 'Calories',
    campaignPerformance: 'Campaign Performance',
    cancel: 'Cancel',
    cards: 'Cards',
    carousel: 'Carousel',
    cart: 'Cart',
    categorySales: 'Category Sales',
    centeredTabs: 'Centered Tabs',
    charts: 'Charts',
    chat: 'Chat',
    checkbox: 'Checkbox',
    checkboxesArray: 'Checkboxes Array',
    checkboxesBoolean: 'Checkboxes Boolean',
    checkboxesColors: 'Checkboxes - Colors',
    checkboxesStates: 'Checkboxes - States',
    checkout: 'Checkout',
    chips: 'Chips',
    choosePlan: 'Choose Plan',
    chooseThePlanThatWorksForYou: 'Choose the plan that works for you.',
    clear: 'Clear',
    clone: 'Clone',
    close: 'Close',
    closeable: 'Closeable',
    collapse: 'Collapse',
    collapseSidebar: 'Collapse Sidebar',
    colored: 'Colored',
    coloredDots: "Colored Dots",
    comment: "Comment",
    comparePlans: 'Compare Plans',
    components: 'Components',
    composeEmail: 'Compose Email',
    composeNewEmail: 'Compose New Email',
    connections: 'Connections',
    contacts: 'Contacts',
    containAndCover: "Contain And Cover",
    content: "Content",
    contextualSnackbar: 'Contextual snackbar',
    continue: 'Continue',
    continueAsGuest: "Continue as Guest",
    continueShopping: 'Continue Shopping',
    continuous: 'Continuous',
    CONTROLICONS: 'CONTROL ICONS',
    courseDetail: "Courses Detail",
    courses: "Courses",
    coursesList: "Courses List",
    createAccount: 'Create Account',
    currentVisitors: 'Current Visitors',
    customColors: 'Custom Colors',
    customDelimiter: 'Custom Delimiter',
    customerAccess: 'Buyer Access',
    customerOverview: 'Customer Overview',
    customHeight: "Custom Height",
    customIcons: "Custom Icons",
    customizer: 'Customizer',
    customSelectableIcons: "Custom Selectable Icons",
    customTransition: 'Custom Transition',
    dailySales: 'Daily Sales',
    danger: 'Danger',
    dark: 'Dark',
    darkMode: 'Dark Mode',
    dashboard: 'Dashboard',
    dashboardOverview: 'Dashboard Overview',
    dashboardv1: 'Dashboard V1',
    dashboardv2: 'Dashboard V2',
    dateAndTime: "Date And Time",
    datepicker: 'Datepicker',
    datePickerHorizontal: 'Date Picker Horizontal',
    datePickersFormattingDate: 'Date Pickers - Formatting Date',
    datePickersInDialogAndMenu: 'Date Pickers- In Dialog And Menu',
    datePickersWithModal: 'Date Pickers With Modal',
    datePickerVertical: 'Date Picker Vertical',
    default: 'Default',
    defaultInput: 'Default Input',
    delete: 'Delete',
    deleteBundle: 'Delete Bundle',
    deletePallet: 'Delete Pallet',
    denseAlert: "Dense Alert",
    description: "Description",
    descriptionAndSize: "Description/Size",
    design: "Design",
    determinate: 'Determinate',
    development: "Development",
    deviceSeparations: 'Device Separations',
    devicesShare: 'Devices Share',
    dialog: 'Dialog',
    directional: "Directional",
    disabled: 'Disabled',
    discrete: 'Discrete',
    doughnutChart: 'Doughnut Chart',
    draft: 'Draft',
    dragAndDrop: 'Drag And Drop',
    draggableResizeable: 'Draggable Resizeable',
    dropzone: "Dropzone",
    ecommerce: 'E-Commerce',
    edit: 'Edit',
    editableNumericValue: 'Editable numeric value',
    editMember: 'Edit Member',
    editor: 'Editor',
    editUser: 'Edit User',
    email: 'Email',
    emailUserName: 'Email/Username',
    embeddedCode: 'Embedded Code',
    embedLink: 'Embed Link',
    employeePayroll: 'Employee Payroll',
    enterCardDetails: "Enter Card Details",
    enterUsernameAndPasswordToAccessControlPanelOf: 'Enter username and password to access control panel of',
    enterYourEmailToSendYouAResetLink: "Enter your email to send you a reset link",
    events: 'Events',
    expand: 'Expand',
    expenses: "Expenses",
    exportPR: 'Export PR',
    extensions: "Extensions",
    features: 'Features',
    feedback: 'Feedback',
    femaleUsers: 'Female Users',
    file: "File",
    fitnessStats: 'Fitness Stats',
    fixedRatio: "Fixed Ratio",
    fixedTabs: "Fixed Tabs",
    flatButton: 'Flat Button',
    floating: 'Floating',
    folders: 'Folders',
    follow: 'Follow',
    followers: 'Followers',
    forDeveloper: 'For developer',
    forgotPassword: "Forgot Password",
    forLargeEnterprises: 'For large enterprises',
    form: "Form",
    forMostOfTheUsers: 'For most of the users',
    forms: 'Forms',
    formValidation: 'Form Validation',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    fullScreen: 'Full Screen',
    funnelChart: 'Funnel Chart',
    gallery: 'Gallery',
    googleMaps: 'Google Maps',
    goToCampaign: 'Go To Campaign',
    gradeGroup: 'Grade Group',
    gradientButton: 'Gradient Button',
    gradientLineChart: 'Gradient Line Chart',
    gradients: "Gradients",
    grantAccess: "Grant Access",
    grid: 'Grid',
    groups: "Groups",
    grow: "Grow",
    gstVatRate: "GST/VAT Rate",
    gstVatPpnRate: "GST/VAT/PPN Rate",
    guestCheckout: "Guest Checkout",
    hardware: "Hardware",
    hardwood: "Hardwood",
    havingAnAccount: 'Having an account?',
    headerCell: 'Header Cell',
    headerFilters: 'Header Filters',
    height: "Height",
    hideControls: 'Hide Controls',
    hideDelimiters: 'Hide Delimiters',
    hlsLive: "HLS Live",
    horizontalMenu: 'Horizontal Menu',
    hotKeywords: 'Hot Keywords',
    hover: 'Hover',
    icon: 'Icon',
    iconDots: "Icon Dots",
    icons: 'Icons',
    iconsAndText: "Icons And Text",
    imageCropper: "Image Cropper",
    images: "Images",
    impressions: 'Impressions',
    inbox5: 'Inbox (5)',
    inbox: 'Inbox',
    indeterminate: 'Indeterminate',
    info: 'Info',
    input: 'Input',
    integrateContactsFirst: 'Integrate Buyers first before Invoices.',
    invoices: 'Invoices',
    iOSUsers: 'iOS Users',
    itemGroups: "Item Groups",
    itemsAndHeaders: 'Items And Headers',
    itemsDownloads: 'Items Downloads',
    itemsUploaded: 'Items Uploaded',
    jvectorMap: 'Jvector Map',
    label: 'Label',
    lastMonth: 'Last Month',
    LAYOUTICONS: 'LAYOUT ICONS',
    leafletMaps: 'Leaflet Maps',
    LearnWithYourConvenience: "Learn With Your Convenience",
    left: 'Left',
    letsGetInTouch: 'Lets Get In Touch',
    light: 'Light',
    lineChart: 'Line Chart',
    list: 'List',
    listOfAllMaterialIcons: 'List Of All Material Icons',
    loaders: 'Loaders',
    lockScreen: 'Lock Screen',
    login1: 'Login 1',
    login2: 'Login 2',
    login: "Login",
    logIn: "Log In",
    loginNow: 'Login Now',
    loginToAdmin: 'Login To Admin',
    logOut: 'Log Out',
    magazine: 'Magazine',
    mailbox: 'Mailbox',
    mailboxes: 'Mailboxes',
    makePayment: "Make Payment",
    MaleUsers: 'Male Users',
    maleUsers: 'Male Users',
    manageFolders: 'Manage Folders',
    management: "Management",
    mapAliases: "Map (Aliases)",
    maps: 'Maps',
    marketingCampaign: 'Marketing Campaign',
    marketingExpenses: 'Marketing Expenses',
    master: 'Master',
    material: 'Material',
    mega: 'Mega',
    menu: 'Menu',
    menus: 'Menus',
    message: 'Message',
    messages: 'Messages',
    miscellaneous: 'Miscellaneous',
    modules: 'Modules',
    monthly: 'Monthly',
    moreCoursesFromJamesColt: "More Courses From James Colt",
    name: 'Name',
    netProfit: 'Net Profit',
    newClients: 'New Clients',
    newCustomers: 'Money Spent',
    newEmail: 'New Email',
    newEmails: 'New Emails',
    newIcons: "New Icons",
    newMembers: 'New Members',
    newPost: 'New Post',
    newRequest: 'New Request',
    news: "News",
    newsletter: 'Newsletter',
    newsletterCampaign: 'Newsletter Campaign',
    noItemsFound: 'No Items Found',
    normal: 'Normal',
    notifications: 'Notifications',
    offerCode: "Offer Code",
    offlineRevenue: 'Offline Revenue',
    ok: 'Ok',
    onBoarding: "On Boarding",
    onlineRevenue: 'Online Revenue',
    oppositeSlot: "Opposite Slot",
    orderAndProjectStats: 'Order & Projects Stats',
    outline: 'Outline',
    overallTrafficStatus: "Overall Traffic Status",
    overview: 'Overview',
    pages: 'Pages',
    pageViews: 'Page Views',
    pagination: "Pagination",
    payment: "Payment",
    paymentOptions: "Payment Options",
    pending: 'Pending',
    person: "Person",
    personal: 'Personal',
    photos: 'Photos',
    pieChart: 'Pie Chart',
    placeOrder: 'Place Order',
    plans: 'Plans',
    pleaseEnterYourPasswordToReset: "Please Enter Your Password To Reset",
    poForExport: "PO for Export",
    poForStock: "PO for Stock",
    polarAreaChart: 'Polar Area Chart',
    popularInstructors: "Popular Instructors",
    previousChats: 'Previous chats',
    pricing1: 'Pricing 1',
    pricing2: 'Pricing 2',
    pricing: 'Pricing',
    primary: 'Primary',
    pro: 'Pro',
    proceedToCheckout: 'Proceed To Checkout',
    productSale: 'Product Sale',
    productSales: 'Product Sales',
    profitShare: 'Profit Share',
    progress: 'Progress',
    projectManagement: "Project Management",
    projectStatus: 'Project Status',
    projectTaskManagement: "Project Task Management",
    promo: 'Promo',
    publish: 'Publish',
    purchaseVuely: 'Purchase Vuely',
    quickLinks: 'Quick Links',
    quillEditor: 'Quill Editor',
    radarChart: 'Radar Chart',
    radio: 'Radio',
    radiosColors: 'Radios - Colors',
    radiosDefault: 'Radios Default',
    radiosDirectionColumn: 'Radios Direction Column',
    radiosDirectionRow: 'Radios Direction Row',
    raisedButton: 'Raised Button',
    ratings: "Ratings",
    readMore: 'Read More',
    recent: 'Recent',
    recentChat: 'Recent Chat',
    recentComments: 'Recent Comments',
    recentNotifications: 'Recent Notifications',
    recentOrders: 'Recent Orders',
    recentSale: 'Recent Sale',
    recentUsers: 'Recent Users',
    recurringClients: 'Recurring Clients',
    referrals: 'Referrals',
    reload: 'Reload',
    resetPassword: "Reset Password",
    reviews: 'Reviews',
    right: 'Right',
    rightAlignedTabs: "Right Aligned Tabs",
    rotate: 'Rotate',
    round: 'Round',
    routerAnimation: 'Router Animation',
    rtlLayout: 'Rtl Layout',
    saas: "SAAS",
    sale: 'Sale',
    sales: 'Sales',
    salesAndEarning: 'Sales And Earning',
    salesAndVisitStats: 'Sales & Visits Stats',
    scheduleDate: 'Schedule Date',
    scopedSlots: "Scoped Slots",
    scrollable: 'Scrollable',
    search: 'Search',
    searchRow: 'Search Row',
    select: 'Select',
    selectable: 'Selectable',
    selectableRows: 'Selectable Rows',
    selectTheme: 'Select Theme',
    sent: 'Sent',
    sentMessages: 'Sent Messages',
    serverLoad: 'Server Load',
    serverStatus: 'Server Status',
    session: 'Session',
    settings: 'Settings',
    shop: 'Shop',
    shoppingCart: 'Shopping Cart',
    showSnackbar: 'Show Snackbar',
    sidebarFilters: 'Sidebar Filters',
    signIn: "Sign In",
    signUp1: 'Sign Up 1',
    signUp2: 'Sign Up 2',
    signUp: "Sign Up",
    simple: "Simple",
    simpleDialogs: 'Simple Dialogs',
    sizeAndWidth: 'Size & Width',
    sizeVariants: 'Size Variants',
    sizing: 'Sizing',
    slider: 'Slider',
    slots: 'Slots',
    smallDots: "Small Dots",
    snackbar: 'Snackbar',
    social: 'Social',
    softwood: "Softwood",
    spam: 'Spam',
    standard: 'Standard',
    starred: 'Starred',
    startToBasic: 'Start To Basic',
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    stepper: 'Stepper',
    submit: 'Submit',
    subscribers: "Subscribers",
    success: 'Success',
    supplierNotPaid: "Supplier not paid",
    supportAndUsefulWidgets: 'Support & Useful Widgets',
    supportRequest: 'Support Request',
    supportTickets: 'Support Tickets',
    switches: 'Switches',
    switchesColors: 'Switches - Colors',
    tables: 'Tables',
    tabName: 'Tab Name',
    tabs: 'Tabs',
    tax: "Tax",
    termsOfDelivery: "Terms of Delivery (Incoterms 2020)",
    termsOfService: 'Terms of Service',
    TEXTEDITOR: 'TEXT EDITOR',
    theDefaultColorToolbar: 'The Default Color Toolbar',
    themeOptions: 'Theme Options',
    themify: 'Themify',
    timelines: "Timelines",
    timePicker: 'Time Picker',
    timepicker: 'Timepicker',
    timePickerInDialogAndMenu: 'Time Picker - In Dialog And Menu',
    todaysDistance: "Today's Distance",
    todaysGoal: "Today's Goal",
    todaysStep: "Today's Step",
    todo: 'Todo',
    todoList: 'To Do List',
    toggle: 'Toggle',
    toolbar: 'Toolbar',
    toolbarTabs: 'Toolbar Tabs',
    tooltip: 'Tooltip',
    top: 'Top',
    topAuthors: 'Top Authors',
    topHeadlines: "Top Headlines",
    topSelling: 'Top Selling',
    topSellingTheme: 'Top Selling Theme',
    total: 'Total',
    totalAnalytics: 'Total Analytics',
    totalAppMemory: 'Total App Memory',
    totalBookmarked: 'Total Bookmarked',
    totalDownloading: 'Total Downloading',
    totalDownloads: 'Total Downloads',
    totalEarnings: 'Total Earnings',
    totalExpences: 'Total Expences',
    totalMemoryUsed: 'Total Memory Used',
    totalPageViews: 'Total Page Views',
    totalPrice: 'Total Price',
    totalRequest: 'Total Request',
    totalSales: "Total Sales",
    trafficChannel: 'Traffic Channel',
    transitions: "Transitions",
    treeview: "Treeview",
    trending: 'Trending',
    twitterFeeds: "Twitter Feeds",
    uiElements: 'UI Elements',
    unfollow: 'Unfollow',
    unreadMail: '12 Unread Mail',
    update: 'Update',
    updated10MinAgo: 'Updated 10 min ago',
    upgradeToAdvance: 'Upgrade To Advance',
    upgradeToPro: 'Upgrade To Pro.',
    usage: "Usage",
    usefulWidgets: 'Useful Widgets',
    user: 'User',
    userProfile: 'User Profile',
    users: 'Users',
    userSignIn: "User Sign In",
    usersList: 'Users List',
    validationWithSubmitAndclear: 'Validation with submit & clear',
    videoPlayer: "Video Player",
    viewAll: 'VIEW ALL',
    viewCart: 'View Cart',
    visibility: 'Visibility',
    visitAndSalesStatistics: 'Visit & Sales Statistics',
    visitors: "Visitors",
    vue2Dragula: 'Vue2 Dragula',
    vueChartjs: 'Vue Chartjs',
    vueDraggable: 'Vue Draggable',
    vueEcharts: 'Vue Echarts',
    warning: 'Warning',
    webAnalytics: 'Web Analytics',
    webAppIcons: 'WEB APP ICONS',
    webApplication: "Web Application",
    websiteTraffic: 'Website Traffic',
    weeklySales: 'Weekly Sales',
    weeklySummary: 'Weekly Summary',
    whatYoWillLearn: "What you Will learn",
    widgets: 'Widgets',
    windows: "Windows",
    withContent: "With Content",
    withoutActivator: 'Without Activator',
    withSearch: "With Search",
    work: 'Work',
    wYSIWYG: 'WYSIWYG',
    yearlyGet2MonthExtra: 'Yearly ( get 2 month extra)',
    yes: 'Yes',

    //APPiC
    abaNumber: "ABA Number",
    ability: "Ability",
    abilityFilter: "Ability Filter",
    abbreviation: "Abbreviation",
    abbr: "Abbr",
    access: "Access",
    accessDenied: "Access Denied",
    accessLocation: "Access Location",
    accessManager: "UAM",
    accessPermissionManagement: "Access & Permission Management",
    accessUser: "Access User",
    account: "Account",
    accountAbaNumber: "Account ABA Number",
    accountBeneficiary: "Account Beneficiary",
    accountCode: "Account Code",
    accountDetails: "Account Details",
    accountName: "Account Name",
    accountNo: "Account No",
    accountNumber: "Account Number",
    accountOwner: "Account Owner",
    accounts: "Accounts",
    accountSwiftCode: "Account Swift Code",
    acct: "Acct",
    acctCode: "AccCode",
    acctName: "AccName",
    action: 'Action',
    actions: 'Action',
    activate: "Activate",
    activateBuyer: "Activate Buyer",
    activateSupplier: "Activate Supplier",
    activateUser: "Activate User",
    active: "Active/Open",
    activated: "Active",
    activeBuyer: "Active buyer",
    activityHistory: "Activity History",
    actualDate: "Actual Date",
    actualForwarderCost: "Act Fwd Cost",
    added: "Added",
    addAbility: "Add Ability",
    addAgent: "Add Agent",
    addArrival: "Add Arrival",
    addAudit: "Add Audit",
    addBankAccount: "Add Bank Account",
    addBundle: "Add Bundle",
    addBuyer: "New Buyer",
    addCertification: 'Add Certification',
    addCurrency: "Add Currency",
    addChargeTo: "Add This Charge To",
    addCommissionInvoice: "Add Commission Invoice",
    addComments: "Add comments",
    addComposition: 'New Composition',
    addContainer: "Add container",
    addContract: "New Contract",
    addContractDocument: "Add another contract document",
    addContractRequest: "New Contract Request",
    addCountry: "New Country",
    addCourier: "New Courier",
    addCoverNote: 'New Cover Note',
    addCredit: 'New Credit Item',
    addCustomClause: 'Add Custom Clause',
    addDataFilter: 'New Data Filter',
    addDebitNote: 'New Debit Note',
    addDensity: 'New Density',
    addDesignProfile: 'New Design/Profile',
    addDeliveryAddress: 'New Document Delivery Address',
    addDocumentClause: 'New Document Clause',
    addDocumentClauseFilter: 'New Document Clause Filter',
    addDocumentFooter: 'New Document Footer',
    addEtdAdjustment: "Add ETD Adjustment",
    addExportPurchaseRequest: "New Export Purchase Request",
    addEutrComplianceDocument: "Add another compliance document",
    addForwarderCost: "Add Forwarder Cost",
    addFilter: 'Add Filter',
    addFinishing: 'Add Finishing',
    addtlDescr: 'Addtl Descr',
    addExchangeRate: "Add Exchange Rate",
    addGlue: "Add Glue",
    addImportDuty: "Add Import Duty",
    addImportPermitAdvice: "New Import Permit Advice",
    addIncoTerm: "New Incoterm",
    addInvoice: "New Invoice",
    addIpa: "New IPA",
    additionalConditions: "Additional Conditions",
    additionalDetails: "Additional Details",
    additionalNote: 'Additional Note',
    addItem: "Add Item",
    addItems: "Add Items",
    additionalCommission: "Additional Commission",
    additionalDescription: "Additional Description",
    additionalData: 'Additional Data',
    addLength: "Add Length",
    addLetterOfCreditAdvice: "Add Letter of Credit Advice",
    addLocalSalesInvoice: "Add Local Sales Invoice",
    addMonthlyExchangeRates: "Add Monthly Exchange Rates",
    addMore: "Add More",
    addMorePayments: "Add More Payments",
    addNcrDocument: "Add another NCR document",
    addPayment: "Add Payment",
    addPaymentTerm: "Add Payment Term",
    addPermission: "Add Permission",
    addPriceMargin: "Add Price Margin",
    addProductGroup: 'Add Product Group',
    addProduct: "Add product",
    addProperty: {
        certification: 'Add certification',
        composition: 'Add composition',
        density: 'Add density',
        design: 'Add design',
        dryingType: 'Add drying type',
        edge: 'Add edge',
        finishing: 'Add finishing',
        glue: 'Add glue',
        grade: 'Add grade',
        grain: 'Add grain',
        length: 'Add length',
        moistureContent: 'Add moisture content',
        profile: 'Add profile',
        sizes: 'Add sizes',
        species: 'Add species',
        surface: 'Add surface',
        thickness: 'Add thickness',
        width: 'Add width'
    },
    addAnotherProperty: {
        certification: 'Add another certification',
        composition: 'Add another composition',
        density: 'Add another density',
        design: 'Add another design',
        dryingType: 'Add another drying type',
        edge: 'Add another edge',
        finishing: 'Add another finishing',
        glue: 'Add another glue',
        grade: 'Add another grade',
        grain: 'Add another grain',
        length: 'Add another length',
        moistureContent: 'Add another moisture content',
        profile: 'Add another profile',
        sizes: 'Add another sizes',
        species: 'Add another species',
        surface: 'Add another surface',
        thickness: 'Add another thickness',
        width: 'Add another width'
    },
    addProductProperty: 'Add property',
    addOtherCharges: "Add Other Charges",
    addPackingList: "New Packing List",
    addPO: "New PO",
    addPoDocument: "Add another PO document",
    addGrade: 'Add Grade',
    addGrossMarginCalculation: 'New GM Calculation',
    addItemSpec: "Add Item Spec",
    addMoistureContent: "Add Moisture Content",
	addPurchaseOrder: "New Purchase Order",
    addRemark: 'New Remark',
    addRemarks: 'Add remarks',
    addRouteStop: "Add Route Stop",
    addSalesBudget: 'Add Sales Budget',
    addSalesColleague: 'Add Sales Colleague',
    addShippingDocument: 'Add another shipping document',
    addShippingInstruction: "New Shipping Instruction",
    addShippingPort: 'Add Shipping Port',
    addSpecies: 'Add Species',
    addStandardClause: 'Add Standard Clause',
    addSalesOrder: "New Sales Order",
    addStandardDataFilter: 'New Standard Data Filter',
    addStandardShippingDocument: 'New Standard Shipping Document',
    addStockArrival: "New Stock Arrival",
    addStorageCost: "Add storage",
    addSupplier: 'New Supplier',
    addSurface: 'New Surface',
    addThickness: 'Add Thickness',
    addToList: 'Add to List',
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    address: "Address",
    addressInfo: "Address Info",
    addressToUse: "Address To Use",
    addToExistingGroup: "Add to an Existing Group",
    addSpecificationNotes: "Add Notes",
    addStockPurchaseRequest: "Add Stock Purchase Request",
    addUser: "New User",
    addWidth: "Add Width",
    addWPR: "New WPR",
    addZohoReport: "Add Zoho Report",
    addWarehousePurchaseRequest: "New Warehouse Purchase Request",
    adjust: "Adjust",
    adjustedTotal: "Adjusted Total",
    adjustedValue: "Adjusted Value",
    adjustLhdnInvoice: "Deduct from LHDN invoice",
    adjustment: "Adjustment",
    adjustmentDescription: "Adj. Description",
    adjustmentDays: "Adjustment (Days)",
    adKdBoth: 'AD/KD/Both',
    adKdPct: 'AD/KD %',
    adminTools: 'Admin Tools',
    addToLhdnInvoice: "Add to LHDN invoice",
    adjustedGradingCost: "Adj Grading Cost",
    after: "After",
    age: "Age",
    agent: "Agent",
    agentAccount: "Agent Account",
    agentAndBl: "Agent/BL",
    agentBank: "Agent Bank",
    agentBankAccountNumber: "Agent Bank Account Number",
    agentBankDetails: "Agent Bank Details",
    agentBankSwiftCode: "Agent Bank Swift Code",
    agentCommission: "Agent Commission",
    agentCommissions: "Agent Commissions",
    agentCommissionCalculation: 'Commissions require custom setups within APPiC. ' +
        'Please discuss first with IT Support before incorporating/updating this commission setting. ' +
        'Updating these fields will not automatically update the commission calculation.',
    agentDetails: "Agent Details",
    agents: "Agents",
    agentScac: "Agent SCAC",
    agentSwiftCode: "Agent Swift Code",
    agtCom: "AC",
    agreedPaymentTerms: "Agreed payment terms",
    all: "All",
    allBuyers: "All Buyers",
    allCustomers: "All Customers",
    allEntries: "All entries",
    allExceptWhSale: 'All (excl. WH Sales)',
    allIncludeWhSale: 'All (incl. WH Sales)',
    allItems: "All items",
    allMonths: "All Months",
    allSources: 'All Sources',
    allSuppliers: "All Suppliers",
    allOffices: "All Offices",
    allOptionsRemoved: 'All options removed.',
    allowed: "Allowed",
    allSales: "All Sales",
    allSalesColleagues: "All Sales Colleagues",
    allSalesTypes: "All Sales Types",
    allWarehouses: "All WH",
    allWoWhSales: "All (w/o WH Sales)",
    allYears: "All Years",
    alsoNotifyPartyBl: "Also Notify Party BL",
    alsoNotifyPartBlNameAddress: 'Also Notify party BL (Name and address)',
    alternateAddress: "Alternate Address",
    alternateAddresses: "Alternate Addresses",
    alternateName: "Alternate Name",
    amount: 'Amount',
    amountCredited: 'Amt. Credited',
    amountDifference: 'Amt. Diff.',
    amountDue: 'Amount Due',
    amountDueFromBuyer: "Amt Due (B)",
    amountDueSupplier: "Amt Due (S)",
    amountToAdd: 'Amount to add',
    amountToDeduct: 'Amount to deduct',
    amt: "Amt",
    annualInAndOut: "Annual In & Output",
    api2PdfKeyNotAvailable: "This might be the first time you tried to merge multiple PDF files. The API key for the merge service has not been loaded. Please logout and login and try again.",
    app: "APP",
    appicManual: 'Help Pages',
    appicProduct: 'APPiC Product',
    appicSettings: 'APPiC Settings',
    applicationDetails: "Application Details",
    applicationRemarks: 'Application Remarks',
    appOffice: "APP Office",
	appic: "APPiC",
    appicAccess: "APPiC Access",
    appicDescription: "APPiC Description",
    appicId: "APPiC  ID",
    APPiCMainMenu: "APPiC Main Menu",
    appicOnly: "APPiC only",
    appicShipmentUpdatesOnly: "Shipment Updates (APPiC only)",
    appicShipmentNotificationsOnly: "Shipment Notifications (APPiC only)",
    appicUpdateAvailable: "APPiC has recently been updated. Please force-reload the application by pressing \"Ctrl\" + \"F5\" or clicking the browser's \"Refresh\" icon",
    appicReport: "APPiC Report",
    appicUser: "APPiC User",
    appicUsers: "APPiC Users",
    appicXeroComparison: "Xero Compare",
    appicXeroComparisonCostOfSales: "Xero Compare - Cost of Sales",
    appicXeroComparisonSales: "Xero Compare - Sales",
    applicableAllDocuments: "(applicable to ALL documents)",
    applicantDetails: 'Applicant Details',
    appliedAmount: "Applied Amt.",
    applyChanges: "Apply Changes",
    appMinimumMargin: "APP Minimum Margin",
    appOnlyDocuments: "APP Timber restricted documents (unmergeable)",
    approve: "Approve",
    approved: "Approved",
    approvedPo: "Approved PO",
    approveNcr: "Approve New Contract Request",
    approveWpr: "Approve Warehouse Purchase Request",
    approxLocation: "Approx Location",
    appSettings: "APPiC Settings",
    appTimber: "APP Timber",
    appTimberCompany: "APP Timber Company",
    area: "Area",
    arrangeZohoReports: 'Arrange Zoho reports on the dashboard.',
    arrived: "Arrived",
    arrivalDate: "Arrival Date",
    asExcel: "As Excel",
    asPer: "as per",
    asPdf: "As PDF",
    assignment: "Assignment",
    attached: "attached",
    attachments: "Attachments",
    attention: "Attention",
    attentionInformation: "Attention Information",
    attn: "Attn",
    audit: "Audit",
    auditNumber: "Audit Number",
    audits: "Audits",
    auditYear: "Audit Year",
    autogenerated: "Autogenerated",
    autoEmail: 'Auto-Email',
    autoEmailNotifications: 'Auto-Email Notifications',
    available: 'Available',
    availableList: 'Available List',
    availableValues: 'Available Values',
    awb: "AWB",
    backToEditing: "Back to Editing",
    balance: "Balance",
    bank: "Bank",
    banks: "Banks",
    bankAccountProfile: "Bank Account Profile",
    bankAddress: "Bank Address",
    bankCharge: "Bank Charge",
    bankCharges: "Bank Charges",
    bankCountry: "Bank Country",
    bankDetails: "Bank Details",
    bankInfo: "Bank Info",
    banker: "Banker",
    bankAccounts: "Bank Accounts",
    bankAccount: "Bank Account",
    bankName: "Bank name",
    basedOn: "Based on",
    basedOnVsResultBy: '"Based on" cannot be the same as "result by"',
    bccSalesColleague: "BCC Sales Colleague-in-charge",
    before: "Before",
    beneficiary: "Beneficiary",
    beneficiaryName: "Beneficiary Name",
    blDate: "BL Date",
    blNo: "BL Number",
    boardView: "Board View",
    bookValue: "Book Value",
    both: "Both",
    branchForProcessing: "Branch for Processing",
    brn: "BRN",
    building: 'Building',
    bundle: "Bundle",
    bundleCost: "Bundle Cost",
    bundleList: "Bundle List",
    bundleNo: "Bundle No",
    bundles: "Bundles",
    bundlePallet: "Bundle/Pallet",
    bundlesToList: 'bundles to list',
    bulkItemPriceEdit: 'Update Prices of Selected Items',
    bulkPriceEdit: 'Update Prices of Selected Bundles',
    bulkUpdateBundles: "Update Selected Bundles",
    buyer: "Buyer",
    buyerActiveContract: "Buyer with Active Contract",
    buyerAdditionalInstructions: "Additional Instructions from Buyer",
    buyerAndDeliveryAddress: "Buyer & Delivery Address",
    buyerAndNotifyingParty: "Buyer & Notifying Party",
    buyerAppicPasswordRule: 'Minimum eight characters, at least one letter and one number. Please click \"Generate Password\" again.',
    buyerConsignee: 'Buyer/Consignee',
    buyerContacts: "Buyer Contacts",
    buyerDeposit: "Buyer Deposit",
    buyerDueDate: "Buyer Due Date",
    buyerFormResetNotification: 'The buyer form has been reset!',
    buyerFullyPaid: "Buyer - Fully Paid",
    buyerLead: "Buyer/Lead",
    buyerLifeCycle: "Buyer Lifecycle",
    buyerLocator: "Buyer Locator",
    buyerLocations: "Buyer Locations",
    buyerSupplierLocations: "Buyer & Supplier Locations",
    buyerSupplierLocator: "Buyer/Spl Locator",
    buyerMainAddress: "Buyer's Main Address",
    buyerNotified: "Buyer Notified",
    buyerOutstanding: "Buyer Outstanding",
    buyerPaid: "Buyer Paid",
    buyerPayments: "Payments from Buyer",
    buyerPaymentTerm: "Buyer Payment Term",
    buyerPaymentTerms: "Buyer Payment Terms",
    buyerPending: "Buyer - Pending",
    buyerPoNo: "Buyer PO No",
    buyerPreview: "Buyer Preview",
    buyerProfile: "Buyer Profile",
    buyerProfiles: "Buyer Profiles",
    buyerRecords: 'This buyer has the following records:',
    buyerRemarks: "Buyer Remarks",
    buyerSpecificRequests: "Buyer specific requests",
    buyerSupplier: "Buyer/Supplier",
    buyerTerms: "Buyer Terms",
    buyers: "Buyers",
    buyerProspect: "Buyer/Prospect",
    buyerUseridPasswordMissing: "The buyer user ID and/or password is missing.",
    buying: "Buying",
    buyingCost: "Buying Cost",
    buyingCostLocal: "BC (LC)",
    buyingCostOriginal: "BC (OC)",
    buyingPrice: "Buying Price",
    buyingPriceLocal: "BP (LC)",
    buyingPriceOriginal: "BP (OC)",
    calculation: "Calculation",
    cancelled: "Cancelled",
    cancelContract: "Cancel Contract",
    cancelCoverNote: "Cancel Cover Note",
    cancelDebitNote: "Cancel Debit Note",
    cancelEpr: "Cancel Export Purchase Request",
    cancelInvoice: "Cancel Invoice",
    cancelJob: "Cancel Job",
    cancelIpa: "Cancel IPA",
    cancelLca: "Cancel LCA",
    cancelLhdnInvoice: "Cancel LHDN invoice",
    cancelLocalSalesInvoice: "Cancel Local Sales Invoice",
    cancelNcr: "Cancel Contract Request",
    cancelPO: "Cancel PO",
    cancelPackingList: "Cancel Packing List",
    cancelPurchaseOrder: "Cancel Purchase Order",
    cancelReason: "Reason for cancellation",
    cancelSalesOrder: "Cancel Sales Order",
    cancelSda: "Cancel Shipping Instruction",
    cancelStockArrival: "Cancel Stock Arrival",
    cancelWpr: "Cancel Warehouse Purchase Request",
    cannotAccessAppicOts: 'Cannot access APPiC-OTS due to country restrictions (China)',
    cannotBeZero: 'Value must be greater than 0',
    cannotCalculateCurrentBuyingPrice: 'Cannot calculate the current buying price',
    cannotUpdateContractNumber: 'The contract number cannot be updated because an invoice has already been generated!',
    carriageOutwards: "Carriage Outwards",
    carrier: "Carrier",
    category: "Category",
    categories: "Categories",
    cert: "Cert",
    certCode: "Certification Code",
    certificate: "Certificate",
    certification: "Certification",
    certifications: "Certifications",
    chartType: 'Chart Type',
    checkBundles: 'Check Bundles',
    checkItems: 'Check Items',
    cif: "CIF",
    cfr: "CFR",
    cfrCif: "CFR/CIF",
	cfrMargin: "CFR Margin",
    cfrValue: "CFR Value",
    citesNo: "CITES No.",
    city: "City",
    changeAvailability: "Change Availability",
    changePicture: "Change Picture",
    changeProduct: "Change Product",
    chartLabel: 'Chart Label',
    checkEudrDocuments: 'Verify EUDR Documents',
    checkForDownPayment: "Check For Down Payment",
    claim: "Cert",
    clauses: "Clauses",
    clearAll: "Clear All",
    clearCurrentBundles: "Clear current list",
    clearance: "Clearance",
    clearanceInvoice: "Clearance Invoice",
    cloneThisContract: "Clone this Contract",
    cloneThisWareHousePurchaseOrder: "Clone this WH Purchase Order",
    cloneThisWareHousePurchaseOrderToDirectSales: 'Clone this WH PO to DS/SS PO',
    closeAlert: "Close alert",
    closeAll: "Close All",
    closeDate: "Close Date",
    closed: "Closed",
    closing: 'Closing',
    cm: "CM",
    cn: "CN",
    cnAmount: "CN Amt",
    cnDetails: "CN Details",
    cnNumber: "CN No",
    code: "Code",
    collectionDate: "Collection Date",
    collectionDeliveryDate: "Collection/Delivery Date",
    combineIdenticalBundles: "Merge identical bundles",
    convertBuyer: "Convert To Buyer",
    convertSupplier: "Convert To Supplier",
    comm: "Comm.",
    comments: "Comments",
    commSales: "Commission Sales",
    commercialInvoice: "Commercial Invoice",
    commission: "Commission",
    commissionAmount: "Commission Amount",
    commissionDatePaid: 'Comm Date Paid',
    commissionDetails: "Commission Details",
    commissionFrom: "Commission From",
    commissionInvoiceFormResetNotification: 'The commission invoice form has been reset!',
    commissions: "Commissions",
    commissionSales: "Comm Sales",
    commissionSource: "Commission Source",
    commissionTerms: "Commission Terms",
    commissionThisQuarter: "Commission this Qtr",
    commissionType: "Commission Type",
    commByUnit: "Comm/unit",
    commonName: "Common Name",
    commAmount: "Comm Amount",
    commPct: "Comm %",
    commUnit: "Comm per unit",
    companies: 'Companies',
    company: "Company",
    companyAddress: "Company Address",
    companyName: "Company Name",
    companyStamp: "Company Stamp",
    companyStampBuyerSignature: "Company Stamp and Buyer's authorised signature",
    completed: "Completed",
    complianceFilesCompleted: "Files completed",
    component: "Component",
    composition: "Composition",
    compositions: "Compositions",
    concurrentLoginDetected: 'You have been logged out because you logged in another browser.',
    conditionField: "Condition Field",
    confirmation: "Confirmation",
    confirmations: {
        continueAgentCancelAction: 'Are you sure you want to cancel this agent? You can\'t undo this action.',
        continueAuditCancelAction: 'Are you sure you want to cancel this audit? You can\'t undo this action.',
        continueBankAccountCancelAction: 'Are you sure you want to cancel this bank account? You can\'t undo this action.',
        continueBuyerFormReset: 'Are you sure you want to reset the buyer form? You can\'t undo this action.',
        continueBuyerDeleteAction: 'Are you sure you want to delete this buyer? You can\'t undo this action.',
        continueBuyerRemarkCancelAction: 'Are you sure you want to cancel this remark? You can\'t undo this action.',
        continueBuyerSuspendAction: 'Are you sure you want to suspend this buyer? You can\'t undo this action.',
        continueBuyersSuspendAction: 'Are you sure you want to suspend these buyers? You can\'t undo this action.',
        continueCertificationCancelAction: 'Are you sure you want to cancel this certification? You can\'t undo this action.',
        continueClauseCancelAction: 'Are you sure you want to cancel this document/clause? You can\'t undo this action.',
        continueCommentsCancelAction: 'Are you sure you want to delete these comments? You can\'t undo this action.',
        continueContractCancelAction: 'Are you sure you want to cancel this contract? You can\'t undo this action.',
        continueContractFormReset: 'Are you sure you want to reset the contract form? You can\'t undo this action.',
        continueContractMarkCompleteAction: 'Are you sure you want to close this contract? You can\'t undo this action.',
        continueContractReopenAction: 'Are you sure you want to reopen the contract?',
        continueContractRestoreAction: 'Are you sure you want to restore the contract?',
        continueConvertBuyerToSupplierAction: 'Are you sure you want to copy this buyer to a supplier? You can\'t undo this action.',
        continueConvertSupplierToBuyerAction: 'Are you sure you want to copy this supplier to a buyer? You can\'t undo this action.',
        continueCountryCancelAction: 'Are you sure you want to delete this country? You can\'t undo this action.',
        continueCoverNoteCancelAction: 'Are you sure you want to cancel this contract? You can\'t undo this action.',
        continueCoverNoteFormReset: 'Are you sure you want to reset the cover note form? You can\'t undo this action.',
        continueCurrencyCancelAction: 'Are you sure you want to delete this currency? You can\'t undo this action.',
        continueDebitNoteCancelAction: 'Are you sure you want to cancel this debit note? You can\'t undo this action.',
        continueDebitNoteFormReset: 'Are you sure you want to reset the debit note form? You can\'t undo this action.',
        continueDeleteAddressAction: 'Are you sure you want to delete this address? You can\'t undo this action.',
        continueDeleteBundleAction: 'Are you sure you want to delete this bundle? You can\'t undo this action.',
        continueDeletePalletAction: 'Are you sure you want to delete this pallet? You can\'t undo this action.',
        continueDeleteClauseAction: 'Are you sure you want to delete this clause? You can\'t undo this action.',
        continueDeleteContactAction: 'Are you sure you want to delete this contact? You can\'t undo this action.',
        continueDeleteItemAction: 'Are you sure you want to delete this item? You can\'t undo this action.',
        continueDeleteBundle: 'Are you sure you want to delete this bundle? You can\'t undo this action.',
        continueDeletePallet: 'Are you sure you want to delete this pallet? You can\'t undo this action.',
        continueDeleteTransactionAction: 'Are you sure you want to delete this transaction? You can\'t undo this action.',
        continueDeliveryAddressCancelAction: 'Are you sure you want to delete this addresses? You can\'t undo this action.',
        continueDocumentDelete: 'Are you sure you want to delete this document? You can\'t undo this action.',
        continueDocumentFooterCancelAction: 'Are you sure you want to delete this document? You can\'t undo this action.',
        continueEmailVerifyAction: 'Are you sure you want to verify this email?',
        continueEprApproval: 'Are you sure you want to approve the export purchase request?',
        continueEprFormReset: "Are you sure you want to reset the contract request form? You can\'t undo this action.",
        continueEprCancelAction: "Are you sure you want to cancel this export purchase request? You can\'t undo this action.",
        continueEprReject: 'Are you sure you want to decline the export purchase request?',
        continueEprSubmit: 'Are you sure you want to submit the export purchase request?',
        continueEtdAdjustmentCancelAction: 'Are you sure you want to delete this ETD adjustment? You can\'t undo this action.',
        continueExchangeRateCancelAction: "Are you sure you want to delete this exchange rate? You can\'t undo this action.",
        continueFilterCancelAction: 'Are you sure you want to delete this filter? You can\'t undo this action.',
        continueGetExchangeRateAction: 'Are you sure you want to update the exchange rate?',
        continueGradeCancelAction: "Are you sure you want to delete this grade? You can\'t undo this action. Please convert this grade to another grade before deleting it.",
        continueGrantAppicAccessAction: 'Are you sure you want to give APPiC access to this buyer contact?',
        continueGrossMarginFormReset: "Are you sure you want to reset the gross margin calculation form? You can\'t undo this action.",
        continueIncoTermCancelAction: 'Are you sure you want to delete this incoterm? You can\'t undo this action.',
        continueIntegrateContactsAction: "Are you sure you want to integrate the selected contacts?",
        continueIntegrateInvoicesAction: "Are you sure you want to integrate the selected invoices?",
        continueInvoiceCancelAction: 'Are you sure you want to cancel this invoice? You can\'t undo this action.',
        continueInvoiceResubmitAction: 'Are you sure you want to resubmit this invoice to LHDN?',
        continueItemSpecCancelAction: 'Are you sure you want to delete this spec? You can\'t undo this action.',
        continueInvoiceFormReset: 'Are you sure you want to reset the invoice form? You can\'t undo this action.',
        continueIpaCancelAction: "Are you sure you want to delete this IPA? You can\'t undo this action.",
        continueIpaFormReset: "Are you sure you want to reset the IPA form? You can\'t undo this action.",
        continueItemsReset: "Are you sure you want to reset the packing list items? You can\'t undo this action.",
        continueLhdnAdjustAction: "Are you sure you want to adjust this invoice? This will upload a credit note to adjust the original invoice. You can\'t undo this action.",
        continueLhdnAddToAction: "Are you sure you want to add new charges to this invoice? This will upload a debit note to adjust the original invoice. You can\'t undo this action.",
        continueLhdnDeleteAction: "Are you sure you want to delete this invoice? LHDN will only allow cancellation up to 72 hours after the invoice has been validated. You can\'t undo this action.",
        continueLhdnRevertAction: "Are you sure you want to revert this invoice? This will upload a credit note to correct the original invoice. You can\'t undo this action.",
        continueJobCancelAction: "Are you sure you want to delete this job? You can\'t undo this action.",
        continueLcaFormReset: "Are you sure you want to reset the LCA form? You can\'t undo this action.",
        continueLcaCancelAction: "Are you sure you want to delete this LCA? You can\'t undo this action.",
        continueLocalSalesInvoiceCancelAction: "Are you sure you want to delete this local sales invoice? You can\'t undo this action.",
        continueLogOut: "Logout",
        continueMarkAsProcessedAction: "Are you sure you want to mark these transactions as processed? You can\'t undo this action.",
        continueMoistureContentCancelAction: "Are you sure you want to delete this moisture content? You can\'t undo this action.",
        continueNewLhdnCreditNoteAction: "Are you sure you want to create this LHDN credit note?",
        continueNewLhdnDebitNoteAction: "Are you sure you want to create this LHDN debit note?",
        continueNcrApproval: 'Are you sure you want to approve the contract request?',
        continueNcrFormReset: "Are you sure you want to reset the contract request form? You can\'t undo this action.",
        continueNcrCancelAction: "Are you sure you want to cancel this contract request? You can\'t undo this action.",
        continueNcrReject: 'Are you sure you want to decline the contract request?',
        continueNcrSubmit: 'Are you sure you want to submit the contract request?',
        continuePackingListFormReset: "Are you sure you want to reset the packing list form? You can\'t undo this action.",
        continuePackingListCancelAction: 'Are you sure you want to cancel this packing list? You can\'t undo this action.',
        continuePaymentTermCancelAction: 'Are you sure you want to delete this payment term? You can\'t undo this action.',
        continuePdfServerReboot: 'Are you sure you want to reboot the PDF server? You can\'t undo this action.',
        continuePortCancelAction: 'Are you sure you want to cancel this port? You can\'t undo this action.',
        continuePostToGpQueue: 'Are you sure you want to send this invoice to the GP posting queue? No updates will be possible after sending,',
        continueProductCancelAction: 'Are you sure you want to delete this product? You can\'t undo this action.',
        continueProductGroupCancelAction: 'Are you sure you want to cancel this product group? You can\'t undo this action.',
        continueProductPropertyCancelAction: 'Are you sure you want to delete this product property? You can\'t undo this action.',
        continuePropertyCancelAction: 'Are you sure you want to delete this property from the product? You can\'t undo this action.',
        continuePurchaseOrderFormReset: "Are you sure you want to reset the purchase order form? You can\'t undo this action.",
        continuePurchaseOrderCancelAction: 'Are you sure you want to cancel this purchase order? You can\'t undo this action.',
        continueRemarkDeleteAction: 'Are you sure you want to cancel this remark? You can\'t undo this action.',
        continueResendInviteAction: 'Are you sure you want to resend the access invitation?',
        continueResetAllPasswordsAction: 'Are you sure you want to reset these user passwords? You can\'t undo this action.',
        continueResetItems: 'Are you sure you want to reset these items? You can\'t undo this action.',
        continueSalesBudgetCancelAction: 'Are you sure you want to delete this sales budget? You can\'t undo this action.',
        continueSalesColleagueCancelAction: 'Are you sure you want to remove this sales colleague? You can\'t undo this action.',
        continueSalesOrderFormReset: "Are you sure you want to reset the sales order form? You can\'t undo this action.",
        continueSalesOrderItemsReset: "Are you sure you want to reset the sales order items? You can\'t undo this action.",
        continueSalesOrderCancelAction: "Are you sure you want to delete this sales order? You can\'t undo this action.",
        continueSalesOrderSign: "Are you sure you want to sign the sales order? You can\'t undo this action.",
        continueSdaFormReset: "Are you sure you want to reset the shipping instruction form? You can\'t undo this action.",
        continueSdaCancelAction: "Are you sure you want to delete this shipping instruction? You can\'t undo this action.",
        continueSendCourierInformationAction: "Are you sure you want to send the courier information to the buyer?",
        continueSpeciesCancelAction: "Are you sure you want to delete this species? You can\'t undo this action. Please convert this species to another species before deleting it.",
        continueSpecificationsReset: "Are you sure you want to reset the contract specifications? You can\'t undo this action.",
        continueShipmentMarkCompleteAction: 'Are you sure you want to close this shipment? You can\'t undo this action.',
        continueStandardShippingDocumentCancelAction: 'Are you sure you want to delete this standard shipping document? You can\'t undo this action.',
        continueStockArrivalBundlesReset: "Are you sure you want to reset the stock entry bundles? You can\'t undo this action.",
        continueStockArrivalCancelAction: 'Are you sure you want to delete this stock entry and its associated bundles? You can\'t undo this action.',
        continueStockArrivalFormReset: 'Are you sure you want to reset the stock entry form? You can\'t undo this action.',
        continueSubmissionToLhdnAction: 'Are you sure you want to submit this invoice to the LHDN? You can\'t undo this action.',
        continueSupplierDeleteAction: 'Are you sure you want to delete this supplier? You can\'t undo this action.',
        continueSupplierFormReset: 'Are you sure you want to reset the supplier form? You can\'t undo this action.',
        continueSupplierRemoveFromGroupAction: 'Are you sure you want to remove the supplier from the group? You can\'t undo this action.',
        continueSupplierSuspendAction: 'Are you sure you want to suspend this supplier? You can\'t undo this action.',
        continueSuspendAppicAccessAction: 'Are you sure you want to suspend this contact\'s APPiC access?.',
        continueThicknessCancelAction: 'Are you sure you want to delete this thickness? You can\'t undo this action.',
        continueUpdateBundleAvailabilityAction: 'Are you sure you want to change this bundle\'s status to "sold"? You can\'t undo this action.',
        continueUploadToXeroAction: 'Are you sure you want to upload this invoice to Xero? You can\'t undo this action.',
        continueUserActivateAction: 'Are you sure you want to activate this user? You can suspend any active user.',
        continueUserDeleteAction: 'Are you sure you want to delete this user? You can\'t undo this action.',
        continueUserLogOutAction: "Are you sure you want to log out this user?",
        continueUserSuspendAction: 'Are you sure you want to suspend this user? You can reactivate any suspended user.',
        continueWprApproval: 'Are you sure you want to approve the warehouse purchase request?',
        continueWprFormReset: 'Are you sure you want to reset the warehouse purchase request form? You can\'t undo this action.',
        continueWprCancelAction: "Are you sure you want to delete this warehouse purchase request ? You can\'t undo this action.",
        continueWprItemsReset: "Are you sure you want to reset the warehouse purchase request items? You can\'t undo this action.",
        continueWprReject: 'Are you sure you want to decline the warehouse purchase request?',
        continueWprSubmit: 'Are you sure you want to submit the warehouse purchase request?',
        continueZohoReportCancelAction: 'Are you sure you want to cancel this zoho report? You can\'t undo this action.'
    },
    confirmedByBuyer: "Confirmed on behalf of Buyer",
    confirmedBySalesAdmin: "Confirmed by the Sales Admin",
    confirmedBySeller: "Confirmed on behalf of Seller",
    consignedFrom: "Consigned From",
    consignee: "Consignee",
    consigneeAWB: "Consignee AWB",
    consigneeBL: "Consignee BL",
    consigneeBl: 'Consignee BL (Name / Address)',
    consigneeCertificateOrigin: "Consignee Certificate Origin",
    consigneeCO: "Consignee CO",
    consigneeDetails: "Consignee Details",
    consigneeFormD: "Consignee Form D",
    consigneePhytoCertificate: "Consignee Phyto. Certificate",
    consigneePC: "Consignee PC",
    consignor: "Consignor",
    consignorAddress: "Consignor Address",
    consignorDetails: "Consignor Details",
    consignorName: "Consignor Name",
    contact: "Contact",
    contactAppicDeveloper: "Contact the developer for support.",
    contactEmail: "Contact Email",
    container: "Container",
    containerCount: "Container Count",
    containerDelivered: 'Container Delivered',
    containerNo: "Container No.",
    containerNos: "Container Nos.",
    containerNumbers: "Container Numbers",
    containers: "Containers",
    containerVolume: "Ctr Vol",
    continent: "Continent",
    contract: "Contract",
    contractAndPoDetails: "Contract and PO Details",
    contractCompleted: "Contract Completed",
    contractRequest: "Contract Request",
    contractRequestNo: "Contract Request No.",
    contractInvoice: "Contract/Invoice",
    contractInvoicePo: "Contract/Invoice/PO",
    contracts: "Contracts",
    contractAmount: "Contract Amount",
    contractsOverview: "Contracts Overview",
    contractPoSo: "CT/PO/SO",
    contractPoSoEpr: "CT/PO/SO/EPR",
    contractPrice: "Contract Price",
    contractcc1: "Contract Cc #1",
    contractcc2: "Contract Cc #2",
    contractConvertNote: "Note: The created contract will be incomplete. Please enter the missing information into the contract manually before sending out to the buyer.",
    contractCost: 'Contract Cost',
    contractSplit: "Contract Split / Base Contract",
    contractType: "Contract Type",
    contractFormResetNotification: 'The contract form has been reset!',
    coverNoteFormResetNotification: 'The cover note form has been reset!',
    contractPreview: 'Contract Preview',
    contractRequestPreview: "Contract Request Preview",
    contractNo: "Contract No",
    contractNotes: "Contract Notes",
    contractNumber: "Contract Number",
    contractOpen: "Contract Open",
    contractPo: "Contract/PO",
    contractRequests: "Contract Requests",
    contractRequestsOverview: "Contract Requests Overview",
    contractRequestDetails: "Details",
    contractSigned: "Contract Signed",
    contractSalesOrder: "Contract/SO",
    contractUpdated: "Contract updated",
    contractVolAndQty: "Contract Vol/Qty",
    contractOrSalesOrder: "Contract/SO",
    contractOrWhSale: "Con/WH Sale",
    contractQuantity: "Contract Qty",
    contractSchedule: "Contract Schedule",
    contractScheduleTabs: {
        dsLimited: "DS - Ltd",
        dsSsMalaysia: "DS/SS/LS - MY",
        ssIndonesia: "SS/LS - ID",
        ssThailand: "SS/LS - TH",
        whMalaysia: "WH - MY",
        whIndonesia: "WH - ID",
        whThailand: "WH - TH",
    },
    contractSo: "Contract/SO",
    contractUnsigned: "Unsigned Contract",
    contractValue: "Contract Value",
    conversionsForWeek: "Conversions for Week",
    convert: "Convert",
    converted: "Converted",
    convertToAnotherGrade: "Convert to Another Grade",
    convertToAnotherSpecies: "Convert to Another Species",
    convertToContract: "Convert to Contract",
    convertToInvoice: "Convert to Invoice",
    convertedToInvoice: "Converted to Invoice",
    converting: "Converting...",
    convertToPo: "Convert to PO",
    continueWorking: 'Continue Working',
	copies: "Copies",
    coPcConsignee: 'Certificate of Origin / Phytosanitary Certificate consignee (name /address)',
    copy: "Copy",
    copyContract: 'Copy Contract',
    copyPreviousContract: 'Copy A Previous Contract',
    copyPreviousEpr: "Copy A Previous EPR",
    copyPreviousExportPo: "Copy A Previous Export PO",
    copyPreviousNcr: "Copy A Previous NCR",
    copyProduct: "Copy Product",
    copyPurchaseOrder: "Copy Purchase Order",
    copyBuyer: "Copy To Buyer",
    copySupplier: "Copy To Supplier",
    copyThisItem: 'Copy this item',
    copyingThisItem: 'Copying this item',
    cost: "Cost",
    costs: "Costs",
    costAmount: "Cost Amount",
    costOfSalesTool: "C of S Tool",
    costPrice: "Cost Price",
	countries: "Countries",
    country: "Country",
    countryCustomerSpecificDocuments: 'Cty/Buyer Specific Documents',
    countryManager: 'Country Sales Manager',
    countryOfLoading: 'Country of Loading',
    countryOfOrigin: "Country of Origin",
    countryListing: "Country Listing",
    countryOriginDestination: "Country of origin / destination",
    countrySpecificDocumentDeliveryAddresses: "Country Specific Document Delivery Addresses",
    couriers: 'Couriers',
    coverNote: "Cover Note",
    coverNoteNumber: "Cover Note Number",
    coverNotes: "Cover Notes",
    create: "Create",
    createAnotherRequest: "Create Another Request",
    createContact: "Create Contact",
    createCountry: "Create Country",
    createDate: "Create Date",
    createDebitNote: "Create Debit Note",
    createGrade: "Create New Grade",
    createGroup: "Create New Group",
    createInternalRemark: "Create Internal Remark",
    createLhdnCreditNote: "Create LHDN Credit Note",
    createLhdnDebitNote: "Create LHDN Debit Note",
    createMarketingColleague: "New Marketing Colleague",
    createMarketSegment: "Create Market Segment",
    createNew: "Create New",
    createNewGroup: "Create Group",
    createNewProfile: "Create New Profile",
    createNewShippingInstruction: "Create New SI",
    createNewUser: "Create New User",
    createPartialShipment: "Create Partial Shipment",
    createProductGroup: "Create Product Group",
    createProductSegment: "Create Product Segment",
    createShippingAgent: "Create Shipping Agent",
    createShippingInstruction: "Create Shipping Instruction",
    createShippingPort: "Create Shipping Port",
    createSize: "Create New Size",
    createSpecies: "Create New Species",
    createTermOfPayment: "Create Term of Payment",
    creditLimits: "Credit Limits",
    creditNoteNumber: "Credit Note Number",
    creditNotes: "Credit Notes",
    creditNotesForVerification: "CN for status update",
    crNo: "CR No",
    cs: "CS",
    cur: "Cur",
    currency: "Currency",
    currencies: "Currencies",
    currencyExchangeRate: "Currency Exchange Rate",
    currencyRate: "Cur Rate",
    currentApprovedCredit: "Current Approved Credit",
    currentBuyingPrice: "Cur Buying Price",
    currentBuyingPriceLocal: "CBP (LC)",
    currentBuyingPriceOriginal: "CBP (OC)",
    currentInTransitStock: 'Current/In-transit Stock',
    currentInTransitStockDetails: "Current/In-transit Stock Details",
    currentPassword: "Current Password",
    currentPaymentStatus: "Current Payment Status",
    currentStage: "Current Stage",
    currentStatus: "Current Status",
    currentStockDetails: "Current Stock Details",
    currentStockInSameItems: "Current stock in same item(s)",
    currentStockPoNumber: "Current Stock PO Number",
    customer: "Customer",
    customerBankInfo: 'Customer bank information',
    customerBankInformation: 'Customer Bank Information',
    customerMainAddress: "Customer's Main Address",
    customerOtherAddresses: "Customer's Other Addresses",
    customerDeliveryNotifyAddress: "Customer's Delivery & Notify Address",
    customPermissions: "Custom Permissions",
    customerNumber: "Customer No",
    customsStationCode: 'Customs Station Code',
    currentFscYearAudit: "Current FSC Year Audit",
    currentPefcYearAudit: "Current PEFC Year Audit",
    dashboards: "Dashboards",
    dataFilters: "Data Filters",
    dataManagement: "Data Mgmt",
    dataMissing: 'Value missing',
    date: "Date",
    datePaid: "Date Paid",
    datePosted: "Date Posted",
    dateSent: "Date Sent",
    dateSigned: "Date Signed",
    days: "days",
    daysAccessed: "No. of Days Accessed",
    daysInStock: "Days in stock (+/-)",
    dearCustomer: "Dear Customer",
    debitNote: "Debit Note",
    debitNotes: "Debit Notes",
    debitNoteAmount: "DN Amount",
    debitNoteDate: "DN Date",
    debitNotesForVerification: "DN for status update",
    debitNoteFormResetNotification: "The debit note form has been reset!",
    debitNoteNo: "Debit Note No",
    debitNoteOrInvoice: "DN/INV",
    debitNoteNumber: "Debit Note Number",
    debitNoteSchedule: "Debit Note Schedule",
    declined: 'Declined',
    deduct: 'Deduct',
    defaultPaymentTerm: "Default Payment Term",
    defaultPaymentTerms: "Default Payment Terms",
    defaultPermissions: "Default Permissions",
    defaultPort: 'Default Port',
    delay: "Delay",
    delayed: "Delayed",
    delayDueCovid: "Delay due to COVID-19",
    deleteAgent: "Delete Agent",
    deleteAudit: "Delete Audit",
    deleteBankAccount: "Delete Bank Account",
    deleteBuyer: "Delete Buyer",
    deleteBuyerRemark: "Delete Buyer Remark",
    deleteCertification: "Delete Certification",
    deleteComments: "Delete Comments",
    deleteCountry: "Delete Country",
    deleteComposition: "Delete Composition",
    deleteCourier: "Delete Courier",
    deleteCurrency: "Delete Currency",
    deleteDataFilter: "Delete Data Filter",
    deleteDeliveryAddress: "Delete Delivery Address",
    deleteDensity: "Delete Density",
    deleteDesignProfile: "Delete Design/Profile",
    deleteDocument: "Delete this document",
    deleteDocumentFooter: "Delete Document Footer",
    deleteEtdAdjustment: "Delete ETD Adjustment",
    deleteExchangeRate: 'Delete Exchange Rate',
    deleteFilter: "Delete Filter",
    deleteFinishing: "Delete Finishing",
    deleteForwarderCost: 'Delete Forwarder Cost',
    deleteGlue: "Delete Glue",
    deleteGrade: "Delete Grade",
    deleteImportDuty: 'Delete Import Duty',
    deleteItemSpec: 'Delete Item Spec',
    deleteIncoTerm: 'Delete Incoterm',
    deleteJob: "Delete job",
    deleteLength: 'Delete Length',
    deleteMoistureContent: 'Delete Moisture Content',
    deletePaymentTerm: "Delete Payment Term",
    deletePriceMargin: 'Delete Price Margin',
    deleteProduct: "Delete Product",
    deleteProductGroup: "Delete Product Group",
    deletePropertyFrom: "Delete Property From",
    deletePort: "Delete Port",
    deleteReason: "Reason to delete",
    deleteRemark: "Delete Remark",
    deleteReport: "Delete Report",
    deleteSalesBudget: "Delete Sales Budget",
    deleteSalesColleague: "Delete Sales Colleague",
    deleteShippingPort: "Delete Shipping Port",
    deleteSpecies: "Delete Species",
    deleteStandardDataFilter: "Delete Standard Data Filter",
    deleteStandardDocument: "Delete Standard Document",
    deleteStandardShippingDocument: 'Delete standard shipping document',
    deleteStorageCost: 'Delete Storage Cost',
    deleteSupplier: "Delete Supplier",
    deleteSurface: "Delete Surface",
    deleteThickness: "Delete Thickness",
    deleteThisClause: "Delete this clause",
    deleteThisContainer: "Delete this container",
    deleteThisItem: "Delete this item",
    deleteThisTransaction: "Delete this payment transaction",
    deleteUser: "Delete User",
    deleteWidth: "Delete Width",
    deleteZohoReport: "Delete Zoho Report",
    deliveryAddress: "Delivery Address",
    deliveryAddressAndNotifyingParty: "Delivery Address & Notifying Party",
    deliveryAddressContainer: 'Delivery Address (Container)',
    deliveryOrder: "Delivery Order",
    deliveryTerms: "Delivery Terms",
    deliverTo: 'Deliver To',
    demoSelector: 'Demo Selector',
    density: "Density",
    densities: "Densities",
    department: "Department",
    deposit: "Deposit",
    depositRequired: "Deposit Required",
    depositTaxInvoice: "Deposit Invoice/Tax Invoice",
    descr: "Descr",
    descriptionAndCertification: "Description / Certification",
    descriptionForm: "Description Form",
    deselected: "Deselected",
    designProfile: "Design/Profile",
    dest: "Dest",
    destination: "Destination",
    destinationCountry: "Destination Country",
    destinationPort: "Destination Port",
    dim: 'Dim',
    direct: "Direct",
    directEmail: "Direct Email",
    directions: "Directions",
    directLine: "Direct Line",
    directSales: "Direct Sales",
    directServiceSales: "Direct/Service Sales",
    dismiss: 'Dismiss',
    displayName: "Display Name",
    dn: "DN",
    dsmNotes: "DSM Notes",
    docFolders: {
        contracts: 'Contracts',
        invoices: 'Invoices',
        others: 'Others',
        packingLists : 'Packing Lists',
        payments: 'Payments',
        pos: 'POs'
    },
    docs: "Docs",
    docsComplete: "Docs Complete",
    docsCompleted: "Docs Completed",
    docsPending: "Docs Pending",
    docStatus: "Doc Status",
    docType: "Doc Type",
    docTypes: {
        CommContractDocuments: 'Commission sales contract document',
        ContractDocuments: 'Direct sales contract document',
        ExportPurchaseRequestDocuments: 'EPR document',
        FinanceDocuments: 'Finance document',
        LocalSaleDocuments: 'WH sale document',
        NcrDocuments: 'NCR document',
        NewCommContractDocuments: 'New commission sales contract document',
        NewContractDocuments: 'New direct sales contract document',
        NewExportPurchaseRequestDocuments: 'New EPR document',
        NewNcrDocuments: 'New NCR document',
        NewPurchaseOrderDocuments: 'New PO document',
        PurchaseOrderDocuments: 'PO document',
        ShippingCommissionDocuments: 'Commission contract shipping document',
        ShippingDirectDocuments: 'Direct sales contract shipping document',
        ShippingEutrDocuments: 'EUTR/EUDR compliance document',
        SupplierDocuments: 'Supplier document',
        WarehousePurchaseRequestDocuments: 'WPR document'
    },
    docFooterTypes: {
        contract: 'Contract notes',
        invoice: 'Invoice notes',
        localsalesinvoice: 'Warehouse sales invoice notes',
        altinvoice: 'Alternate invoice notes',
        covernote: 'Cover notes',
        lcanote: 'LCA notes',
        lcacondition: 'LCA conditions',
        purchaseorder: 'Purchase Order notes',
        salesorder: 'Sales Order notes'
    },
    document: "Document",
    documentClause: 'Document/Clause',
    documentDate: "Doc Date",
    documentDeliveryAddress: "Document Delivery Address",
    documentElectronicallyGenerated: "This document is electronically generated and requires no signature",
    documentFooters: "Doc Footers",
    documentNumber: "Doc No",
    documents: "Documents",
    documentsCompleted: "Documents Completed",
    documentsCourier: "Courier",
    documentsCourierAwb: "Documents Tracking No.",
    documentsPending: "Documents Pending",
    documentsRequired: "Documents Required",
    documentsSentTrackingNumber: "Documents Tracking Number",
    documentsSentOn: "Documents Sent On",
    documentsSentVia: "Courier Used",
    documentStatus: "Document Status",
    documentTitle: "Document Title",
    doNotIndicatePoNo: "Please do NOT indicate your PO Number",
    doNotShow: 'Do Not Show',
    down: 'Down',
    downloadExcel: "Download Excel",
    downloadEutrDocumentsMergedPdf: "Download All EUTR Compliance Files As Merged PDF",
    downloadManual: 'Download Manual',
    downloadMergedPdf: "Download As Merged PDF",
    downloadPdf: "Download PDF",
    downloadShippingDocumentsMergedPdf: "Download All Shipping Documents As Merged PDF",
    downloadSupportingDocumentsMergedPdf: 'Download All Supporting Documents As Merged PDF"',
    downloadZip: "Download As Zip File",
    downPaymentPaid: "Down Payment Paid",
    downPaymentPaidStatus: "DP Paid Status",
    downPaymentReceived: "Down Payment Received",
    dpPaid: "DP Paid",
    draftDocs: "Draft Docs",
    draftDocsPending: "Draft Docs Pending",
    drying: 'Drying',
    dryingMethod: 'Drying Method',
    dsSs: 'DS/SS',
    due: "Due",
    dueDate: "Due Date",
    dummyDate: "Dummy Date",
    dummyInvoice: "Dummy Invoice",
    dummyPackingList: "Dummy Packing List",
    duplicate: "duplicate",
    duty: 'Duty',
    edge: "Edge",
    editBundleInfo: "Edit bundle",
    editByDialog: "Edit by dialog",
    editContent: "Edit Content",
    editItemInfo: "Edit item",
    editPallet: 'Edit pallet',
    editPrices: "Edit prices",
    eInvoices: "E-invoices",
    element: "Element",
    emailToSales: "Email to Sales",
    employee: "Employee",
    empty: "empty",
    emptyContainerReturned: "Empty Container Returned",
    enabled: "Enabled",
    endDate: "End Date",
    enterAnyDescription: "Enter item description.",
    enterAuthCode: "Enter your auth code...",
    enterYourEmailToSendYourPassword: "Enter your email to send your password.",
    entity: "Entity",
    entryPoint: "Entry Point",
    epr: 'EPR',
    eprAndPoDetails: 'EPR And PO Details',
    eprConvertThisToContract: "Convert this New Export Purchase Request to a Contract",
    eprNo: 'EPR No',
    eprs: 'EPRs',
    error: "Error",
    errors: {
        accessNotDeleted: "Access could not be deleted.",
        accessNotUpdated: "Access could not be updated.",
        accountsNotLoaded: "Xero accounts could not be loaded.",
        agentNotAdded: 'Agent could not be added.',
        agentNotUpdated: "Agent could not be updated.",
        agentNotDeleted: "Agent could not be deleted.",
        agentCommissionNotUpdated: "Commission could not be updated.",
        appicAccessNotGranted: 'APPiC access could not be granted.',
        appicAccessNotEnabled: 'APPiC access could not be enabled.',
        appicAccessNotSuspended: 'APPiC access could not be suspended.',
        appicAccessNotResent: 'APPiC access invitation could not be sent.',
        appicAccessNotRevoked: 'APPiC access could not be revoked.',
        auditNotAdded: 'Audit could not be added.',
        auditNotUpdated: 'Audit could not be deleted.',
        auditNotDeleted: 'Audit could not be updated.',
        bankAccountNotAdded: "Bank account has been added.",
        bankAccountNotDeleted: "Bank account has been deleted.",
        bankAccountNotUpdated: "Bank account could not be updated.",
        basedOnVsResultBy: '"Based on" cannot be the same as "result by"',
        browserHasNoAccessToGeolocation: "Map does not have permission to use your location.",
        browserNotSupportGeolocation: "Your browser does not support geolocation.",
        bundlesNotAdded: "Bundles could not be added.",
        bundleCannotBeAdded: "Bundle/s could not be added.",
        bundleNotDeleted: "Bundle could not be deleted.",
        bundleNotSplit: "Bundle could not be split.",
        bundleNotUpdated: "Bundle could not be updated.",
        bundlesNotUpdated: "Bundles could not be updated.",
        buyerNotActivated: 'Buyer could not be activated',
        buyerNotAdded: 'Buyer could not be added.',
        buyerNotDeleted: "Buyer could not be deleted.",
        buyerNotSuspended: 'Buyer could not be suspended.',
        buyersNotSuspended: 'Selected buyers could not be suspended.',
        buyerNotUpdated: 'Buyer could not be updated.',
        cannotCheckForExistingRates: 'Cannot check for existing rates.',
        cannotCheckLhdnStatus: 'Cannot check LHDN status',
        cannotCopyContract: 'Cannot copy the contract.',
        cannotCopyNcr: 'Cannot copy the NCR.',
        cannotLoadHistory: "Cannot load document history.",
        cannotLoadStocks: 'Cannot load warehouse stock list.',
        cannotDetermineYourLocation: "The browser cannot determine your location.",
        cannotGeoLocateBuyer: "The buyer address cannot be found on the map. Please locate it manually.",
        cannotGetNewOtsBuyerId: 'Please enter the following missing information:',
        cannotGetNewContractNumber: 'Please enter the following missing information:',
        cannotGetNextCreditNoteNumber: 'Cannot get the next credit note number.',
        cannotGetNextDebitNoteNumber: 'Cannot get the next credit note number.',
        cannotGrantAppicAccessNoEmail: 'Please provide a email address for this contact first.',
        cannotLoadOptions: 'Cannot load options.',
        cannotParseTallySheet: 'Cannot parse the tally sheet',
        cannotPrint: 'Cannot print the document.',
        certificationNotAdded: 'Certification could not be added.',
        certificationNotDeleted: 'Certification could not be cancelled.',
        certificationNotUpdated: 'Certification could not be updated.',
        clauseNotAdded: 'Clause could not be added.',
        clauseFilterNotAdded: 'Clause filter could not be added.',
        commentsNotAdded: 'Comments could not be added.',
        commentsNotDeleted: 'Comments could not be deleted.',
        containerReportRequestNotSent: 'Container report could not be sent.',
        contractNumberTaken: 'Contract number is already taken.',
        contractNotAdded: 'Contract could not be added.',
        contractNotCloned: 'Contract could not be cloned.',
        contractNotCompleted: 'Contract could not be closed.',
        contractNotDeleted: 'Contract could not be cancelled.',
        contractNotReopened: 'Contract could not be reopened.',
        contractNotRestored: 'Contract could not be restored.',
        contractNotSent: 'Contract could not be sent.',
        contractNotUpdated: 'Contract could not be updated.',
        contractNumberCannotBeEmpty: 'Contract number cannot not be empty.',
        coverNoteNotAdded: 'Cover Note could not be added',
        coverNoteNotDeleted: 'Cover Note could not be cancelled.',
        coverNoteNotUpdated: 'Cover Note could not be updated.',
        couldNotBeLoaded: 'could not be loaded.',
        couldNotDownloadManual: 'Help manual could not be downloaded.',
        couldNotExportList: 'Report could not be exported.',
        couldNotMergeFiles: 'Files could not be merged.',
        couldNotPrint: 'The document cannot be printed.',
        countryNotAdded: 'Country could not be added.',
        countryNotDeleted: 'Country could not be deleted.',
        countryNotUpdated: 'Country could not be updated.',
        courierInformationNotSent: 'The courier information could not be sent.',
        courierNotAdded: 'Courier could not be added.',
        courierNotDeleted: 'Courier could not be deleted.',
        courierNotUpdated: 'Courier could not be updated.',
        currencyNotAdded: 'Currency could not be added.',
        currencyNotDeleted: 'Currency could not be deleted.',
        currencyNotUpdated: 'Currency could not be updated.',
        customerNotConverted: 'Customer could not be converted to supplier.',
        dataFilterNotAdded: 'Data filter could not be added.',
        dataFilterNotUpdated: 'Data filter could not be updated.',
        dataFilterNotDeleted: 'Data filter could not be deleted.',
        dateNotProvided: 'Date not provided. Please select a valid date.',
        debitNoteNotAdded: 'Debit Note could not be added.',
        debitNoteNotDeleted: 'Debit Note could not be cancelled.',
        debitNoteNotPosted: 'Debit Note could not be sent to posting queue.',
        debitNoteNotUpdated: 'Debit Note could not be updated.',
        deliveryAddressesNotLoaded: 'Default addresses could not be loaded.',
        deliveryAddressNotAdded: 'Delivery address could not be added.',
        deliveryAddressNotDeleted: 'Delivery address could not be deleted.',
        deliveryAddressNotUpdated: 'Delivery address could not be updated.',
        documentAlreadySelected: 'Document is already in the list.',
        documentClauseNotAdded: 'Document/clause could not be added.',
        documentClauseNotUpdated: 'Document/clause could not be updated.',
        documentClauseNotDeleted: 'Document/clause could not be deleted.',
        documentFooterNotAdded: 'Document footer could not be added.',
        documentFooterNotUpdated: 'Document footer could not be updated.',
        documentFooterNotDeleted: 'Document footer could not be deleted.',
        documentFooterSortingNotUpdated: 'Document footer sorting could not be updated.',
        documentNotParsed: "Document could not be parsed.",
        documentNotSent: 'Document could not be sent.',
        documentNotUploaded: 'Document could not be uploaded.',
        documentsNotSent: 'Documents could not be sent.',
        dueDateNotUpdated: "Due date could not be updated.",
        emailNotVerified: "Email could not be verified.",
        eprNotAdded: 'Export Purchase Request could not be created.',
        eprNotApproved: 'Export Purchase Request could not be approved.',
        eprNotConverted: 'Export Purchase Request could not be converted.',
        eprNotDeleted: 'Export Purchase Request could not be deleted.',
        eprNotSubmitted: 'Export Purchase Request could not be submitted.',
        eprNotUpdated: 'Export Purchase Request could not be updated.',
        eprRemarkNotSent: 'Export Purchase Request remark could not be sent.',
        etdAdjustmentNotAdded: 'ETD adjustment could not be added.',
        etdAdjustmentNotDeleted: 'ETD adjustment could not be deleted.',
        etdAdjustmentNotUpdated: 'ETD adjustment could not be updated.',
        exchangeRateNotAdded: "Exchange rate could not be added.",
        exchangeRateNotDeleted: "Exchange rate could not be deleted.",
        exchangeRateNotUpdated: "Exchange rate could not be updated.",
        exRateXeroMissing: "Ex-rate for Xero is missing",
        fileEmpty: 'No file selected.',
        fileNotValid: 'Invalid file type.',
        filterNotDeleted: 'Filter could not be deleted.',
        forwarderCostNotAdded: 'Forwarder cost could not be added.',
        forwarderCostNotDeleted: 'Forwarder cost could not be deleted.',
        forwarderCostNotUpdated: 'Forwarder cost could not be updated.',
        freightInsuranceNotUpdated: "Freight, Insurance & Import/Transport Cost not updated.",
        grossMarginNotAdded: 'Gross margin calculation could not be created.',
        grossMarginNotAvailable: 'Gross margin calculation could not be viewed.',
		grossMarginNotDeleted: 'Gross margin calculation could not be deleted.',
		grossMarginNotUpdated: 'Gross margin calculation could not be updated.',
        grossMarginExRateNotUpdated: 'Gross margin exchange rate could not be updated.',
        grossMarginPricesNotUpdated: 'Gross margin prices could not be updated.',
        helpPageSetupNotUpdated: 'Help Page setup could not be updated.',
		importDutyNotAdded: 'Import duty could not be added.',
		importDutyNotDeleted: 'Import duty could not be deleted.',
		importDutyNotUpdated: 'Import duty could not be updated.',
		importPermitAdviceNotSent: 'Import permit advice could not be sent.',
        incoTermNotAdded: 'Incoterm could not be added.',
        incoTermNotDeleted: 'Incoterm could not be deleted.',
        incoTermNotUpdated: 'Incoterm could not be updated.',
        invoiceNotAdded: 'Invoice could not be added.',
        invoiceNotDeleted: 'Invoice could not be deleted.',
        invoiceNotLoaded: 'Invoice could not be loaded.',
        invoiceNotPosted: 'Invoice could not be sent to posting queue.',
        invoiceNotSubmitted: 'Invoice could not be sumbitted.',
        invoiceNotUpdated: 'Invoice could not be updated.',
        invoiceNotUploaded: 'Invoice could not be uploaded.',
        invoicesNotIntegrated: 'Invoices could not be integrated.',
        inputNotValid: 'Input not valid.',
        ipaNotAdded: 'IPA could not be added.',
        ipaNotDeleted: 'IPA could not be deleted.',
        ipaNotUpdated: 'IPA could not be updated.',
        itemNotUpdated: 'Item could not be updated.',
        itemsNotAdded: "Items could not be added.",
        itemsNotLoaded: 'Items could not be loaded.',
        itemsNotUpdated: 'Items could not be updated.',
        itemSpecNotAdded: 'Item spec could not be added.',
        itemSpecNotDeleted: 'Item spec could not be deleted.',
        itemSpecNotUpdated: 'Item spec could not be updated.',
        jobNotDeleted: 'Job could not be deleted',
        lcaNotAdded: 'LCA could not be added.',
        lcaNotDeleted: 'LCA could not be deleted.',
        lcaNotUpdated: 'LCA could not be updated.',
        letterOfCreditAdviceNotSent: 'Letter of Credit Advice could not be sent.',
        linkNotValid: 'Invalid link.',
        listCannotBeLoaded: 'List cannot be loaded.',
        lhdnCreditNoteNotCreated: "LHDN credit note could not be created.",
        lhdnDebitNoteNotCreated: "LHDN debit note could not be created.",
        lhdnNotAddedTo: 'E-invoice amount could not be increased.',
        lhdnNotAdjusted: 'E-invoice amount could not be decreased.',
        lhdnNotCancelled: 'E-invoice could not be cancelled.',
        lhdnNotReverted: 'E-invoice could not be reverted.',
        localSalesInvoiceNotAdded: 'Local Sales Invoice could not be added.',
        localSalesInvoiceNotDeleted: 'Local Sales Invoice could not be deleted.',
        localSalesInvoiceNotUpdated: 'Local Sales Invoice could not be updated.',
        loginError: 'Sorry, we were unable to login you in. Please check your credentials and try again!',
        logNotLoaded: 'Log could not be loaded.',
        marketingReportNotFetched: 'Marketing report could not be fetched.',
        moistureContentNotAdded: 'Moisture content could not be added.',
        moistureContentNotDeleted: 'Moisture content could not be deleted.',
        moistureContentNotUpdated: 'Moisture content could not be updated',
        ncrNotAdded: 'Contract Request could not be created.',
        ncrNotApproved: 'Contract Request could not be approved.',
        ncrNotConverted: 'Contract Request could not be converted.',
        ncrNotDeleted: 'Contract Request could not be deleted.',
        ncrNotSubmitted: 'Contract Request could not be submitted.',
        ncrNotUpdated: 'Contract Request could not be updated.',
        ncrRemarkNotSent: 'Contract Request remark could not be sent.',
        negaraRatesNotPulled: 'Cannot get Bank Negara rates.',
        noApiKey: "No API key available.",
        noBundlesSelected: "No bundles selected",
        noClauseEntered: 'Please enter a new clause.',
        noClauseSelected: 'Please select a standard clause.',
        noContactsSelected: 'No contacts selected.',
        noDeliveryAddressSelected: 'Please select a delivery address.',
        noDocumentSelected: 'Please select a document.',
        noInvoicesSelected: 'No invoices selected.',
        noItemsSelected: "No items selected.",
        noGroupSelected: 'No group selected.',
        noNewContractNo: 'A new contract number cannot be obtained.',
        noNewOtsBuyerId: 'A new OTS buyer ID cannot be obtained.',
        noPuchaseOrderSelected: "No PO has been selected.",
        noRelatedContractSelected: 'Please select a related Contract first.',
        noRelatedLocalsalesorderSelected: 'Please select a related Sales Order first.',
        noSearchColumnAndSearchTerm: 'Please select a search column and/or provide a search term.',
        noSearchTerm: 'Please provide a search term.',
        notificationsNotMarkProcessed: 'Notifications could not be marked as processed.',
        notificationNotSent: 'Notification was not sent.',
        notMarkedAsPaid: 'Commissions could not be marked as paid.',
        noNotificationsSelected: 'No notifications selected.',
        noTransactionsSelected: 'No transactions selected.',
        noUsersSelected: 'No users selected.',
        optionsNotLoaded: 'Options not loaded.',
        otsLoginInfoNotSent: 'APPiC/OTS login info not sent.',
        packingListNotAdded: 'Packing list could not be added.',
        packingListNotDeleted: 'Packing list could not be cancelled.',
        packingListNotUpdated: 'Packing list could not be updated.',
        packingListNotUploaded: 'Packing list could not be uploaded.',
        partialShipmentNotCreated: 'Partial shipment could not be created.',
        passwordNotReset: "Password could not be reset.",
        paymentsNotUpdated: 'Payments could not be updated.',
        paymentDateNotUpdated: 'Payment date could not be updated.',
        paymentDueDateNotUpdated: 'Payment due date could not be updated.',
        paymentStatusNotUpdated: 'Payment status could not be updated.',
        paymentTermNotAdded: 'Payment term could not be added.',
        paymentTermNotDeleted: 'Payment term could not be deleted.',
        paymentTermNotUpdated: 'Payment term could not be updated.',
        pdfError: 'The PDF could not be generated.',
        pdfServerNotRebooted: 'PDF server could not be rebooted.',
        pleaseEnterTrackingNumber: 'Please enter the tracking number.',
        pleaseGenerateUserPassword: 'Please generate a user passsword',
        pleaseGenerateUserId: 'Please generate a user id',
        pleaseSelectCourier: 'Please select a courier.',
        pleaseSelectOneContact: 'Please select at least one contact.',
        pleaseSelectOneDocument: 'Please select at least one document.',
        poNumberCannotBeEmpty: "PO number cannot be empty.",
        portNotAdded: "Port could not be added.",
        portNotDeleted: "Port could not be deleted.",
        portNotUpdated: "Port could not be updated.",
        priceListNotSent: "Price list could not be sent.",
        priceMarginNotAdded: 'Price margin could not be added.',
        priceMarginNotDeleted: 'Price margin could not be deleted.',
        priceMarginNotUpdated: 'Price margin could not be updated.',
        productNotAdded: 'Product could not be added.',
        productNotCopied: 'Product could not be copied.',
        productNotDeleted: 'Product could not be deleted.',
        productNotUpdated: 'Product could not be updated.',
        productGroupNotAdded: "Product group could not be added.",
        productGroupNotDeleted: "Product group could not be deleted.",
        productGroupNotExist: "Product group has been deleted.",
        productGroupNotUpdated: "Product group could not be updated.",
        propertyNotAdded: 'Property could not be added.',
        propertyNotDeleted: 'Property could not be deleted.',
        propertyNotUpdated: 'Property could not be updated.',
        purchaseOrderNotAdded: 'PO could not be added.',
        purchaseOrderNotCloned: 'PO could not be cloned.',
        purchaseOrderNotDeleted: 'PO could not be cancelled.',
        purchaseOrderNotLinked: 'PO could not be linked.',
        purchaseOrderNotUpdated: 'PO could not be updated.',
        remarkNotAdded: 'Remark could not be added.',
        remarkNotDeleted: 'Remark could not be deleted.',
        remarkNotUpdated: 'Remark could not be updated.',
        reportDetailNotLoaded: 'Report details could not be loaded.',
        reportNotLoaded: 'Report details could not be loaded.',
        requiredFieldsEmpty: 'Some required fields are empty. Please check and try again.',
        salesBudgetNotAdded: 'Sales budget could not be added.',
        salesBudgetNotDeleted: 'Sales budget could not be deleted.',
        salesBudgetNotUpdated: 'Sales budget could not be updated.',
        salesColleagueNotAdded: 'Sales Colleague could not be added.',
        salesColleagueNotDeleted: 'Sales Colleague could not be removed.',
        salesColleagueNotUpdated: 'Sales Colleague could not be updated.',
        salesOrderNotAdded: 'Sales Order could not be added.',
        salesOrderNotDeleted: 'Sales Order could not be cancelled.',
        salesOrderNotSigned: 'Sales Order could not be signed.',
        salesOrderNotUpdated: 'Sales Order could not be updated.',
        sdaNotAdded: 'Shipping instruction could not be added.',
        sdaNotDeleted: 'Shipping instruction could not be deleted.',
        sdaNotUpdated: 'Shipping instruction could not be updated',
        settingNotUpdated: 'Setting could not be updated',
        shipmentNotCompleted: 'Shipment could not be marked as completed.',
        shipmentNotUpdated: 'Shipment could not be updated.',
        shippingInstructionNotSent: 'Shipping instruction could not be sent',
        shippingPortNotAdded: 'Shipping port could not be added.',
        shippingPortNotDeleted: 'Shipping port could not be deleted.',
        shippingPortNotUpdated: 'Shipping port could not be updated.',
        slipNotSent: 'Payment slip could not be sent.',
        specificationNotAdded: 'Specification could not be added.',
        specificationNotDeleted: 'Specification could not be deleted.',
        specificationNotUpdated: 'Specification could not be updated.',
        soCannotConvert: 'The SO cannot be converted. Correct the error and reload this page.',
        sourceDetailCannotBeLoaded: 'Source detail cannot be loaded',
        sourcesNotLoaded: "Xero journal sources could not be loaded.",
        speciesNotAdded: 'Species could not be added.',
        speciesNotDeleted: 'Species could not be deleted.',
        speciesNotExist: "Species has been deleted.",
        speciesNotUpdated: 'Species could not be updated.',
        standardShippingDocumentNotAdded: 'Standard shipping document could not be added.',
        standardShippingDocumentNotDeleted: 'Standard shipping document could not be deleted.',
        standardShippingDocumentNotUpdated: 'Standard shipping document could not be updated.',
        standardShippingDocumentSortingNotUpdated: 'Standard shipping document sorting could not be updated.',
        statusNotUpdated: 'The status could not be updated.',
        stockArrivalNotAdded: 'Stock Arrival could not be added.',
        stockArrivalNotDeleted: 'Stock Arrival could not be cancelled.',
        stockArrivalNotUpdated: 'Stock Arrival could not be updated.',
        stockPoItemsNotLoaded: 'PO items could not be loaded.',
        stockNotReserved: 'Stock could not be reserved.',
        storageCostNotAdded: 'Storage cost could not be added.',
        storageCostNotDeleted: 'Storage cost could not be deleted.',
        storageCostNotUpdated: 'Storage cost could not be updated.',
        supplierGroupNotCreated: 'Supplier group could not be created.',
        supplierLogoNotUpdated: 'Logo not updated',
        supplierNotAdded: 'Supplier could not be added.',
        supplierNotActivated: 'Supplier could not be activated.',
        supplierNotAddedToGroup: 'Supplier could not be added.',
        supplierNotDeleted: 'Supplier could not be deleted.',
        supplierNotConverted: 'Supplier could not be converted.',
        supplierNotRemovedFromGroup: 'Supplier could not be removed.',
        supplierNotSuspended: 'Supplier could not be suspended.',
        supplierNotUpdated: 'Supplier could not be updated.',
        thicknessNotAdded: 'Thickness could not be added.',
        thicknessNotDeleted: 'Thickness could not be deleted.',
        thicknessNotUpdated: 'Thickness could not be updated.',
        tinNotFound: 'TIN not found.',
        totalAmountLessThanOriginalAmount: 'The new total amount cannot be less than the original amount.',
        totalAmountMoreThanOriginalAmount: 'The new total amount cannot be more than the original amount.',
        transactionsNotMarkProcessed: 'Transactions could not be marked as processed.',
        uploadFailed: 'Upload failed.',
        userNotActivated: 'User could not be activated.',
        userNotAdded: 'User could not be added.',
        userNotDeleted: 'User could not be deleted.',
        userNotLoggedOut: "User could not be logged out.",
        userNotSuspended: 'User could not be suspended.',
        userNotUpdated: 'User could not be updated.',
        userPasswordNotReset: 'User password could not be reset.',
        userProfilePictureNotUpdated: 'Profile picture was not updated.',
        userZohoReportNotAdded: 'User Zoho report could not be added.',
        userZohoReportNotDeleted: 'User Zoho report could not be deleted.',
        wprNotAdded: "Warehouse Purchase Request could not be added.",
        wprNotApproved: "Warehouse Purchase Request could not be approved.",
        wprNotConverted: "Warehouse Purchase Request could not be converted.",
        wprNotDeleted: "Warehouse Purchase Request could not be cancelled.",
        wprNotRejected: "Warehouse Purchase Request could not be declined.",
        wprNotSubmitted: 'Warehouse purchase request could not be submitted.',
        wprNotUpdated: "Warehouse Purchase Request could not be updated.",
        zohoReportNotAdded: 'Zoho Report could not be added.',
        zohoReportNotUpdated: 'Zoho Report could not be updated.',
        zohoReportNotDeleted: "Zoho Report could not be deleted.",
        userZohoReportSortingNotUpdated: 'User Zoho reports sorting could not be updated.',
    },
    estimatedFreight: "Estimated Freight",
    eta: "ETA",
    etaCurrentStockInSameItems: "ETA Current stock in same item(s)",
    etaIntransitStockInSameItems: "ETA In-Transit stock in same item(s)",
    etdAdjustments: "ETD Adj",
    etaWh: "ETA WH",
    etdOfNewPo: "ETD of New PO",
    etaOfNewPo: "ETA of New PO",
    etdWeekSet: "ETD week set",
    etd: "ETD",
    etdweek: "ETD Week",
    ets: "ETS",
    eutrComplianceRequired: "Requires EUTR/EUDR compliance",
    eutrEudr: "EUTR & EUDR",
    eutrEudrComplianceFiles: "EUTR & EUDR Compliance Files",
    eutrUploadFileMaxSize: "File size < 10MB",
    eutrEudrCompliance: "EUTR/EUDR Compliance",
    eutrEudrDocuments: {
        addRemoveDocuments: "Add/Remove Documents",
        allSupporting: "All supporting documents",
        confirmedExerciseDueDiligence: "This is to confirm that APP Timber Snd. Bhd., as supplier of the goods under this contract, confirms that we have exercised due diligence to and the risk that these goods are illegally harvested is negligible in compliance with the EU Timber Regulations.",
        confirmedExerciseDueDiligenceShort: "APP Timber Snd. Bhd., as supplier of the goods under this contract, confirms that we have exercised due diligence to and the risk that these goods are illegally harvested is negligible in compliance with the EU Timber Regulations.",
        countryOfHarvest: 'Country of harvest',
        dontHesitate: "Please do not hesitate to contact the undersign in case of any questions.",
        dueDiligenceAssessment: "EUTR DUE DILIGENCE ASSESSMENT",
        dueDiligenceAssessmentContract: "EUTR DUE DILIGENCE ASSESSMENT - CONTRACT",
        fillMissingInfo: "Please fill any missing information",
        formCertifyCompliance: "The form shall be used to certify compliance EUTR",
        legallyHarvestedDocumentation: "All plant materials are to our best knowledge legally harvested based on the following documentation.",
        manufacturingLocation: 'Location of manufacturing',
        pleaseFindAttached: "Please find attached all related documents:",
        productType: "Product type",
        regionOfHarvest: 'Region of harvest',
        selfDeclarationHarvesting: "EUTR Self Declaration Legal Harvesting",
        selfDeclarationHarvestingShort: "Self Declaration For Legal Harvesting",
        statementLegalHarvesting: "Statement of Legal Harvesting",
        supplierRiskAssessment: "EUTR Supplier Risk Assesment",
        toWhom: "To Whom It May Concern",
        tradeName: "Trade name",
        yoursFaithfully: "Yours Faithfully",
    },
    eutrEudrDocumentsComplete: "Docs Complete",
    event: "Event",
    eventDate: "Event Date",
    excludeFscPefcReport: "Exclude from FSC/PEFC Reports",
    expectedCloseDate: "Expected Close Date",
    expiry: "Expiry",
    expiryDate: "Expiry Date",
    exportAsExcel: "Export as Excel",
    exportAsPdf: "Export as PDF",
    exportAsPdfWithoutSupplier: "Export as PDF w/o Supplier",
    exportPurchaseRequestNo: 'Export Purchase Request No.',
    exwFasValue: "EXW/FAS Value",
    emailId: "E-mail ID",
    exception: "Exception",
    exchangeRate: "Exchange Rate",
    exchangeRates: "Exchange Rates",
    exchangeRateForGST: "Exchange Rate for GST",
    exchangeRateForGstVat: "Exchange Rate for GST/VAT",
    exchangeRateForGstVatPpn: "Exchange Rate for Xero/GST/VAT/PPN",
    exchangeRateForXero: 'Exchange Rate for Xero',
    existingAppicAccess: 'This contact already has access through:',
    existingRatesFound: 'Existing rates for this month have been found.',
    expectedTimeOfSalesOfCurrentStock: "Expected time of 75% sales of current stock",
    expectedTimeOfSalesOfInTransitStock: "Expected time of 75% sales of in-transit stock",
    expectedTimeOfSalesOfNewPO: "Expected time of 75% sales of new PO",
    explanationForPurchase: "Explanation for purchase",
    export: "Export",
    exportFromAsia: "Export from Asia",
    exportBoard: "Export Board",
    exportProducts: "Export Products",
    exportPurchaseRequest: 'Export Purchase Request',
    exportPurchaseRequests: 'Export Purchase Requests',
    exportToExcel: "Export to Excel",
    exportToPdf: "Export to PDF",
    exRate: "ex-Rate",
    exRateCurrent: "Exchange Rate (Current)",
    exRateDate: "ex-Rate Date",
    exwFas: "EXW/FAS",
    fax: "Fax",
    fee: "Fee",
    feePerUnit: "Fee per Unit",
    fetch: "Fetch",
    fetching: "Fetching...",
    fileName: "File Name",
    fileMaxSize: 'File (size < 1MB)',
    fileMaxSize1_5M: 'File (size < 1.5MB)',
    filesComplete: "Files Complete",
    fileSizeExceedOneMb: 'File is > 1MB. Please reduce the file size and upload again',
    fileSizeExceedOneFiveMb: 'File is > 1.5MB. Please reduce the file size and upload again',
    fileSizeExceedTwoMb: 'File is > 2MB. Please reduce the file size and upload again',
    fileSizeExceedThreeMb: 'File is > 3MB. Please reduce the file size and upload again',
    fileSizeExceedFourMb: 'File is > 4MB. Please reduce the file size and upload again',
    fileSizeExceedFiveMb: 'File is > 5MB. Please reduce the file size and upload again',
    fileSizeExceedSixMb: 'File is > 6MB. Please reduce the file size and upload again',
    fileSizeExceedSevenMb: 'File is > 7MB. Please reduce the file size and upload again',
    fileSizeExceedTenMb: 'File is > 10MB. Please reduce the file size and upload again',
    filesUploaded: "Uploaded Files",
    filter: "Filter",
    filters: 'Filters',
    filterBy: "Filter by",
    filterByMonths: "Filter by Months",
    filterByProperty: "filter by property",
    filterByQuarters: "Filter by Quarters",
    filterBySupplier: "filter by supplier",
    filterByYear: "Filter by Year",
    filterCommissions: "Filter Commissions",
    filterConditions: "Filter Conditions",
    filterResults: "Filter Results",
    filterResultsBy: "Filter results by",
    filterTitle: "Filter Title",
    finalDestination: "Final Destination",
    finance: "Finance",
    financeData: "Finance Data",
    financeDataOverview: "Finance Data Overview",
    financeReports: "Finance Reports",
    finishing: "Finishing",
    finishings: "Finishings",
    firebase: 'Firebase',
    firstName: 'First Name',
    fmCoc: "FM/COC",
    fob: "FOB",
    folder: "Folder",
    footerText: "Footer Text",
    for: "For",
    forContract: "For Contract",
    forex: "Forex",
    forGpIntegration: "For MS Dynamics GP Integration",
    formalPaymentTerm: 'Formal Payment Term',
    format: 'Format',
    formLabel: "Form Label",
    forPO: "For PO",
    forwarder: "Forwarder",
    forwarderCost: "Forwarder Cost",
    forwardingCost: "Forwarding Cost",
    freeText: 'Free text',
    freeText1: "Free Text #1",
    freeText2: "Free Text #2",
    freeText3: "Free Text #3",
    freeText4: "Free Text #4",
    freeTextAllowed: "free text allowed",
    freeTextLabel: 'Free text label',
    freeTextType: 'Free text type',
    freight: "Freight",
    freightCost: "Freight Cost",
    freightInsuranceCost: "Freight, Insurance & Import/Transport Cost",
    fresh: "Fresh",
    freshStock: "Fresh Stock",
    from: "From",
    fromYear: "From Year",
    fsc: "FSC",
    fscCertificate: "FSC Certificate",
    fscCertificateNo: "FSC Certificate No",
    fscCertification: "FSC Certification",
    fscDescription: "FSC Descr",
    fscExpiryDate: "FSC Expiry Date",
    fscMaterialCategory: "FSC Material Category",
    fscProduct: "FSC Product",
    fscProductType: "FSC Product Type",
    fscNo: "FSC Certificate Number",
    fscPefc: "FSC/PEFC",
    fscPefcReport: "FSC/PEFC Reports",
    fullContainerLoad: "FULL CONTAINER LOAD",
    fullPaymentReceived: "Full Payment Received",
    fullSetDocumentsUploaded: "Full set documents uploaded",
    fullSetUploaded: "Full set uploaded",
    fullyPaid: "Fully Paid",
    fullyPaidStatus: "Fully Paid Status",
    fw: 'FW',
    certDesc: "Cert. Descr.",
    general: "General",
    generate: "Generate",
    generateByTallySheet: "Generate using AI",
    generateGrossMargin: "Generate Gross Margin",
    generatePassword: "Generate Password",
    generatingPdf: "Generating PDF",
    geolocation: "Geolocation",
    getExcelFileSample: "Get excel file sample",
    getNegaraRates: 'Get Bank Negara Rates',
    getNewContractNumber: 'Get new contract number',
    getNewOtsBuyerId: 'Get new OTS buyer user ID',
    getNewOtsBuyerPassword: 'Get new OTS buyer user password',
    getTin: 'Get TIN from LHDN',
    getUpdatedExchangeRate: 'Get updated exchange rate',
    glue: "Glue",
    glues: "Glues",
    gm: "GM",
    gmIntake: "GM Intake",
    gmo: "GMO",
    gmUsd: "GM USD",
    gpTaxCode: "GP Tax Code",
    gpCustomerNumber: "GP Customer Number",
    gpCustomerNumberRequired: "GP requires a customer number to process this invoice. Please provide one in the buyer's profile",
    grade: "Grade",
    gradeAndSpecification: "Grade/Specification",
    gradeAndType: "Grade/Types",
    grades: "Grades",
    gradingAdjustedCost: "Grading Adjusted Cost",
    gradeRemarks: "Grade Remarks",
    grain: "Grain",
    grandTotal: "Grand Total",
    grantAppicAccessTo: "Grant APPiC access to",
    grantAppicAccess: "Grant APPiC access to this contact",
    grossAmount: "Gross Amount",
    grossAmt: "Gross Amt",
    grossMargin: "Gross Margin",
    grossMarginCalculation: "Gross Margin Calculation",
    grossMarginCalculationEffect: "Gross Margin Calculation Effect",
    grossMarginEffect: "Gross Margin Effect",
    grossMarginNotAvailableYet: 'The gross margin calculation is not available yet.',
    grossMarginNotGenerated: "Gross margin calculation not created",
    grossMargins: "GM Calculations",
    grossMarginsOverview: "GM Calculations Overview",
    grossMarginsWarehouseCost: "Warehouse Cost (5% on purchase price)",
    grossWeight: "Gross Weight",
    group: "Group",
    groupBy: "Group by",
    groupSameSuppliers: "Group same suppliers",
    hasAgentCommissionSaveFirst: 'This contract has an agent commission. Please save first to view the calculation.',
    hasFilter: 'Has Filter?',
    help: 'Help',
    hide: "Hide",
    hideForexConversion: "Hide forex conversion",
    hideFromReport: "Hide from Report",
    hidePrint: "Hide from PDF",
    hideSignatureStamp: "Hide company stamp",
    highDensityFibreboard: 'High-density fibreboard',
    history: 'Document History',
    hp: "HP",
    hqMalaysia: 'HQ Malaysia',
    hscode: "HS Code",
    hsCode: "HS Code",
    hsCodeForImport: "HS code (for Import)",
    hscodeVeneer: "HS Code Veneer",
    hsCodeVeneer: "HS Code (Veneer)",
    hubSpotDeals: "HubSpot Deals",
    hubSpotOnly: "HS only",
    hubSpotLink: "HubSpot link",
    hubSpotStages: {
        needsDiscovered: "Needs discovered",
        offerSent: "Offer sent",
        prospectIdentified: "Prospect identified",
        start: "Start",
        underNegotiation: "Under Negotiation",
        act: "Status today"
    },
    icNo: "I/C No",
    id: 'ID',
    idc: 'IDC',
    idrRateVatCalculation: 'IDR Rate for VAT Calculation',
    ifExceedQuarterGrossMargin: 'If you exceed the Qtr GM target by',
    imperial: "Imperial",
    import: "Import",
    importToAsia: "Import to Asia",
    importantNotes: "Important Notes",
    importDuty: "Import Duty",
    importPermitForContract: "IMPORT PERMIT DATA FOR CONTRACT",
    importTaxFreight: "Import/Tax/Freight",
    importTransportEtc: "Est. total import duties, local transport, taxes, etc",
    inactive: "Inactive",
    inactivityDetected: "Inactivity detected.",
    inAppic: "In APPiC",
    includeSuspended: "include suspended",
    incomingAndArrivals: "Incoming/Arrivals",
    incoming: "Incoming",
    incomingStock: "Incoming Stock",
    incoterm: "Incoterm",
    incoTerm: "Incoterm",
    incotermOfLc: "Incoterm of L/C",
    incoterms: "Incoterms",
    indonesia: {
        travelDocument: "Surat Jalan",
        travelDocumentNo: "No. Surat Jalan",
        driver: "Driver",
        companyNote: "Nota Perusahaan",
        selfDeclaration: "Deklarasi Mandiri",
        forwarding: "Ekspidisi",
        truckNumber: "No. Polisi"
    },
    indonesiaSales: "Indonesia Sales",
    inputAmount: "Input Amount",
    inputClaim: "Input Claim",
    inputFscClaim: "Input FSC Claim",
    inputVolume: "Input Volume",
    initiator: 'Initiator',
    insertAdditionalItem: "Insert Additional Item",
    intakeDate: 'Intake Date',
    intakeValue: 'Intake Value',
    intakeCost: 'Intake Cost',
    intakeMargin: 'Intake Margin',
    intakeMarginPct: 'Margin %',
    integrateContacts: 'Integrate Contacts',
    integrateInvoices: 'Integrate Invoices',
    integrateSelection: "Integrate Selection",
    internal: "Internal",
    internalCommentsOperations: "Internal Comments for Operations",
    internalReference: "Internal Reference",
    internalRemarks: "Internal Remarks",
    intRmrks: "Int Rmrks",
    inTransit: "In-transit",
    intransitStockDetails: "In-transit Stock Details",
    intransitStockInSameItems: "In-transit stock in same item(s)",
    intransitStockPoNumber: " In-transit Stock PO Number",
    inUSD: "in USDx1000",
    invalidCredentials: 'Invalid credentials',
    invAmt: "Inv Amt",
    invoice: "Invoice",
    invoicesForVerification: "Invoice for status update",
    invoicing: "Invoicing",
    invoiceAmount: "Invoice Amount",
    invoiceCurrency: "Invoice Currency",
    invoiceDate: "Invoice Date",
    invoiceDebitNote: "Inv/DN",
    invoiceDebitNoteDate: "Inv/DN Date",
    invoiceDebitNotePaid: "Inv/DN Paid",
    invoiceDetails: "Invoice Details",
    invoicedSales: "Sales (invoiced)",
    invoiceForecast: "Invoice Forecast",
    invoiceFormResetNotification: "The invoice form has been reset!",
    invoiceItemsResetNotification: 'This invoice\'s specifications have been reset!',
    invoiceNo: "Invoice No",
    invoiceNotAvailable: 'invoice not available',
    invoiceNumber: "Invoice Number",
    invoicePreview: "Invoice Preview",
    invoicePrice: "Invoice Price",
    invoicesOverdue: 'overdue invoices',
    invoicesOverview: "Invoices Overview",
    invoiceTo: "Invoice to",
    invoiceType: "Invoice Type",
    invoiceValue: "Invoice Value",
    invoiceVolAndQty: "Inv Vol/Qty",
    invoiceToBankDrawee: "Invoice To / Bank Drawee",
    invoiceWithCurrentConversionRate: "Invoice with current conversion rate",
    invoiceAmt: "Inv Amt",
    invoiceTaxAmt: "Tax Amt",
    invoiceCurrencyCode: "Inv Cur",
    invoiceCurrencyRate: "Cur Rate",
    invPrice: "Inv Price",
    intake: 'Intake',
    intakeSales: "Sales Intake (incl. not yet invoiced)",
    integrated: "Integrated",
    ip: "IP",
    ipAddress: "IP Address",
    ipaDate: "IPA Date",
    ipaForContract: "IPA For Contract",
    ipaFormResetNotification: "The IPA form has been reset!",
    ipa: "IP Advice",
    ipaSent: "IPA Sent",
    ipas: "IP Advices",
    ipDone: "IP Done",
    isACategory: "Is a Category?",
    itemDescriptions: "Item Descriptions",
    item: "Item",
    items: "Items",
    itemDetails: 'Item Details',
    itemSpec: "Item Spec",
    itemSpecs: "Item Specs",
    itemsMissingInAppic: "Show items missing in APPiC",
    itemsMissingInXero: "Show items missing in Xero",
    itemsToList: 'items to list',
    jobStatus: "Job Status",
    journalDate: "Journal Date",
    journalGrossAmt: "Jnl Gross Amt",
    journalNetAmt: "Jnl Net Amt",
    journalSource: "Journal Source",
    jnlDate: "Jnl Date",
    landedWarehouseCost: "Landed WH Cost",
    lastContact: "Last Contact",
    lastEmail: 'Last Email',
    lastHubSpotEntry: 'Last HS entry',
    lastMeeting: 'Last Meeting Report',
    lastName: 'Last Name',
    lastNote: 'Last Note Made',
    lastReset: "Last Reset",
    lastSale: "Last Sale",
    lastUpdate: "Last update",
    lastUpload: "Latest upload",
    latePayment: "Late Payment (on Selling Price)",
    latestPaymentFromBuyer: "Latest Payment (B)",
    latestPaymentToSupplier: "Latest Payment (S)",
    latestShipmentDate: 'Latest Shipment Date',
    latinName: 'Latin Name',
    latitude: 'Latitude',
    lc: 'LC',
    lca: 'LC Advice',
    lcLatestReceivedDate: "LC Latest Received Date",
    lcas: "LC Advices",
    lcaDate: "LCA Date",
    lcBased: 'LC Based',
    lcDetails: "LC Det",
    lced: "LC E/D",
    lcaFormResetNotification: "The LCA form has been reset!",
    lcaLatestDate: "LCA Latest Received Date",
    lcDescription: "LC Description",
    lcExpiryDate: "LC Expiry Date",
    lcExpiry: "LC Expiry",
    lcInfo: "LC Information",
    lcLatestShipmentDate: 'LC Latest Shipment Date',
    lcLsd: "LC LSD",
    lcNumber: "LC Number",
    lcReceived: "LC to be Received",
    lcReceivedDate: "LC Received Date",
    lcsd: "LC S/D",
    lcTerm: "LC Term",
    lead: "Lead",
    leads: "Leads",
    legend: "Legend",
    len: "Len",
    length: "Length",
    lengths: "Lengths",
    lengthMeasurement: "Length Measurement",
    lessDeposit: "Less Deposit",
    letterheads: "Letterheads",
    letterOfCreditAdvice: "LETTER OF CREDIT ADVICE",
    levelOne: "Level 1",
    levelTwo: "Level 2",
    levelThree: "Level 3",
    lhdnDateValidated: "LHDN Date Validated",
    lhdnErrors: "LHDN Errors",
    lhdnLink: "LHDN Link",
    lhdnReceived: "LHDN Received",
    lhdnValidated: "LHDN Validated",
    lifeCycle: "Lifecycle",
    lifeCycleStages: {
        lead: "Lead",
        prospect: "Prospect",
        opportunity: "Opportunity",
        customer: "Buyer",
        noPotential: "No Potential"
    },
    lineNo: 'No',
    linked: 'Linked Document',
    linkedUrl: 'Linked Document URL',
    linkOldPurchaseOrder: 'Link pre-APPiC PO',
    linkPurchaseOrder: 'Link PO',
    linkStockOrder: 'Link Stock Arrival to pre-APPiC PO',
    listing: "Listing",
    loadActiveBuyer: "Load Active Buyer",
    loadActiveContract: "Load Active Contract",
    loadActiveCoverNote: "Load Active Cover Note",
    loadActiveDebitNote: "Load Active Debit Note",
    loadActiveInvoice: "Load Active Invoice",
    loadActiveIpa: "Load Active IPA",
    loadActiveLca: "Load Active LCA",
    loadActivePackingList: "Load Active Packing List",
    loadActivePurchaseOrder: "Load Active Purchase Order",
    loadActiveSalesOrder: "Load Active Sales Order",
    loadActiveSda: "Load Active SI",
    loadActiveSupplier: "Load Active Supplier",
    loadAnotherStockArrival: "Load Another Stock Arrival",
    loadingItems: "Loading items",
    loadingRequestedView: "Loading the requested view.",
    localAmount: "Local Amt",
    localCurrency: "Local Cur",
    localSalesInvoice: "Local Sales Invoice",
    localSalesInvoiceCannotInitialize: "Local Sales Invoice could not be initialised. Please contact APPIC developer for support.",
    location: "Location",
    loginToAppic: "Login to APPiC",
    loggedOutFrom: "You have been logged out from",
    loggingIn: "Logging In...",
    logNo: "Log No",
    logoForPrint: "Logo for Print",
    logout: "Logout",
    logOutUser: "Log Out This User",
    longitude: 'Longitude',
    lorryDriver: "Lorry Driver",
    lorryRegistration: "Lorry Registration",
    mainDetails: "Main Details",
    mainSupplier: "Main Supplier",
    mainSupplierAgent: "Main SPL/Agent",
    mainProfile: "Main Profile",
    manage: 'Manage',
    manageFooterNotes: 'Manage Footer Notes',
    manual: "Manual",
    manualEntry: "Manual entry",
    manualEntryReturn: "Manual entry / stock return",
    map: 'Map',
    mapLocation: 'Map Location',
    margin: 'Margin',
    marginPct: 'Margin %',
    marginAmount: "Margin Amount",
    markAsPaid: "Mark These Commissions as Paid",
    markComplete: "Mark as complete",
    markAsProcessed: "Mark as Processed",
    markSelectedCommissionsAsPaid: "Mark Commissions as Paid",
    marketCertification: 'Cert(M)',
    marketing: "Marketing",
    marketingActive: "Marketing (active)",
    marketingAndFinance: 'Marketing/Finance',
    marketingAndOperationsAndConfirmation: 'Marketing/Operations/Confirmation',
    marketingColleagues: "Marketing Colleagues",
    marketingReport: "Marketing Report",
    marketingReportDetail: "Marketing Report Detail",
    marketSegment: "Market Segment",
    marketSegmentListing: "Market Segment Listing",
    marketSegments: "Market Segments",
    masterList: "Master List",
    masterStockList: "Master Stock List",
    masterStockSummary: "Master Stock Summary",
    materialCategory: "Material Category",
    materialReadyWaitingBooking: "Material ready, waiting for booking",
    maxNineStops: "(max 9 stops)",
    maxNineStopsOnly: "You can only have 9 stops in your route.",
    mc: 'MC',
    mediumDensityFibreboard: 'Medium-density fibreboard',
    measurement: "Measurement",
    measurementSystem: 'Measurement System',
    mergeToNcr: 'Merge to NCR',
    mergeToPdf: 'Merge to main PDF',
    messrs: "Messrs",
    method: 'Method',
    methodFilter: "Method/Filter",
    methodOfDelivery: "Method of Delivery",
    methodTitle: "Method Title",
    metric: "Metric",
    mgPct: "%",
    minimumCommission: "Minimum Commission",
    minimumLength: "Min. length",
    missingBuyer: "Missing buyer",
    missingCertificate: "Missing certificate",
    missingClaim: "Missing claim",
    missingExpiryDate: "Missing expiry date",
    missingFmCoc: "Missing FM/COC",
    missingProduct: "Missing product",
    missingProductCategory: "Missing product category",
    missingSpecies: "Missing species",
    missingScientificName: "Missing scientific name",
    mobileNo: "Mobile No.",
    mode: "Mode",
    model: "Model",
    modeOfTransport: "Mode of Transport",
    module: "Module",
    moistureContent: 'Moisture Content',
    month: "Month",
    monthlyRates: "Monthly Rates",
    monthYear: "Month/Year",
    monthYearCommission: "M/Y Comm",
    monthYearPaid: "M/Y Paid",
    moreActions: "More actions",
    multiPagePrint: "Print Multi Page",
    mustBeEarlierThanToYear: "value must be earlier than \"to\" year",
    mustBeEarlierEqualThanToYear: 'value must be earlier than or equal \"to\" year',
    mustBeLaterThanFromYear: "value must be later than \"from\" year",
    mustBeLaterThanEqualFromYear: 'value must be later than or equal \"from\" year',
    myDashboard: "My Dashboard",
    mySalesIncentives: 'My Sales Incentives',
    na: "na",
    nameAndBuyerAccess: "Name / Buyer Access",
    ncr: "NCR",
    ncrAppic: "NCR/APPiC",
    ncrConvertThisToContract: "Convert this New Contract Request to a Contract",
    ncrNo: 'NCR No',
    ncrs: "NCRs",
    negotiableAt: "Negotiable at",
    netAmount: "Net Amount",
    netAmt: "Net Amt",
    netDue: "Net Due",
    netWeight: "Net Weight",
    neverAccessed: "never accessed",
    new: 'New',
    newBundle: "New Bundle",
    newBundleNoUnchanged: "The new bundle number must be different than the original bundle number (add \"-2\", etc).",
    newBundleValuesZero: "The new bundle pieces or volume cannot be \"0\"",
    newBuyer: "New Buyer",
    newBuyers: 'New Buyers',
    newBuyerProfile: "New Buyer Profile",
    newBuyerUnsaved: 'This new buyer has not been saved yet!',
    newCommissionInvoice: "New Commission Invoice",
    newCommissionInvoiceUnsaved: 'This new invoice has not been saved yet!',
    newContract: "New Contract",
    newContractNumber: "New Contract Number",
    newContractRequest: "New Contract Request",
    newContractUnsaved: 'This new contract has not been saved yet!',
    newCoverNoteUnsaved: 'This new cover note has not been saved yet!',
    newCreditNote: "New Credit Note",
    newDebitNote: "New Debit Note",
    newExportPR: "New Export PR",
    newExportPurchaseExport: "New Export PR",
    newEprUnsaved: "This new export purchase request has not been saved yet!",
    newGrossMarginUnsaved: 'This gross margin calculation has not been saved yet!',
    newInvoice: "New Invoice",
    newInvoiceUnsaved: 'This new invoice has not been saved yet!',
    newInStageSevenDays: "New in stage (< 7 days)",
    newInStageFourteenDays: "In stage (< 14 days)",
    newIpaUnsaved: 'This new IPA has not been saved yet!',
    newLcaUnsaved: 'This new LCA has not been saved yet!',
    newLocalSalesInvoiceUnsaved: 'This new local sales invoice has not been saved yet!',
    newMargin: 'New Margin',
    newMarginPct: 'New Margin %',
    newNcrUnsaved: 'This new contract request has not been saved yet!',
    newPackingList: "New Packing List",
    newPackingListUnsaved: 'This new packing list has not been saved yet!',
    newPallet: "New Pallet",
    newPassword: "New Password",
    newPaymentUnsaved: 'This new Payment has not been saved yet!',
    newPODetails: "New PO Details",
    newProfile: "New Profile",
    newPO: "New PO",
    newPoNumber: "New PO Number",
    newPurchaseOrder: "New Purchase Order",
    newPurchaseOrderUnsaved: 'This new purchase order has not been saved yet!',
    newRatesAfter: "New rates after",
    newRemark: "New Remark",
    newSalesOrderUnsaved: "This new sales order has not been saved yet!",
    newSdaUnsaved: 'This new Shipping Instruction has not been saved yet!',
    newSupplierUnsaved: "This new supplier has not been saved yet!",
    newStockEntry: "New Stock Entry",
    newStockOrderUnsaved: "This new stock entry has not been saved yet!",
    newStockPR: "New Stock PR",
    newTotal: "New Total",
    newUserUnsaved: 'This new user has not been saved yet!',
    newWarehousePR: "New Warehouse PR",
    newWarehousePurchaseRequest: "New Warehouse Purchase Request",
    newWPR: "New WPR",
    newWprUnsaved: "This new warehouse purchase request has not been saved yet!",
    nil: "nil",
    no: "No",
    noAccess: "No Access",
    noAppicRecord: "No APPiC record",
    noXeroRecord: "No Xero record",
    noDocumentsFound: 'No documents found.',
    noContactsFound: 'No contacts found.',
    noContactsSelected: 'No contacts selected or none of your contacts have a valid email address.',
    noConversion: 'No Conversion',
    noDashboardsAssigned: 'You have no dashboards assigned to your profile.',
    noDataToExport: "There are no commissions to export.",
    noDocumentHistoryHere: "No document history available.",
    noDocumentsAvailable: "No documents available.",
    noEutrComplianceFilesAvailable: "No compliance files available/uploaded.",
    noFilesSelected: "No files select,ed. Please select two or more files.",
    noMovement: 'No movement',
    none: "None",
    noFilter: "No filter",
    noNotificationHistoryHere: "No notification history available.",
    nonStandard: "Non-Standard",
    nonVeneer: "Non-veneer",
    noOfContainers: "No. of Containers",
    noOfDays: "No. of Days",
    noOfDocumentsRequired: "Copies Required",
    noPotential: "No Potential",
    noPurchaseOrderAvailable: "This contract has no related PO.",
    noResultsFetched: "No records fetched.",
    noResultsMatching: "No matching results.",
    noShippingDocumentsAvailable: "No shipping documents available/uploaded.",
    notAllowed: "Not Allowed",
    note: "Note",
    notes: "Notes",
    noteDateFiltersAreRanges: "Note* Date filters are ranges.",
    noteDocumentHistory: "Note* Only post APPiC document history is recorded.",
    noteNotificationHistory: "Note* Only post APPiC notification history is recorded.",
    notFound: "Not Found",
    notifyingParty: "Notifying Party",
    notifyPartyAWB: "Notify Party AWB",
    notifyPartyBL: "Notify Party BL",
    notifyPartyBl: 'Notify party BL (Name and address)',
    notifyUser: "Notify user",
    noTrackingInformationHere: "No tracking information available.",
    number: "Number",
    numberOfContainers: "No. of Containers",
    office: "Office",
    officeOwner: "Office/Owner",
    officeTelephone: "Office Telephone",
    old: "Old",
    oldStock: "Old Stock",
    only: 'only',
    optimizeRoute: "Optimize Route",
    oops: 'Oops!',
    open: 'Open',
    openHubSpotCompany: "Open in HubSpot",
    openHubSpotDeal: "Open in HubSpot",
    openHubSpotProspect: "Open in HubSpot",
    opening: 'Opening',
    operational: "Operational",
    operations: "Operations",
    operationsData: "Operations Data",
    operationsDataOverview: "Operations Data Overview",
    operationsRemarks: "Operations Remarks",
    opportunities: "Opportunities",
    or: 'or',
    orderDetails: "Order Details",
    orEnterContractDetailsManuallyBelow: "or enter the contract details manually below",
    orEnterPoDetailsManuallyBelow: "or enter the PO details manually below",
    onHold: "On Hold",
    onInvoice: "On Invoice",
    origin: "Origin",
    originAndDestination: "Origin/Dest",
    originCertification: 'Cert(O)',
    originalAmount: "Original Amount",
    originalBundle: "Original Bundle",
    originalContract: "Original Contract",
    originalCurrency: "Orig Cur",
    originalPallet: "Original Pallet",
    originalShippingDocument: 'Original shipping document delivery (name / address)',
    originalWarehousePo: 'Original Warehouse PO',
    osd: "OSD",
    otherAddress: "Other Address",
    otherAddresses: "Other addresses",
    otherCharges: "Other Charges",
    otherContractDocuments: 'Other Contract Documents',
    otherCost: "Other Cost",
    otherCosts: "Other Costs",
    otherDetails: "Other Details",
    otherGroupMembers: 'Other Group Members',
    otherLocations: "Other Locations",
    otherPartialShipments: "Other Partial Shipments",
    otherRelatedName: "Other (related) Name",
    others: "Others",
    otherSession: "other session.",
    otherSessions: "other sessions.",
    otherSessionsDetected: "Other sessions detected",
    otherRequests: 'Other Requests',
    otsDpPaidTerm: "OTS DP Paid Term",
    otsFullyPaidTerm: "OTS Fully Paid Term",
    otsLogin: "OTS Login",
    otsName: "OTS Name",
    otsOverrideForAddress: "OTS Override for address",
    otsPassword: "OTS Password",
    otsShortName: "OTS Short Name",
    otsTerm: "OTS Term",
    otsUserId: "OTS User ID",
    outboundNotifications: 'Outbound Notifications',
    outputAmount: 'Output Amount',
    outputClaim: 'Output Claim',
    outputVolume: 'Output Volume',
    outstanding: "Outstanding",
    overdue: "Overdue",
    overDue: "Overdue",
    overdueDays: "Overdue (days)",
    overPayment: "Over Pymt",
    overPayments: "Over Payments",
    overPaymentDetails: "Over payment details",
    owner: "Owner",
    paid: "Paid",
    paidBy: "Paid By",
    paidOn: "Paid on",
    paidAmount: 'Paid Amount',
    paidInvoices: 'Paid Invoices',
    paidCommissions: 'Paid Commissions',
    paidFromBuyer: "Paid (B)",
    paidFromSupplier: "Paid (S)",
    paidOutAmount: "Paid Out Amount",
    paidOutCommissions: "Paid Out Comm",
    paidPresentedTo: "To be paid/presented to",
    paidTo: "To be paid to",
    password: "Password",
    packages: "Packages",
    packageType: "Package Type",
    packingList: "Packing List",
    packingListDate: "Packing List Date",
    packingListFormResetNotification: "The packing list form has been reset",
    packingListItemsResetNotification: 'This packing list\'s item list have been reset!',
    packingListNotConverted: "This packing list is pre-APPiC. Please convert it from the TMS format before printing it.",
    packingListNumber: "Packing List Number",
    packingListPreview: "Packing List Preview",
    packingLists: "Packing Lists",
    pallet: "Pallet",
    palletNo: "Pallet No",
    pallets: "Pallets",
    partialContract: "Partial Contract",
    particleBoard: "Particle board",
    payable: "Payable",
    payableInvoice: "Payable Invoice",
    paymentSchedule: "Payment Schedule",
    payments: "Payments",
    paymentBuyer: "Pymt B",
    paymentDate: "Payment Date",
    paymentDueDate: "Payment Due Date",
    paymentReceivedDate: "Payment Rec. Date",
    paymentRecordNotFound: "Payment model does not exist. Please contact the APPiC developer.",
    paymentSentDate: "Payment Sent Date",
	paymentStatus: "Payment Status",
    paymentSupplier: "Pymt S",
    paymentTerm: "Payment Term",
    paymentTermBuyer: "Payment Term (B)",
    paymentTermSupplier: "Payment Term (S)",
    paymentTerms: "Payment Terms",
    paymentTermsSellingPrice: "Payment Terms (on Selling Price)",
    paymentTermsAppic: "APPiC - Payment Terms",
    paymentTermsContract: "Contract - Payment Terms",
    paymentTermsPo: "PO - Payment Terms",
    payOn: "Pay on",
    pcs: "Pcs",
    pcsSheets: "Pcs/Shts",
    pct: "Pct",
    pctTotalValue: '% of Total Value',
    pctWareHouseStock: '% of Warehouse Stock',
    pdf: "PDF",
    pdfFilesOnlyClickUpload: "PDF files only. Please click \"upload\" after selecting the file.",
    pdfServer: "PDF Server",
    pefc: "PEFC",
    pefcCertificate: "PEFC Certificate",
    pefcCertificateNo: "PEFC Certificate No",
    pefcCertification: "PEFC Certification",
    pefcCertified: 'PEFC Certified',
    pefcClaim: "PEFC Claim",
    pefcDescription: "PEFC Descr",
    pefcExpiryDate: "PEFC Expiry Date",
    pefcMaterialCategory: "PEFC Material Category",
    pefcProduct: "PEFC Product",
    pefcProductType: "PEFC Product Type",
    pendingDeposit: "Pending Deposit",
    pendingImportPermit: "Pending Import Permit",
    pendingOutboundNotifications: 'Pending Outbound Notifications',
    pendingTransfers: 'Pending Transfers',
    pendingValidLc: "Pending Valid LC",
    perLine: "per line",
    perUnit: "Per Unit",
    phone: "Phone",
    pieces: "Pieces",
    piNo: "PI No",
    piSoNo: "PI/SO No",
    placeholder: "Placeholder",
    placeOfOrigin: "Place of Origin",
    plantQuarantineQty: "Plant Quarantine Qty",
    plantQuarantineQuantity: "Plant Quarantine Quantity",
    pleaseClick: 'Note: Please click',
    toUpdateValue: 'to update the value',
    pleasePayInUSD: "Please pay total amount due in USD",
    pleaseProvide: "Please provide",
    pleaseRead: "Please Read",
    pleaseRemitTo: "Please Remit To",
    pleaseSaveFirstGrantAccess: "Please save this new contact first before granting APPiC access.",
    pleaseSelectSalesColleagueFirst: 'Please select a sales colleague first.',
    pleaseSign: "Please stamp, sign and return",
    pleaseWait: "Please wait, preparing the documents...",
    plDate: "PL Date",
    plNo: "PL No",
    plNumber: "PL Number",
    plywood: "Plywood",
    pnql: "Prior Notice Quarantine Letter",
    pnqlContact: "PNQL* Contact",
    po: "PO",
    poContract: "PO/Contract",
    poContractInvoice: "PO/Contract/Inv",
    poAmount: "PO Amt",
    poBoard: "PO Board",
    poBundle: "PO/Bundle",
    poCur: "PO Cur",
    poCurrency: "PO Currency",
    pod: "PoD",
    poDate: "PO Date",
    poDetails: "PO Details",
    pol: 'PoL',
    poNo: "PO No",
    poNumber: "PO Number",
    poPaymentRecordNotFound: "You cannot enter any payments because the PO Payment model does not exist. Please contact the APPiC developer to fix this issue.",
    poPrice: "PO Price",
    poQuantity: "PO Qty",
    port: "Port",
    portOfDestination: "Port of Destination",
    portOfDischarge: "Port of Discharge",
    portOfLoading: "Port of Loading",
    pos: "POs",
    poSigned: "PO Signed",
    position: "Position",
    postcode: "Postcode",
    postInvoiceGp: "Post Invoice to GP",
    postToGp: "Queue To GP",
    postToGpQueue: "Send to GP posting queue",
    postToXero: "Post To Xero",
    posted: "Posted",
    potential: "Potential",
    poTypeInsurance: "PO Type Ins.",
    poUnsigned: "Unsigned PO",
    poVolAndQty: "PO Vol/Qty",
    preAppic: "Pre APPiC version",
	preAppicDocuments: "Pre APPiC documents",
    preAppicPackingList: "The items in this packing list are in the old TMS format. Click the button below to convert it to the APPiC format before you update the items.",
    precision: "Precision",
    preferences: "Preferences",
    preferredSellerCaption: 'Preferred "Seller" caption',
    preferredBuyerAndAddressCaption: "Preferred Buyer & Address captions",
    preferredDeliveryAddress: "Preferred Delivery Address",
    preferredEta: "Preferred ETA",
    preferredEtd: "Preferred ETD",
    preferredFooterNotes: "Footer Notes",
    preferredSupplier: "Preferred Supplier",
    pressEnterToSearch: "Press \"enter\" to filter",
    pressEnterToCreateNewOne: "Press \"enter\" to create a new one",
    presentationDocuments: "Presentation of Documents",
    preview: "Preview",
    previewContract: "Preview Contract",
    previewInvoice: "Preview Invoice",
    previewNcr: "Preview Contract Request",
    previewPackingList: "Preview Packing List",
    previewProfile: "Preview Profile",
    previewPurchaseOrder: "Preview Purchase Order",
    previewSalesOrder: "Preview Sales Order",
    previewWpr: "Preview Warehouse Purchase Request",
    previousAppicBuyer: "APPiC buyer",
    previousMonth: "Previous Month",
    previousRemark: 'Previous Remark',
    previousRemarks: 'previous remarks',
    price: "Price",
    priceList: "Price List",
    priceMargin: "Price Margin",
    priceMargins: "Price Margins",
    print: "Print",
    printChart: "Print Chart",
    printDirections: "Print Directions",
    printDummyInvoice: "Print Dummy Invoice",
    printDummyPackingList: "Print Dummy Packing List",
    printMap: "Print Map",
    printPDF: "Print PDF",
    printPreferences: "PDF Preferences",
    printSummary: "Print Summary",
    printTwoPages: "Print in 2 Pages",
    printVisibility: "Print?",
    proceed: "Proceed",
    procurement: "Procurement",
    proc: "Proc",
    professionalFeeForServices: "Professional fees for services related to",
    profileName: "Profile Name",
    proforma: "Proforma",
    proformaInvoice: "Proforma Invoice",
    proformaInvoiceNumber: "Proforma Invoice No.",
    proformaInvoiceExchangeRate: "Proforma Invoice Ex-Rate",
    proformaInvoiceExchangeRateDate: "Proforma Invoice Ex-Rate Date",
    product: "Product",
    productCategory: "Product Category",
    productCode: "Product Code",
    productGroup: "Product Group",
    productGroupListing: "Product Group Listing",
    productGroups: "Product Groups",
    productHsCode: "Product HS Code",
    productOrigin: "Product Origin",
    productPortToPort: "Product Port to Port",
    products: "Products",
    productsList: "Products List",
    productsOffered: "Products Offered",
    productSearch: "Product Search",
    productSegment: "Product Segment",
    productSegmentListing: "Product Segment Listing",
    productSegments: "Product Segments",
    productTable: "Product Table",
    productType: "Product Type",
    profile: "Profile",
    profiles: "Profiles",
    profitAndLossForecast: "P & L Forecast",
    profitAndLossTool: "P & L Tool",
    project: "Project",
    prospect: "Prospect",
    prospects: "Prospects",
    purchaseCost: 'Purchase Cost',
    purchaseOrder: "Purchase Order",
    purchaseOrderNo: "Purchase Order No",
    purchaseOrderAmount: "",
    purchaseOrderDate: "PO Date",
    purchaseOrderFormResetNotification: "The purchase order form has been reset!",
    purchaseOrderNumber: "PO Number",
    purchaseOrders: "Purchase Orders",
    purchaseOrdersOverview: "Purchase Orders Overview",
    purchaseOrderPreview: "Purchase Order Preview",
    purchaseOrderQty: "PO Qty",
    purchasePrice: "Purchase Price",
    purchaseValue: "Purchase Value",
    purpose: 'Purpose',
    partialShipment: "Partial Shipment",
    pl: "PL",
    plannedDate: "Planned Date",
    pleaseTypeContractNo: "Please type the contract number manually.",
    printLongFormat: "Print Long Format",
    pymtDate: "Pymt Received",
    qty: "Qty",
    quantity: 'Quantity',
    quantityAndUofm: "Quantity/Unit",
    quantityPricing: 'Quantity/Pricing',
    queueForPosting: "Queue for Posting",
    taxRate: 'Tax Rate',
    rateAgainstRm: 'Rate Against RM',
    rateDateFirstDay: "Effective Date (always first of the month)",
    ratePerCbm: "Rate/M3",
    readFile: "Read File",
    readFiles: "Read Files",
    readingFile: "Reading File",
    readingFiles: "Reading Files",
    reasonForAddition: 'Reason for increase',
    reasonForCreditNote: "Reason for credit note",
    reasonForDebitNote: "Reason for debit note",
    reasonForDeduction: 'Reason for deduction',
    reasonForPurchase: 'Reason for Purchase',
    rebootPdfServer: 'Reboot Pdf Server',
    rebootServer: 'Reboot Server',
    recipients: "Recipients",
    receivable: "Receivable",
    received: "Received",
    receivedDate: "Received Date",
    receivedGoodCondition: "Received the above goods in good condition",
    receiveNotifications: 'Receive Notifications',
    reconnectXero: "Reconnect to Xero",
    recordDate: "Record Date",
    recordId: "Record Id",
    redirectingToAppicReport: "Redirecting you to the APPiC Report",
    redirectingToDashboard: "Redirecting you to your dashboard",
    regenerate: "Regenerate",
    reference: "Ref",
    referenceInvoiceNumber: "Reference Invoice Number",
    refetch: "Refetch",
    reject: "Decline",
    rejected: "Declined",
    rejectionReason: "Rejection Reason",
    rejectNcr: "Decline New Contract Request",
    rejectWpr: "Decline Warehouse Purchase Request",
    relatedComponent: "Related Component",
    relatedContract: "Related Contract",
    relatedContractSo: "Related Contract/Sales Order",
    relatedFiles: "Related Files",
    relatedInvoice: "Related Invoice",
    relatedPurchaseOrder: "Related Purchase Order",
    relatedSalesOrder: "Related Sales Order",
    relatedShipment: "Related Shipment",
    releaseDate: "Release Date",
    releaseOrder: "Release Order",
    remark: "Remark",
    remarks: "Remarks",
    remarksForBuyer: "Remarks (for Buyer)",
    remarksInContractAuto: "Remarks in contract (auto)",
    remarksInPoAuto: "Remarks in PO (auto)",
    remarksInternal: "Internal Comments for Operations",
    remitTo: "Remit To",
    remove: "Remove",
    removeAccess: "Remove Access",
    removeAdditionalItem: "Remove Additional Item",
    removeFromGroup: "Remove from Group",
    removeNotes: "Remove Notes",
    removeSidebar: 'Remove Sidebar',
    report: "Report",
    reportChart: "Chart",
    reportDate: "Report Date",
    reportFilter: "Report Filter",
    reportResult: "Result",
    reports: "Reports",
    reportsAndLists: "Reports & Lists",
    reportType: "Report Type",
    representedBy: "Represented by",
    required: "Required",
    resendCode: "Resend Code",
    resendCodeIn: "Resend Code in",
    resendInvite: "Resend Invite",
    reserve: "Reserve",
    reserved: "Reserved",
    reserveBundle: "Reserve Bundle",
    reset: "Reset",
    resetAllPasswords: 'Reset All Passwords',
    resetAllUserPasswords: 'Reset These User Passwords',
    resetBuyerForm: "Reset buyer form",
    resetContractForm: "Reset contract form",
    resetCoverNoteForm: "Reset cover note form",
    resetDebitNoteForm: "Reset debit note form",
    resetGrossMarginForm: "Reset gross margin calculation form",
    resetInvoiceForm: "Reset invoice form",
    resetIpaForm: "Reset IPA form",
    resetItems: "Reset items",
    resetLcaForm: "Reset LCA form",
    resetMap: "Recenter Map",
    resetPackingListForm: "Reset packing list form",
    resetPasswordFor: "Reset password for",
    resetPurchaseOrderForm: "Reset purchase order form",
    resetSalesOrderForm: "Reset sales order form",
    resetSalesOrderItems: "Reset sales order items",
    resetSdaForm: "Reset Shipping Instruction form",
    resetSpecifications: "Reset specifications",
    resetStockArrivalBundles: "Reset stock entry bundles",
    resetStockArrivalForm: 'Reset stock entry form',
    resetSupplierForm: "Reset supplier form",
    resetWprForm: "Reset warehouse purchase request form",
    resetWprItems: "Reset warehouse purchase request items",
    reopenContract: "Reopen Contract",
    restoreContract: "Restore Contract",
    resubmit: "Resubmit",
    resubmitInvoice: "Resubmit invoice",
    result: "Result",
    resultDetail: "Details",
    results: "Results",
    resultsBy: "Result By",
    revenues: "Revenues",
    revert: "Revert",
    revertReason: "Revert reason",
    revertLhdnInvoice: "Revert LHDN invoice",
    revisedBundleNoUnchanged: "The original bundle number must be changed (add \"-1\", etc).",
    revision: "Revision",
    revisionNumber: "Revision Number",
    rmRateGstCalculation: "RM Rate for GST Calculation",
    rocRobNo: 'ROC/ROB No.',
    role: "Role",
    shipmentType: "Shipment Type",
    speciesOffered: "Species Offered",
    sailed: "Sailed",
    sailingConfirmed: "Sailing Confirmed",
    salesAmount: "Sales Amount",
    salesAndMarketing: "Sales & Mktg",
    salesBudgets: "Sales Budgets",
    salesColleague: "Sales Colleague",
    salesCommissions: "Sales Commissions",
    salesCommissionsOverview: "Sales Commissions Overview",
    salesColleagues: "Sales Colleagues",
    salesData: "Sales Data",
    salesGroup: "Sales Group",
    salesIncentives: "Sales Incentives",
    salesItemsResetNotification: 'This contract\'s specifications have been reset!',
    salesStaff: "Sales staff",
    salesOrder: "Sales Order",
    salesOrderFormResetNotification: "The sales order form has been reset!",
    salesOrderNo: "Sales Order No.",
    salesOrderNumber: "Sales Order No.",
    salesOrders: "Sales Orders",
    salesOrdersOverview: "Sales Orders Overview",
    salesOrderPreview: "Sales Order Preview",
    salesOrderSigned: "Sales Order Signed",
    salesPrice: "Sales Price",
    salesTeam: "Sales Team",
    salesTerritory: "Sales Territory",
    salesType: "Sales Type",
    salutation: "Salutation",
    sameAsBuyer: 'Same as buyer',
    sameAsInContract: "same as in contract (default)",
    sameGroupAs: "Same group as",
    sameNameSuppliers: 'Suppliers with same name.',
    sameSupplierAs: "Same supplier as",
    save: "Save",
    saving: "Saving...",
    saveChanges: "Save Changes",
    saveCommission: "Save Commission",
    saveDate: "Save Date",
    saveNewBuyer: "Save New Buyer",
    saveNewContract: "Save New Contract",
    saveNewContractRequest: 'Save New Contract Request',
    saveNewCoverNote: "Save New Cover Note",
    saveNewDebitNote: "Save New Debit Note",
    saveNewGrossMarginCalculation: "Save New GM Calculation",
    saveNewInvoice: "Save New Invoice",
    saveNewIpa: "Save New IPA",
    saveNewLca: "Save New LCA",
    saveNewLocalSalesInvoice: "Save New Local Sales Invoice",
    saveNewPackingList: "Save New Packing List",
    saveNewSalesOrder: "Save New Sales Order",
    saveNewSda: "Save New Shipping Instruction",
    saveNewStockArrival: "Save New Stock Arrival",
    saveNewUser: "Save New User",
    saveNewWpr: "Save New Warehouse Purchase Request",
    sc: "SC",
    scac: "SCAC",
    scientificName: 'Scientific Name',
    scNotes: "SC Notes",
    scrollingList: "Scrolling List",
    sda: "Shipping Instructions",
    sdaCountryCustomerDocuments: "Country or Customer Specific Document Requirements/Instructions",
    sdaOtherRequests: "Other Requests",
    sdaPresentationDocuments: "Presentation of Documents",
    sdaStandardDocuments: "Standard Documents Required (in triplicate unless stated differently)",
    sealNo: "Seal No.",
    searchColumn: "Search Column",
    searchResults: "Search Results",
    searchTerm: "Search Term",
    seconds: "seconds",
    seePresentationOfDocuments: 'See Presentation of Documents',
    seeRemarks: "See Remarks",
    seeRequestsList: "See Requests List",
    seeRemarksField: "See Remarks Field",
    selectAll: "Select all",
    selectAtLeastOneOption: "Select at least one option.",
    selectContacts: "Select Contacts",
    selectContract: "Select Contract",
    selectDeselectPriceEdit: "Select/deselect all for price edit",
    selectDocuments: "Select Documents",
    selectEpr: 'Select EPR',
    selectExcelFile: "Select Excel File",
    selectFile: "Select File",
    selectNcr: 'Select NCR',
    selected: "Selected",
    selectMoreThanOneFile: "You can only merge two or more files.",
    selectOne: "Please Select One",
    selectOptionOptions: "Select Option/Options",
    selectOptionOrFreeText: "Select an option or write free text",
    selectOptionsForFiltering: 'Select options for filtering',
    selectNewStatus: 'Select new status',
    selectProductFirst: 'Select product first',
    selectUser: "Select User",
    selectQuantitySampleImage: "Select a quantity sample image file",
    selectRelatedContract: "Please select a related contract",
    selectSalesColleague: 'Select Colleague',
    selectSizeSampleImage: "Select a size sample image file",
    selectSalesColleagues: 'Select sales colleagues',
    selectSalesColleagueFirst: 'Please select a sales colleague first',
    selectShipment: "Please select a shipment",
    selectTallySheetFile: "Select a tally sheet file",
    selectToFilter: "Select to filter",
    selectWarehouseStock: "Please select a bundle from the warehouse",
    seller: "Seller",
    sellerSupplier: "Seller/Supplier",
    sellingPrice: "Selling Price",
    sellingPriceCurrency: "SP Cur",
    sellingValue: "Selling Value",
    send: "Send",
    sendComplianceFilesToBuyer: "Send compliance files to buyer",
    sendDate: "Send Date",
    sendDateTime: "Send Date & Time",
    sendDocumentsToBuyer: 'Send documents to buyer',
    sender: "Sender",
    sendContractToSales: "Send This Contract to Sales",
    sendCourierInformationToBuyer: "Send courier info to buyer.",
    sendListToSalesColleagues: "Send list to sales colleagues.",
    sendLoginInfoToBuyer: "Send Login Info to Buyer",
    sendNotification: 'Send notification',
    sendNotificationToUsers: 'Send notification to users',
    sendNotificationToBuyer: 'Send notification to buyer',
    sendNotificationToFinance: 'Send notification to finance',
    sendOriginalDocuments: "please send a full set of original documents <u>by courier</u> to:",
    sendRemark: "Send Remark",
    sendRemarkToSalesColleague: "Send a remark to the sales colleague.",
    sendSlipToSupplier: "Send payment slip to supplier",
    sendThisContainerPreloadingInspectionReportToSupplier: 'Send this container inspection report request to supplier.',
    sendThisImportPermitAdviceToBuyer: 'Send this import permit slip advice to the buyer.',
    sendThisImportPermitToSupplier: 'Send this import permit to supplier.',
    sendThisLcAdviceToBuyer: 'Send Letter of Credit Advice to the buyer.',
    sendThisPaymentConfirmationToBuyer: "Send this payment confirmation to the buyer.",
    sendThisPaymentSlipToSupplier: "Send this payment slip to the supplier.",
    sendThisShippingInstructionToSupplier: "Send this shipping instruction to the supplier.",
    sendTo: "Send To",
    sendToBuyer: "Send to Buyer",
    sendToSupplier: "Send to Supplier",
    sentBy: "Sent By",
    sentDate: "Sent Date",
    separatedPages: "Separated pages",
    service: "Service",
    servicedSales: "Serviced Sales",
    sessionStopped: 'Session expired.',
    sessionExpired: "Your session has expired. You can login again.",
    setting: "Setting",
    sfc: 'SFC',
    sheets: "Sheets",
    shipment: "Shipment",
    shipments: "Shipments",
    shipmentsOverview: "Shipments Overview",
    shipmentStatus: "Shipment Status",
    shipping: "Shipping",
    shippingInfoAndDocuments: "Shipping Info and Documents",
    shippingUploadFileMaxSize: "Combined file size < 7MB, other files < 3MB)",
    shippingWeekStatement: 'Shipping Week Statement',
    shipped: "Shipped",
    shipper: "Shipper",
    shipperDetail: "Shipper Detail",
    shippedOn: "Shipped On",
    shippedVolAndQty: "Shipped Vol/Qty",
    shippedUnitPrice: "Shipped Unit Price",
    shippingAgents: "Shpg Agents",
    shippingInstructions: "Shipping Instructions",
    shippingDocuments: "Shipping Documents",
    shippingDocumentsOverview: "Shipping Documents Overview",
    shippingInstructionDocumentManager: 'SI Doc Mgmt',
    shippingInfo: "Shipping Info Summary",
    shippingPort: "Shipping Port",
    shippingPorts: "Shpg Ports",
    shippingUnit: "Shipping Unit",
    shippingWeek: "Shipping Week",
    shpg: "Shpg",
    shpStatus: "Shp Status",
    show: "Show",
    showActive: "Show active",
    showAll: "Show all",
    showAlsoNotifyPartyBl: " Show \"Also Notify Party BL\"",
    showBankDetails: "Show Bank Details",
    showCustomerAddress: "Show Customer Address",
    showCompanyStamp: "Show Company Stamp",
    showGroupedSuppliers: "Show grouped suppliers",
    shownInContract: 'to be shown in contract',
    showContractsNoInvoice: 'show DS contracts w/o invoices',
    showOtherValues: "Show \"Other\" Values",
    showNoPotentials: "Show no potentials",
    showProspects: "Show prospects",
    showSignatureBox: "Show signature box",
    showSleeping: "Show sleeping",
    showInactive: "Show inactive",
    showLeads: "Show leads",
    showSuppliers: "Show suppliers",
    showTotals: "Show Totals",
    showTotalValues: "Show Total Values",
    showValueCif: "Show Value As CIF",
    showValueCfr: "Show Value As CFR",
    showValueFob: "Show Value As FOB",
    si: "Shipping Instructions",
    siDate: 'SI Date',
    siDocumentManager: "SI Doc Mgmt",
    shippingDocumentAddresses: "Shpg Docs Addr",
    siForm: "Shipping Instructions",
    signed: "Signed",
    signedContract: 'Signed Contract',
    signedContractNotAvailable: "Signed contract has not been uploaded yet.",
    signedPo: 'Signed PO',
    signSalesOrder: "Sign Sales Order",
    simpleList: "Simple List",
    since: "Since",
    siReference: "SI Reference",
    siSent: 'SI Sent',
    siTerms: "SITerms",
    site: "Site",
    size: "Size",
    sizeAsShownContract: "Size (as shown in contract)",
    sizeAsShownInvoice: "Size (as shown in invoice)",
    sizeAsShownSalesOrder: "Size (as shown in sales order)",
    sizeRemarks: "Size Remarks",
    sizes: "Sizes",
    skype: "Skype",
    slip: "Slip",
    sleeping: 'Sleeping',
    sleepingBuyer: 'Sleeping buyer',
    sleepingBuyers: 'Sleeping Buyers',
    sleepersAndLeads: "Sleepers & Leads",
    slicedVeneer: "Sliced Veneer",
    sold: "Sold",
    sort: "Sort",
    sorting: "Sorting",
    sortId: "Sort Number",
    sortReports: "Sort Reports",
    sortStandardShippingDocuments: "Sort",
    soNo: "SO No",
    source: "Source",
    spec: 'Spec',
    specie: "Specie",
    species: "Species",
    speciesAndQty: "Species and Qty",
    speciesSpecSize: "Species/Spec/Size",
    specAndMc: "Spec and MC",
    speciesGroup: "Species Group",
    speciesTradeName: "Species (Trade Name)",
    speciesScientificName: "Species (Scientific Name)",
    specs: "Specs",
    specsAndMC: "Specs and MC",
    specification: "Specification",
    specificationAndGrade: 'Specification / Grade',
    specificationAsShownContract: "Specification (as shown in contract)",
    specificationAsShownInvoice: "Specification (as shown in invoice)",
	specificationAsShownSalesOrder: "Specification (as shown in sales order)",
    specificationAsShownPurchaseOrder: "Specification (as shown in PO)",
    specificationGradeSize: 'Specification / Grade / Size',
    specifications: "Specifications",
    specificationNotes: "Specification Notes",
    specificationsData: "Specifications Data",
    specificationsDataOverview: "Specifications Data Overview",
    spl: "Spl",
    splitted: "Splitted",
    splitBundle: "Split Bundle",
    splitReBundle: "Split/Re-bundle/Re-pallet",
    splitPallet: "Split Pallet",
    splitReason: "Reason to split bundle/pallet",
    splPayment: "Spl Payment",
    splProfomaInvoice: "Spl PI#",
    sstNo: "SST No",
    stage: "Stage",
    stageMovement: "Stage Movement",
    standardCarrierAlphaCode: "Standard Carrier Alpha Code (SCAC)",
    standardDescription: "Standard Description",
    standardDocuments: 'Standard Documents',
    standardForwarderCost: "Std Fwd Cost",
    standardForwarderCostFullText: 'Standard Forwarder Cost',
    standardWarehouseCost: "Std WH Cost",
    standardWarehouseCostFullText: "Standard Warehouse Cost",
    standardShippingDocuments: 'Std Docs',
    startDate: "Start Date",
    startTypingToSearch: "Start typing to search",
    standardDataFilters: "Std Data Filters",
    state: "State/Province",
    stateProvince: "State/Province",
    status: "Status",
    statusAndDates: "Status and Dates",
    statusBoard: "Status Board",
    statusBoards: "Status Boards",
    statusDatesCarrier: "Status, Dates & Carrier Info",
    stkOrder: "Stk Order",
    stock: "Stock",
    stockArrival: "Stock Arrival",
    stockArrivalFormResetNotification: "The stock entry form was reset!",
    stockAtPurchaseValue: 'Stock at Purchase Value',
    stockDetails: "Stock Details",
    stockEntry: "Stock Entry",
    stockInDate: "Stock In Date",
    stockList: "Stock List",
    stockManagement: "Stock Mgmt",
    stockMasterList: {
        unitPriceCalculated: 'Unit price calculated from non-USD currency using the \"ex-Rate\" above. The original unit price is not in USD. Please refer to the PO for the original value.'
    },
    stockOrder: "Stock Order",
    stockOrderCur: "SO Cur",
    stockOrderListing: "Stock Order Listing",
    stockPoNumber: "Stock PO Number",
    stockPoSummary: "Stock PO Summary",
    stockPR: "Stock PR",
    stockPurchaseRequests: "Stock Purchase Requests",
    stockUploadTemplate: "Stock Excel Upload",
    stockReport: "Stock Report",
    stockReports: "Stock Reports",
    stocks: "Stocks",
    stocksListing: "Stocks Listing",
    stocksOverview: "Available Stocks Overview",
    storageCost: "Storage Cost",
	street: 'Street',
    streetAddress: 'Street Address',
    subCode: "Sub-Code",
    subject: "Subject",
    subjectAction: 'Subject/Action',
    submitEpr: "Submit EPR",
    submitted: "Submitted",
    submittedBy: "Submitted by",
    submitToDsm: "Submit to DSM",
    submitToLhdn: "Submit to LHDN",
    submitToLhdnQueue: "Submit to LHDN queue",
    submitToOperations: "Submit to Operations",
    submitNcr: "Submit Contract Request for Review/Approval",
    submitWpr: "Submit Warehouse Purchase Request for Approval",
    subSupplier: "Sub-Supplier",
    successes: {
        accessDeleted: "Access has been deleted.",
        accessUpdated: "Access has been updated.",
        agentAdded: "Agent has been added.",
        agentUpdated: "Agent has been updated.",
        agentDeleted: "Agent has been deleted.",
        agentCommissionUpdated: "Commission has been updated.",
        appicAccessGranted: 'APPiC access granted.',
        appicAccessEnabled: 'APPiC access enabled.',
        appicAccessRevoked: 'APPiC access revoked.',
        appicAccessResent: 'APPiC access invitation resent.',
        appicAccessSuspended: 'APPiC access suspended.',
        auditAdded: "Audit has been added.",
        auditUpdated: "Audit has been updated.",
        auditDeleted: "Audit has been deleted.",
        bankAccountAdded: "Bank account has been added.",
        bankAccountDeleted: "Bank account has been deleted.",
        bankAccountUpdated: "Bank account has been updated.",
        bundlesAdded: "Bundles have been added.",
        bundleAdded: "Bundle has been added.",
        bundleDeleted: "Bundle has been deleted.",
        bundleSplit: "Bundle has been split.",
        bundleUpdated: "Bundle has been updated.",
        bundlesUpdated: "Bundles has been updated.",
        buyerActivated: 'Buyer has been activated',
        buyerAdded: 'Buyer has been added.',
        buyerDeleted: 'Buyer has been deleted.',
        buyerSuspended: 'Buyer has been suspended.',
        buyersSuspended: 'Selected buyers have been suspended.',
        buyerUpdated: 'Buyer has been updated.',
        certificationAdded: "Certification has been added.",
        certificationDeleted: "Certification has been deleted.",
        certificationUpdated: "Certification has been updated.",
        clauseFilterAdded: 'Clause filter has been added.',
        commentsAdded: 'Comments has been added.',
        commentsDeleted: 'Comments have been deleted.',
        contactsIntegrated: 'Contacts have been integrated.',
        containerReportRequestSent: 'Container report request has been sent to the supplier.',
        contractAdded: 'Contract has been added.',
        contractCompleted: 'Contract has been closed.',
        contractCloned: 'Contract has been cloned.',
        contractDeleted: 'Contract has been cancelled.',
        contractReopened: 'Contract has been reopened.',
        contractRestored: 'Contract has been restored.',
        contractSent: 'Contract has been sent.',
        contractUpdated: 'Contract has been updated.',
        coverNoteAdded: 'Cover Note has been added.',
        coverNoteDeleted: 'Cover Note has been cancelled.',
        coverNoteUpdated: 'Cover Note has been updated.',
        countryAdded: 'Country has been added.',
        countryUpdated: 'Country has been updated.',
        countryDeleted: 'Country has been deleted.',
        courierInformationSent: 'The courier information has been sent to the buyer.',
        courierAdded: 'Courier has been added.',
        courierUpdated: 'Courier has been updated.',
        courierDeleted: 'Courier has been deleted.',
        currencyAdded: 'Currency has been added.',
        currencyUpdated: 'Currency has been updated.',
        currencyDeleted: 'Currency has been deleted.',
        customerConverted: 'Customer has been converted to supplier.',
        dataFilterAdded: 'Data filter has been added.',
        dataFilterUpdated: 'Data filter has been updated.',
        dataFilterDeleted: 'Data filter has been deleted.',
        dealNoteAdded: "Deal note has been added.",
        dealNoteDeleted: "Deal note has been deleted.",
        debitNoteAdded: 'Debit Note has been added.',
        debitNoteDeleted: 'Debit Note has been cancelled.',
        debitNotePosted: 'Debit Note has been queued for posting to GP.',
        debitNoteUpdated: 'Debit Note has been updated.',
        deliveryAddressAdded: 'Delivery address has been added.',
        deliveryAddressDeleted: 'Delivery address has been deleted.',
        deliveryAddressUpdated: 'Delivery address has been updated.',
        documentClauseAdded: 'Document/clause has been added.',
        documentClauseUpdated: 'Document/clause has been updated.',
        documentClauseDeleted: 'Document/clause has been deleted.',
        documentFooterAdded: 'Document footer has been added.',
        documentFooterUpdated: 'Document footer has been updated.',
        documentFooterDeleted: 'Document footer has been deleted.',
        documentFooterSortingUpdated: 'Document footer sorting has been updated',
        documentSent: 'Document has been sent.',
        documentsSent: 'Documents has been sent.',
        documentUploaded: 'Document uploaded.',
        downloadManualMoment: 'The help manual will be ready in a few seconds.',
        dueDateUpdated: "Due date updated.",
        emailVerified: "Email verified.",
        etdAdjustmentAdded: 'ETD adjustment has been added.',
        etdAdjustmentDeleted: 'ETD adjustment has been deleted.',
        etdAdjustmentUpdated: 'ETD adjustment has been updated.',
        eprAdded: 'Export Purchase Request has been created.',
        eprApproved: 'Export Purchase Request has been approved.',
        eprConverted: 'Export Purchase Request has been converted.',
        eprDeleted: 'Export Purchase Request has been cancelled.',
        eprRemarkSent: 'Export Purchase Request remark has been sent.',
        eprSubmitted: 'Export Purchase Request has been submitted.',
        eprUpdated: 'Export Purchase Request has been updated.',
        exchangeRateAdded: "Exchange rate has been added.",
        exchangeRateDeleted: "Exchange rate has been deleted.",
        exchangeRateUpdated: "Exchange rate has been updated.",
        exportExcelPleaseWaitForDownload: 'Your Excel file will be ready in a few seconds.',
        exportPdfPleaseWaitForDownload: 'Your PDF file will be ready in a few seconds.',
        exportedDirectionsReadyMoment: 'Your exported directions will be ready in a few seconds.',
        exportedListReadyMoment: 'Your exported file will be ready in a few seconds.',
        exportedMapReadyMoment: 'Your exported map will be ready in a few seconds.',
        filterDeleted: 'Filter has been deleted.',
        forwarderCostAdded: 'Forwarder cost has been added.',
        forwarderCostDeleted: 'Forwarder cost has been deleted.',
        forwarderCostUpdated: 'Forwarder cost has been updated.',
        gradeAdded: 'Grade has been added.',
        gradeConverted: 'Grade has been converted.',
        gradeDeleted: 'Grade has been deleted.',
        gradeUpdated: 'Grade has been updated.',
		grossMarginAdded: 'Gross margin calculation has been created.',
		grossMarginDeleted: 'Gross margin calculation has been deleted.',
        grossMarginExRateUpdated: 'Gross margin exchange rate has been updated.',
        grossMarginUpdated: 'Gross margin calculation has been updated.',
        grossMarginPricesUpdated: 'Gross margin prices has been updated.',
        helpPageSetupUpdated: "Help Page setup has been updated.",
		importDutyAdded: 'Import duty has been added.',
		importDutyDeleted: 'Import duty has been deleted.',
		importDutyUpdated: 'Import duty has been updated.',
        importPermitAdviceSent: 'Import permit advice has been sent.',
        incoTermAdded: 'Incoterm has been added.',
        incoTermDeleted: 'Incoterm has been deleted.',
        incoTermUpdated: 'Incoterm has been updated.',
        invoiceAdded: 'Invoice has been added.',
        invoiceDeleted: 'Invoice has been cancelled.',
        invoicePosted: 'Invoice has been queued for posting to GP.',
        invoiceSubmitted: 'Invoice has been submitted.',
        invoiceUpdated: 'Invoice has been updated.',
        invoiceUploaded: 'Invoice has been uploaded.',
        invoicesIntegrated: 'Invoices have been integrated.',
        ipaAdded: 'IPA has been added.',
        ipaDeleted: 'IPA has been cancelled.',
        ipaUpdated: 'IPA has been updated.',
        itemUpdated: 'Item has been updated.',
        itemsAdded: 'Items have been added.',
        itemsUpdated: 'Items have been updated.',
        itemSpecAdded: 'Item spec has been added.',
        itemSpecDeleted: 'Item spec has been cancelled.',
        itemSpecUpdated: 'Item spec has been updated.',
        jobDeleted: 'Job has been deleted.',
        lcaAdded: 'LCA has been added.',
        lcaDeleted: 'LCA has been cancelled.',
        lcaUpdated: 'LCA has been updated.',
        letterOfCreditAdviceSent: 'Letter of Credit Advice has been sent.',
        lhdnAdjusted: 'E-invoice has been adjusted.',
        lhdnAddedTo: 'E-invoice has been added to.',
        lhdnCancelled: 'E-invoice has been cancelled.',
        lhdnCreditNoteCreated: "LHDN credit note has been created.",
        lhdnDebitNoteCreated: "LHDN debit note has been created.",
        lhdnReverted: 'E-invoice has been reverted.',
        reportExported: "Report has been exported.",
        localSalesInvoiceAdded: 'Local Sales Invoice has been added.',
        localSalesInvoiceDeleted: 'Local Sales Invoice has been cancelled',
        localSalesInvoiceUpdated: 'Local Sales Invoice has been updated',
        loginSuccess: 'You successfully logged in to APPiC!',
        markAsPaid: 'Commissions have been marked as paid.',
        moistureContentAdded: 'Moisture content has been added.',
        moistureContentDeleted: 'Moisture content has been deleted.',
        moistureContentUpdated: 'Moisture content has been updated.',
        ncrAdded: 'Contract Request has been created.',
        ncrApproved: 'Contract Request has been approved.',
        ncrConverted: 'Contract Request has been converted.',
        ncrDeleted: 'Contract Request has been cancelled.',
        ncrRemarkSent: 'Contract Request remark has been sent.',
        ncrSubmitted: 'Contract Request has been submitted.',
        ncrUpdated: 'Contract Request has been updated.',
        mergedFileReadyMoment: 'Your merged PDF file will be ready in a few seconds.',
        nothingToUpdate: "Nothing to update.",
        noteAdded: "Note has been added.",
        noteDeleted: "Note has been deleted.",
        notificationsMarkProcessed: 'Notifications are marked as processed.',
        notificationSent: 'Notification sent.',
        otsLoginInfoSent: 'APPiC/OTS login info sent.',
        packingListAdded: 'Packing list has been added.',
        packingListDeleted: 'Packing list has been cancelled.',
        packingListUpdated: 'Packing list has been updated.',
        packingListUploaded: 'Packing list has been uploaded.',
        partialShipmentCreated: 'Partial Shipment has been created.',
        passwordReset: "Password has been reset.",
        paymentsUpdated: 'Payments have been updated.',
        paymentDateUpdated: 'Payment date has been updated.',
        paymentDueDateUpdated: 'Payment due date has been updated.',
        paymentTermAdded: 'Payment term has been added.',
        paymentTermDeleted: 'Payment term has been deleted.',
        paymentTermUpdated: 'Payment term has been updated.',
        pdfMergedPleaseWaitForDownload: 'Your merged PDF file will be downloaded in a few seconds...',
        pdfServerRebooted: 'PDF server has been rebooted.',
        portAdded: 'Port has been added.',
        portUpdated: 'Port has been updated.',
        portDeleted: 'Port has been deleted.',
        priceListSent: "Price list has been sent.",
        priceMarginAdded: 'Price margin has been added.',
        priceMarginDeleted: 'Price margin has been deleted.',
        priceMarginUpdated: 'Price margin has been updated.',
        productAdded: 'Product has been added.',
        productDeleted: 'Product has been deleted.',
        productGroupAdded: 'Product group has been added.',
        productGroupUpdated: 'Product group has been updated.',
        productGroupDeleted: 'Product group has been deleted.',
        productUpdated: 'Product has been updated.',
        propertyAdded: 'Property has been added.',
        propertyDeleted: 'Property has been deleted.',
        propertyUpdated: 'Property has been updated.',
        purchaseOrderAdded: 'PO has been added.',
        purchaseOrderCloned: 'PO has been cloned.',
        purchaseOrderDeleted: 'PO has been cancelled.',
        purchaseOrderLinked: 'PO has been linked.',
        purchaseOrderUpdated: 'PO has been updated.',
        remarkAdded: 'Remark has been added.',
        remarkDeleted: 'Remark has been deleted.',
        remarkUpdated: 'Remark has been updated.',
        salesBudgetAdded: 'Sales budget has been added.',
        salesBudgetDeleted: 'Sales budget has been deleted.',
        salesBudgetUpdated: 'Sales budget has been updated.',
        salesColleagueAdded: 'Sales Colleague has been added.',
        salesColleagueDeleted: 'Sales Colleague has been removed.',
        salesColleagueUpdated: 'Sales Colleague has been updated.',
        salesOrderAdded: "Sales Order has been added.",
        salesOrderDeleted: "Sales Order has been cancelled.",
        salesOrderSigned: "Sales Order has been signed.",
        salesOrderUpdated: 'Sales Order has been updated.',
        sdaAdded: 'Shipping Instruction has been added.',
        sdaDeleted: 'Shipping Instruction has been cancelled.',
        sdaUpdated: 'Shipping Instruction has been updated',
        settingUpdated: 'Setting has been updated',
        shipmentCompleted: 'Shipment has been completed.',
        shipmentUpdated: 'Shipment has been updated.',
        shippingPortAdded: 'Shipping port has been added.',
        shippingPortDeleted: 'Shipping port has been deleted.',
        shippingPortUpdated: 'Shipping port has been updated.',
        shippingInstructionSent: 'Shipping instruction has been sent.',
        slipSent: 'Payment slip has been sent to the supplier.',
        speciesAdded: 'Species has been added.',
        speciesDeleted: 'Species has been deleted.',
        speciesUpdated: 'Species has been updated.',
        specificationAdded: 'Specification has been added.',
        specificationDeleted: 'Specification has been deleted.',
        specificationUpdated: 'Specification has been updated.',
        standardShippingDocumentAdded: 'Standard shipping document has been added.',
        standardShippingDocumentDeleted: 'Standard shipping document has been deleted.',
        standardShippingDocumentUpdated: 'Standard shipping document has been updated.',
        standardShippingDocumentSortingUpdated: 'Standard shipping document sorting has been updated',
        stockArrivalAdded: 'Stock arrival has been added.',
        stockArrivalDeleted: 'Stock arrival has been cancelled.',
        stockArrivalUpdated: 'Stock arrival has been updated.',
        stockReserved: 'Stock reserved.',
        storageCostAdded: 'Storage cost has been added.',
        storageCostDeleted: 'Storage cost has been deleted.',
        storageCostUpdated: 'Storage cost has been updated.',
        supplierActivated: 'Supplier has been activated.',
        supplierAdded: 'Supplier has been added.',
        supplierAddedToGroup: 'Supplier has been added to group.',
        supplierConverted: 'Supplier has been converted to buyer.',
        supplierDeleted: 'Supplier has been deleted.',
        supplierGroupCreated: 'Supplier group has been created.',
        supplierLogoUpdated: 'Logo hase been updated.',
        supplierRemovedFromGroup: 'Supplier has been removed from group.',
        supplierSuspended: 'Supplier has been suspended.',
        supplierUpdated: 'Supplier has been updated.',
        thicknessAdded: 'Thickness has been added.',
        thicknessDeleted: 'Thickness has been deleted.',
        thicknessUpdated: 'Thickness has been updated.',
        transactionsMarkProcessed: 'Transactions are marked as processed.',
        userActivated: 'User has been activated.',
        userAdded: 'User has been added.',
        userDeleted: 'User has been deleted.',
        userLoggedOut: "User has been logged out.",
        userPasswordReset: 'User password has been reset.',
        userProfilePictureUpdated: 'Profile picture has been updated.',
        userSuspended: 'User has been suspended.',
        userUpdated: 'User has been updated.',
        userZohoReportAdded: 'User Zoho report has been added.',
        userZohoReportDeleted: 'User Zoho report has been deleted.',
        userZohoReportSortingUpdated: 'User Zoho reports sorting has been updated.',
        wprAdded: 'Warehouse purchase request has been added.',
        wprApproved: "Warehouse purchase request has been approved.",
        wprConverted: "Warehouse purchase request has been converted.",
        wprDeleted: 'Warehouse purchase request has been cancelled.',
        wprRejected: "Warehouse purchase request has been rejected.",
        wprSubmitted: 'Warehouse purchase request has been submitted.',
        wprUpdated: 'Warehouse purchase request has been updated.',
        zohoReportAdded: 'Zoho Report has been added.',
        zohoReportDeleted: 'Zoho Report has been deleted.',
        zohoReportUpdated: 'Zoho Report has been updated.'
    },
    suffix: "Suffix",
    suggestedETA: "Suggested ETA",
    suggestedETD: "Suggested ETD",
    suggestedETS: "Suggested ETS",
    suggestedSellingPrice: "Suggested Selling Price",
    suggestedSellingPriceLocal: "Suggested Selling Price (LC)",
    suggestedTariffCode: "Suggested Tariff Code",
    suggestPassword: "Suggest Password",
    summary: "Summary",
    supPi: "Spl PI",
    supPiNo: "Spl PI No",
    supplier: "Supplier",
    supplierContacts: "Supplier Contacts",
    supplierDocumentsReceivedTrackingNumber: "Supplier Documents Tracking Number",
    supplierDocumentsReceivedOn: "Supplier Documents Received On",
    supplierDocumentsSentVia: "Supplier Documents Received Via",
    supplierDueDate: "Supplier Due Date",
    supplierInvoice: "Supplier Invoice",
    supplierInvoiceDate: "Supplier Invoice Date",
    supplierInvoiceNumber: "Supplier Invoice Number",
    supplierInvoiceValue: "Supplier Invoice Value",
    supplierFormResetNotification: 'The supplier form has been reset!',
    supplierFullyPaid: "Supplier - Fully Paid",
    supplierList: "Supplier List",
    supplierMainAddress: 'Supplier Main Address',
    supplierName: "Supplier Name",
    supplierPayment: "Spl Payment",
    supplierPayments: "Payments to Supplier",
    supplierPaymentTerm: "Supplier Payment Term",
    supplierPaymentTerms: "Supplier Payment Terms",
    supplierPending: "Supplier - Pending",
    supplierPiNo: "Supplier PI No",
    supplierProfile: "Supplier Profile",
    supplierProfiles: "Supplier Profiles",
    suppliers: "Suppliers",
    suppliersRepresented: "Suppliers Represented",
    supplierRecords: 'This supplier has the following records:',
    supplierSpecificRequests: "Supplier specific requests",
    supplierTerms: "Supplier Terms",
    supportingDocuments: "Supporting Documents",
    surface: "Surface",
    surfaceEdges: 'Surface/Edges',
    surfaceEdgesSides: 'Surface/Edges/Sides',
    surfaceProfile: "Surface/Profile",
    surname: "Surname",
	sus: "Sus",
    suspend: "Suspend",
    suspended: "Suspended",
    suspendAccount: "Suspend Account",
    suspendBuyer: "Suspend Buyer",
    suspendBuyers: "Suspend Buyers",
    suspendSupplier: "Suspend Supplier",
    suspendSuppliers: "Suspend Suppliers",
    suspendUser: "Suspend User",
    swc: 'SWC',
	switchToBoardView: "Switch to Board View",
    switchToFilterThickness: 'switch to filter thickness list.',
    switchToFilterThicknessAndUnits: 'switch to filter thickness & units list.',
    swiftCode: "SWIFT Code",
    swiftId: "Swift ID",
    symbol: "Symbol",
    system: "System",
    systemCreateDate: "System Created",
    systemUpdateDate: "System Updated",
    auditLog: "Audit Log",
    tabular: "Tabular",
    tagAsOthers: "Tag as \"Others\"",
    tariffCode: "Tariff Code",
    tariffDescription: 'Tariff Description',
    tariffQty: "Tariff Quantity",
    taxAmount: "Tax Amount",
    taxAmt: "Tax Amt",
    taxId: "Tax ID",
    taxInvoice: "Tax Invoice",
    taxName: "Tax Name",
    taxNumber: "Tax Number",
    tba: "To be advised",
    team: "Team",
    tel: "Tel",
    telephone: "Telephone",
    template: "Template",
    temporaryCurrency: "Temporary Currency",
    temporaryExchangeRate: "Temporary Ex-Rate",
    temporaryExchangeRateDate: 'Temporary Ex-Rate Date',
    temporaryInvoice: "Temporary Invoice",
    exchangeRateDate: "Ex-Rate Date",
    term: "Term",
    terms: "Terms",
    termsDeliveryRemarks: "Terms of Delivery Remarks",
    termsOfPayment: "Terms of Payment",
    textPrefix: "Text Prefix",
    thankYou: "Thank you",
    thk: "Thk",
    theseUsersOpenedSamePage: "These users have also opened this page",
    thick: "Thick",
    thickness: "Thickness",
    thicknessMeasurement: "Thickness Measurement",
    thisMonth: "This Month",
    thisSupplier: "This Supplier",
    time: "Time",
    timeOut: "Timeout",
    timestamp: "Timestamp",
    title: "Title",
    titles: {
        agentCommissions: 'Agent Commissions',
        appicReport: 'APPiC Report',
        appicSettings: 'APPiC Settings',
        buyerLifeCycle: 'Buyer Lifecycle',
        buyerLocations: 'Buyer & Supplier Locations',
        buyer: 'Buyer',
        buyers: 'Buyers',
        contract: "Contract",
        contracts: "Contracts",
        contractRequests: 'Contract Requests',
        costOfSalesTool: 'C of S Tool',
        dashboard: 'Dashboard',
        dashboardReports: "Dashboard Reports",
        debitNote: 'Debit Note',
        eInvoices: 'E-Invoices',
        epr: 'EPR',
        eprs: 'EPRs',
        exportPurchaseRequests: 'EPRs',
        fscPefcReport: 'FSC & PEFC',
        financeData: 'Finance Data',
        financeDataManagement: 'Finance Data Mgmt',
        financeReports: "Finance Reports",
        grossMargin: "GM Calculation",
        grossMargins: "GM Calculations",
        hubSpotDeals: "HubSpot Deals",
        invoice: "Invoice",
        invoices: "Invoices",
        ipa: "IPA",
        lca: "LCA",
        lhdnInvoices: "E-Invoices",
        marketingReport: "Marketing Report",
        marketingReportDetail: "Marketing Report Detail",
        masterStockList: "Master Stock List",
        miscellaneous: "Miscellaneous",
        myDashboard: 'My Dashboard',
        ncr: "NCR",
        newBuyer: "New Buyer",
        newContract: "New Contract",
        newContractRequest: "New Contract Request",
        newCreditNote: "New Credit Note",
        newDebitNote: "New Debit Note",
        newEpr: "New EPR",
        newInvoice: "New Invoice",
        newIpa: "New IPA",
        newGrossMargin: "New GM Calculation",
        newLca: "New LCA",
        newNcr: "New NCR",
        newPayments: 'New Payments',
        newPackingList: 'New Packing List',
        newPo: "New PO",
        newSda: "New SI",
        newSo: "New SO",
        newStockArrival: "New Stock Arrival",
        newStockEntry: "New Stock Entry",
        newStockPr: "New Stock PR",
        newSupplier: "New Supplier",
        newUser: 'New User',
        newWPR: "New WPR",
        operationsData: 'Operations Data',
        operationsDataManagement: 'Operations Data Mgmt',
        packingList: 'Packing List',
        payment: 'Payment',
        payments: 'Payments',
        pdfServer: 'PDF Server',
        pendingOutboundNotifications: 'Pending Outbound Notifications',
        pendingTmsTransfers: 'Pending TMS Transfers',
        pl: 'PLs',
        po: "PO",
        pos: "POs",
        productTable: "Products",
        profitLossTool: "P & L Tool",
        purchaseOrders: "Purchase Orders",
        salesCommissions: 'Sales Commissions',
        salesDataManagement: 'Sales Data Mgt',
        salesOrders: 'Sales Orders',
        sda: 'SI',
        searchResults: 'Search Results',
        shipment: "Shipment",
        shipments: "Shipments",
        shippingDocuments: "Shipping Documents",
        so: 'SO',
        specificationsData: 'Specifications Data',
        specificationsDataManagement: 'Specifications Data Mgmt',
        standardDataFilters: 'Standard Data Filters',
        stockArrivals: 'Stock Arrivals',
        stockEntry: 'Stock Entry',
        stockPos: "Stock Entries",
        stockPr: 'Stock PR',
        stockPrs: 'Stock PRs',
        stockReports: 'Stock Reports',
        stocks: 'Stocks',
        supplier: 'Supplier',
        suppliers: 'Suppliers',
        updatePayments: 'Update Payments',
        user: 'User',
        userActivity: "User Activity",
        users: 'Users',
        warehouseStocks: 'WH Stocks',
        wpr: 'WPR',
        wprs: 'WPRs',
        xeroIntegrations: "Xero Integrations",
        zohoReports: 'Zoho Reports'
    },
    tms: "TMS",
	tmsPackingListConverted: "The TMS packing list has been converted. You can now update the items.",
    tmsPackingListNotConverted: "The TMS packing list could not be converted. Please contact the developer for support.",
    tmsCreateDate: "TMS Created",
    tmsDocuments: "TMS Documents",
    tmsTransfers: 'TMS Transfers',
    tmsUpdateDate: "TMS Updated",
    toFollow: "To follow",
    to: "To",
    toBeAdvised: "To Be Advised",
    toBePaidOn: "To Be Paid On",
    tocGroup: "TOC Grouping",
    toFollowAfterShipment: "to follow after shipment",
    to_follow_after_shipment: "to follow after shipment",
    toggleFullscreen: 'Toggle Fullscreen',
    topFive: 'Top 5',
    topTen: 'Top 10',
    topTwentyFive: 'Top 25',
    totalAmount: "Total Amount",
    totalArea: "Total Area",
    totalBundles: "Total Bundles",
    totalCFRValue: "Total CFR Value",
    totalCommission: 'Total Comm.',
    totalCommissionFull: 'Total Commission',
    totalCost: 'Total Cost',
    totalDownPayment: "Total DP",
    totalDue: "Total Due",
    totalExcess: "Total Excess",
    totalMargin: "Total Margin",
    totalMarginFob: "Total Margin (FOB)",
    totalPaid: "Total Paid",
    totalPieces: "Total Pieces",
    totalPoQty: 'Total PO Quantity',
    totalOfPackages: "Total of Packages",
    totalOutstanding: "Total Outstanding",
    totalQty: "Total Quantity",
    totalSheets: "Total Sheets",
    totalVolume: "Total Volume",
    totals: "Totals",
    totalsByAge: 'Totals by Age',
    totalsByProduct: "Totals by Product",
    totalValue: "Total Value",
    totalVolAndQty: "Total Vol/Qty",
    totalVol: "Total Volume",
    toYear: "To Year",
    toYearGreaterFromYear: "the \"to\" date must be later than the \"from\"2 date",
    trackingInformation: "Tracking Information",
    trackingIdPlaceholder: "Tracking ID Placeholder",
    trackingUrl: "Tracking URL",
    traderReferenceNumber: "Trader Reference Number",
    traderRemarks: "Trader Remarks",
    tradingValue: "Trading Value",
    transhipment: "Transhipment",
    transaction: "Transaction",
    transitTime: "Transit Time",
    triplicate: "triplicate",
    tvRvSummary: 'Trading Values / Revenue Summary',
    twoFactorEnabled: "Your account has two-factor authentication enabled. Enter your authentication code in the field below to continue.",
    twoFactorEmailEnabled: "Enter the verification code that was sent to your email address.",
    twoFactorCodeExpiry: "Your code will expire in 3 minutes.",
    type: "Type",
    typeSupplier: "Type Supplier",
    typeId: "Type ID",
    typeIfNone: "Type \"0\" if none",
    typeInsurance: "Type Ins.",
    typeAccountNo: "Enter the account number",
    typeNewBundleNo: "Enter the new bundle no manually.",
    typeNewPalletNo: "Enter the new pallet no manually.",
    typeToSearch: "Type to search",
    typeOfPackaging: "Type of Packaging",
    typeStatusText: "Type status text",
	updatedSdaUnsaved: "Updates not saved.",
    unableToAccessAppic: 'Unable to access APPiC',
    unit: 'Unit',
    unitOfMeasurement: 'Unit Of Measurement',
    unitCost: 'Unit Cost',
    unitDescription: 'Unit of Description',
    unitMargin: 'Unt Mrgn',
    unitPrice: "Unit Price",
    unknownError: 'Oops an unknown error occurred. Please check details and try again.',
    unlcode: "UNLOCODE",
    unlocode: "UN Code for Trade and Transport Locations",
    unpaid: "Unpaid",
    unpaidDueFromBuyer: "Unpaid Due (B)",
    unpaidDueToSupplier: "Unpaid Due (S)",
    updatePageSetup: "Update Page Setup",
    unposted: "Not posted",
    unrelated: "Unrelated",
    unshipped: "Unshipped",
    unsigned: "Unsigned",
    unsignedContract: "Unsigned Contract",
    unsignedPo: "Unsigned PO",
    unsignedWhPo: "Unsigned WH PO",
    uofm: 'UofM',
    updateAgent: "Update Agent",
    updateAllBundles: "Update all bundles",
    updateAppicSetting: "Update Appic Setting",
    updateAudit: "Update Audit",
    updateBankAccount: "Update Bank Account",
    updateBundle: "Update Bundle",
    updateBundles: "Update Bundles",
    updateBundlesCost: "Update Bundles Cost",
    updateBundlesSpecs: "Update Bundles Specs",
    updateBundleStatus: "Update Bundle Status",
    updateBuyer: "Update Buyer",
    updateBuyerRemark: "Update Buyer Remark",
    updateCertification: "Update Certification",
    updateComposition: "Update Composition",
    updateContract: "Update Contract",
    updateContractDirectly: "Update the contract directly!",
    updateContractRequest: "Update Contract Request",
    updateCountry: "Update Country",
    updateCourier: "Update Courier",
    updateCoverNote: "Update Cover Note",
    updateCurrency: "Update Currency",
    updateDebitNote: "Update Debit Note",
    updateDensity: "Update Density",
    updateDesignProfile: 'Update Design/Profile',
    updateDeliveryAddress: "Update Delivery Address",
    updateDocument: "Update Document",
    updateDocumentFooter: "Update Document Footer",
    updateDueDate: "Update Due Date",
    updateEtdAdjustment: "Update ETD Adjustment",
    updateExchangeRate: "Update Exchange Rate",
    updateEpr: "Update Export Purchase Request",
    updateFinishing: "Update Finishing",
    updateFreightInsuranceCost: "Update Freight, Insurance & Import/Transport Cost",
    updateForwarderCost: "Update Forwarder Cost",
    updateGlue: "Update Glue",
    updateGrade: "Update Grade",
    updateGrossMargin: "Update GM Calculation",
    updateGroup: "Update Group",
    updateImportDuty: "Update Import Duty",
    updateImportPermitAdvice: "Update Import Permit Advice",
    updateIncoTerm: "Update Incoterm",
    updateInvoice: "Update Invoice",
    updateIpa: "Update IPA",
    updateItem: "Update item",
    updateItemSpec: "Update Item Spec",
    updateLatePaymentCost: "Update Late Payment Cost",
    updateLca: "Update LCA",
    updateLength: "Update Length",
    updateLetterCreditAdvice: "Update Letter of Credit Advice",
    updateLocalSalesInvoice: "Update Local Sales Invoice",
    updateMoistureContent: "Update Moisture Content",
    updateNcr: "Update Contract Request",
    updatePackingList: "Update Packing List",
    updatePallet: "Update Pallet",
    updatePallets: "Update Pallets",
    updatePalletsCost: "Update Pallets Cost",
    updatePalletsSpecs: "Update Pallets Specs",
    updatePalletStatus: "Update Pallet Status",
    updatePaymentTerm: "Update Payment Term",
    updatePaymentTermCost: "Update Payment Term Cost",
    updatePayments: 'Update Payments',
    updatePriceMargin: "Update Price Margin",
    updatePrices: "Update Selling/Purchase Prices",
    updateProduct: "Update Product",
    updateProductGroup: "Update Product Group",
    updateProfilePicture: "Update Profile Picture",
    updatePurchaseOrder: "Update Purchase Order",
    updateRemark: 'Update Remark',
    updateRemarks: 'Update remarks',
    updateReport: "Update Report",
    updateSalesBudget: "Update Sales Budget",
    updateSalesColleague: "Update Sales Colleague",
    updateSalesOrder: "Update Sales Order",
    updateSda: "Update Shipping Instruction",
    updateSetting: "Update Setting",
    updateShipment: "Update Shipment",
    updateShippingInfo: "Please update the shipment information.",
    updateShippingInstruction: "Update Shipping Instruction",
    updateShippingPort: "Update Shipping Port",
    updateSpecies: "Update Species",
    updateStandardDataFilter: 'Update Standard Data Filter',
    updateStandardDocument: "Update Standard Document",
    updateStandardShippingDocument: 'Update standard shipping document',
    updateStatus: "Update Status",
    updateStock: "Update Stock",
    updateStockArrival: "Update Stock Arrival",
    updateStockEntry: "Update Stock Entry",
    updateStockOrder: "Update Stock Order",
    updateStockPo: "Update Stock PO",
    updateStockPurchaseRequest: "Update Stock Purchase Request",
    updateStorageCost: "Update Storage Cost",
    updateSupplier: "Update Supplier",
    updateSupplierLogo: "Update Supplier Logo",
    updateSurface: "Update Surface",
    updateThickness: "Update Thickness",
    updateUser: "Update User",
    updateWarehouseCost: "Update Warehouse Cost",
    updateWarehousePurchaseRequest: 'Update Warehouse Purchase Order',
    updateWhSalesInvoice: "Update WH Sales Invoice",
    updateWidth: "Update Width",
    updateWpr: "Update Warehouse Purchase Order",
    updatedBuyerUnsaved: "Updates not saved.",
    updatedContractUnsaved: "Updates not saved.",
    updatedEprUnsaved: 'Updates not saved.',
    updateExportPurchaseRequest: "Update Export Purchase Request",
    updatedGrossMarginUnsaved: "Updates not saved.",
    updatedInvoiceUnsaved: "Updates not saved.",
    updatedIpaUnsaved: "Updates not saved.",
    updatedLcaUnsaved: "Updates not saved.",
    updatedNcrUnsaved: 'Updates not saved.',
    updatedPackingListUnsaved: "Updates not saved.",
    updatedPaymentUnsaved: "Updates not saved.",
    updatedPurchaseOrderUnsaved: "Updates not saved.",
    updatedSalesOrderUnsaved: "Updates not saved.",
    updatedShipmentUnsaved: "Updates not saved.",
    updatedStockOrderUnsaved: "Updates not saved.",
    updatedSupplierUnsaved: "Updates not saved.",
    updateTableOfContents: "Update TOC",
    updatedUserUnsaved: 'Updates not saved.',
    updatedWprUnsaved: "Updates not saved.",
    updateZohoReport: "Update Zoho Report",
    updates: 'Updates',
    upload: "Upload",
    uploadBundlesByExcelFile: 'Upload bundles using excel file',
    uploadBundlesByTallySheet: 'Upload bundles using AI',
    uploadPalletsByExcelFile: 'Upload pallets using excel file',
    uploadedDocuments: 'uploaded documents',
    uploadFile: 'Upload File',
    uploadFiles: "Upload Files",
    uploaded: "Uploaded",
    uploadShippingDocs: 'Upload to Shipping Documents',
    uploadToXero: "Upload to Xero",
    uploadToXeroQueue: "Upload to Xero Queue",
    uscc: "USCC",
    usdOnly: "USD Only",
    useAlternateAddress: 'Use alternate address',
    useAppTimberAddress: "Use APP Timber address",
    useOtherStatement: "Use Other Statement",
    useSupplierLogo: "Use Supplier Logo",
    userAccess: "User Access Mgr",
    userAccessManager: "APPiC User Access Manager",
    userActive: "Active",
    userActivity: "User Activity",
    userActivityHistory: "User Activity History",
    userAppicPasswordRule: "Minimum six characters, at least one letter and one number. Please click \"Generate Password\" again.",
    userId: "User ID",
    userManagement: "User Mgmt",
    userRole: "User Role",
    userType: "User Type",
    usersAndAccess: "Users & Access",
    usersOverview: "Users Overview",
    validatedLhdn: "Validated by LHDN",
    validationLink: "Validation link",
    value: "Value",
    valueField: "Value Field",
    valueModel: "Value Model",
    valuePlaceholder: "Value Placeholder",
    values: "Values",
    valueDisplayField: "Value Display Field",
    valueGreaterZero: "The value must be > 0.",
    valueMissing: "Please provide a value.",
    valueMustBeValid: "Please provide a value.",
    vat: 'VAT',
    veneer: "Veneer",
    verify: "Verify",
    verifying: "...Verifying",
    verified: "Verified",
    verifyEmail: "Verify Email",
    emailVerified: "Email verified",
    version: "Version",
    vessel: "Vessel",
    vesselName: "Vessel Name",
    vesselNameSet: "Vessel set",
    vesselOrEtdSet: "Vessel or ETD week set",
    viewBuyer: "View Buyer",
    viewDocument: "View Document",
    viewEpr: "View Export Purchase Request",
    viewErrors: "View errors",
    viewGrossMargin: "View Gross Margin",
    viewInvoice: "View Invoice",
    viewReason: "View reason",
    viewNcr: "View Contract Request",
    viewPayments: "View Payments",
    viewPurchaseOrder: "View PO",
    viewSalesDocument: "View Sales Document",
    viewSlip: "View payment slip",
    viewSalesOrder: "View Sales Order",
    viewSupplier: "View Supplier",
    viewWpr: "View Warehouse Purchase Request",
    volume: "Volume",
    volAndQty: 'Vol/Qty',
    volumeMeasurement: "Volume Measurement",
    voyage: "Voyage",
    voyageNumber: 'Transport/Voyage/Flight/Conveyance No',
    warehouse: "Warehouse",
    warehouseCost: "Warehouse Cost",
    warehouseIncomingAndArrivals: "Warehouse Incoming/Arrivals",
    warehousePO: "WH PO",
    warehousePR: "Warehouse PR",
    warehousePricingVariables: 'WH Pricing Variables',
    warehouseProductGrouping: "Warehouse Product Grouping",
    warehousePurchaseRequest: "Warehouse Purchase Request",
    warehousePurchaseRequestOverview: "Warehouse Purchase Request Overview",
    warehousePurchaseRequestPreview: "Warehouse Purchase Request Preview",
    warehousePurchaseRequests: "Warehouse Purchase Requests",
    warehousePurchasesTerm: "WH Purchases Term",
    warehouseSales: "Warehouse Sales",
    warehouseSalesOnly: "Warehouse Sales Only",
    website: "Website",
    weHereServeYou: "we are here to serve you!",
    whatsapp: "WhatsApp",
    whCost: "WH Cost",
    whSalesMargins: "WH Sales Margins",
    whSalesOnly: "WH Sales Only",
    wdth: "Wdth",
    week: 'Week',
    wh: "WH",
    whPoUnsigned: "Unsigned WH PO",
    whEprPoUnsigned: 'Unsigned WH/EPR PO',
    whSales: "WH Sales",
    width: "Width",
    widths: "Widths",
    widthMeasurement: "Width Measurement",
    wprFormResetNotification: "The warehouse purchase request form has been reset!",
    wprConvertNote: "Note: The created PO will be incomplete. Please enter the missing information into the PO manually before sending out to the supplier.",
    wprConvertThisToPo: "Convert this WH Purchase Request to a PO",
    wprNo: "WPR No",
    wprs: "WPRs",
    year: "Year",
    yearOnYearResults: "Show year on year results",
    yearTotal: "Year Total",
    youHaveBeenInactive: 'You have been inactive for',
    youHaveBeenLoggedOut: 'You have been logged out.',
    youCanGetThisQuarter: "You can get this Qtr",
    yourCommissionThisQuarter: "Your Commission this Qtr",
    yourLocation: 'Your Location',
    yourMaxCommissionPotential: 'Your Maximum Commission Potential',
    yourNotes: "Your Notes",
    yourProfile: 'Your Profile',
    yourMissingGrossMargin: "Your missing GM",
    yourRequiredMargin: 'If you sell at this margin',
    yourMissingSales: 'You\'re required to sell',
    youWillLogOut: 'You will be logged out in',
    xero: "Xero",
    xeroConnectionTerminated: "Your connection to Xero was terminated. Please click the reconnect button to authorize APPiC before integrating.",
    xeroDatePosted: "Xero Date Posted",
    xeroIntegrations: "Xero Integrations",
    zoho: 'Zoho',
    zohoReports: 'Zoho Reports'
};
export default {
    ...spreadElements
}
