import { api } from "Api";
import {getField, updateField} from "vuex-map-fields";
import _ from 'lodash';

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        SalesOrder: {
            id: null,
            bank_id: null,
            contact_id: null,
            currency_id: 2,
            customer_id: null,
            date_signed: null,
            deliveryorder: null,
            destinatione_id: null,
            dodate: null,
            gstpercent: 0,
            gstrmrate: 1,
            headertaxschedule: null,
            issigned: 0,
            item_list_version: 1.1,
            json: {
                delivery_terms: null,
            },
            lmeasurement_id: null,
            localsale_id: null,
            longformat: null,
            marketsegment_id: null,
            measurement_id: null,
            ms_id: 1,
            office_id: 1,
            orderstatus_id: 1,
            origin_id: null,
            paymentstatement_id: null,
            paymentterm: null,
            paymentterm_id: null,
            pickup_date: null,
            productgroup_id: null,
            product_type: 1,
            releasedate: null,
            remarks: null,
            salescontact_id: null,
            sodate: null,
            supplier_id: null,
            title:null,
            tmeasurement_id: null,
            wmeasurement_id: null,
            Invoice: {
                id: null,
                title: '',
            }
        }
    }
}

const state = {
    allActiveSalesOrders: [],
    allClosedSalesOrders: [],
    //current SO data. Can either be a loaded existing SO or a new unsaved SO.
    current: {
        SalesOrder: {
            bank_id: null,
            contact_id: null,
            currency_id: 2,
            customer_id: null,
            date_signed: null,
            deliveryorder: null,
            destinatione_id: null,
            dodate: null,
            gstpercent: 0,
            gstrmrate: 1,
            headertaxschedule: null,
            id: null,
            json: {
                delivery_terms: null,
            },
            issigned: 0,
            item_list_version: 1.1,
            lmeasurement_id: null,
            localsale_id: null,
            longformat: null,
            marketsegment_id: null,
            measurement_id: null,
            ms_id: 1,
            office_id: 1,
            orderstatus_id: 1,
            origin_id: null,
            paymentstatement_id: null,
            paymentterm: null,
            paymentterm_id: null,
            pickup_date: null,
            productgroup_id: null,
            product_type: 1,
            releasedate: null,
            remarks: null,
            salescontact_id: null,
            sodate: null,
            supplier_id: null,
            title:null,
            tmeasurement_id: null,
            wmeasurement_id: null,
            Company: {
                id: null,
                title: '',
                GPCustomerNumber: ''
            },
            Customer: {
                id: null,
                title: '',
                company_id: ''
            },
            Invoice: {
                id: null,
                title: ''
            }
        },
        salesOrderBuyers: [],
        salesOrderSalesColleagues: [],
    },
    defaultPaymentStatementId: 4304, //TT before release
    update: {
        SalesOrder: {
            currency_id: 1,
            id: null,
            json: {},
            office_id: 1,
            item_list_version: 1.0,
            product_type: 1,
            Company: {},
            Customer: {},
            Invoice: {
                id: null,
                title: '',
            }
        }
    },
    newSalesOrderSaved: false,
    newSalesOrderUnsaved: false,
    updatedSalesOrderUnsaved: false,
    updatedSalesOrderSaved: false
}

const getters = {
    allActiveSalesOrders: state => {
        return state.allActiveSalesOrders
    },
    allUnInvoicedSalesOrders: state => {
        return state.allActiveSalesOrders.filter(salesorder => {
            return salesorder.Localsale.id === null
        })
    },
    getSalesOrderById: (state) => (id) => {
        let salesOrder = state.allActiveSalesOrders.find(salesorder => salesorder.Localsalesorder.id === id)
        return salesOrder
    },
    getField
}

const mutations = {
    resetState ( state ){
        console.log('Resetting sales orders...')
        state.allActiveSalesOrders = []
        state.allClosedSalesOrders = []
        state.current.salesOrderBuyers = []
        state.current.salesOrderSalesColleagues = []
    },
    resetCurrentState ( state ){
        state.current.SalesOrder = getDefaultState().SalesOrder
        state.current.SalesOrder.sodate = new Date().toISOString().substr(0, 10)
    },
    resetOverviewState ( state ){
        console.log('resetting')
        state.current.salesOrderBuyers = []
        state.current.salesOrderSalesColleagues = []
    },
    setAllActiveSalesOrders ( state, data ) {
        state.allActiveSalesOrders = data
    },
    setSalesOrderBuyers (state, data) {
        state.current.salesOrderBuyers = data;
    },
    setSalesOrderSalesColleagues ( state, data ) {
        state.current.salesOrderSalesColleagues = data;
    },
    setSalesOrderToBeUpdated ( state, data ) {
        state.update.SalesOrder = data.SalesOrder
        state.update.SalesOrder.json = JSON.parse(data.SalesOrder.json)

    },
    updateField
}

const actions = {
    cancelSalesOrderById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/localsalesorders/" + val)
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
                // .finally(() => {
                //     resolve('error')
                // })
        })
    },
    createSalesOrder ( {state, rootState} ) {
        let salesOrder = _.cloneDeep(state.current.SalesOrder)
        //TODO remove unneeded properties
        delete salesOrder.Agent
        delete salesOrder.Bank
        delete salesOrder.Company
        delete salesOrder.Customer
        delete salesOrder.Invoice

        return new Promise((resolve, reject) => {
            api
                .post('/localsalesorders',{
                        salesorder: salesOrder,
                        salesorderitems: rootState.appic.salesorderitem.current.SalesOrderItems
                    }
                )
                .then((response) => {
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .finally(()=>{
                //     resolve('error')
                // })
        })
    },
    getAllActiveSalesOrders ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/localsalesorders/active",{
                })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllSalesOrders ({commit}, payload) {
        let filterOptions = payload.filterOptions
        let conditions = []
        if (filterOptions.buyer) {
            let obj = {
                field: 'localsalesorders.customer_id',
                value: filterOptions.buyer
            }
            conditions.push(obj)
        }
        if(filterOptions.month && filterOptions.year){
            let endDate = new Date(filterOptions.year, filterOptions.month,0)
            let obj = {
                field: 'localsalesorders.sodate',
                value: {
                    from: [filterOptions.year.toString(),filterOptions.month.toString().padStart(2,'0'),'01'].join('-'),
                    to: [endDate.getFullYear(),(endDate.getMonth() + 1).toString().padStart(2,'0'), endDate.getDate().toString().padStart(2,'0')].join('-')
                }
            }
            conditions.push(obj)
        } else if(filterOptions.month == null && filterOptions.year){
            let obj = {
                field: 'localsalesorders.sodate',
                value: {
                    from: [filterOptions.year.toString(),'01','01'].join('-'),
                    to: [filterOptions.year.toString(),'12','31'].join('-')
                }
            }
            conditions.push(obj)
        }
        if (filterOptions.salesColleague) {
            let obj = {
                field: 'localsalesorders.salescontact_id',
                value: filterOptions.salesColleague
            }
            conditions.push(obj)
        }
        if (filterOptions.office) {
            let obj = {
                field: 'localsalesorders.office_id',
                value: filterOptions.office
            }
            conditions.push(obj)
        }
        return new Promise((resolve, reject) => {
            api
                .get("/localsalesorders",{
                    params: {
                        conditions: conditions,
                        order: [
                            {
                                field: 'localsalesorders.sodate',
                                direction: 'ASC'
                            },
                        ]
                    }
                })
                .then(response => {
                    let tableData = {
                        rows: response.data.data,
                        totalRows: response.data.totalRows
                    }
                    resolve(tableData)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllSalesOrderBuyers ( {commit} ) {
        return new Promise((resolve, reject) => {
            api
                .get('/localsalesorders/buyers')
                .then(response=>{
                    commit('setSalesOrderBuyers',response.data.data)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getAllSalesOrderSalesColleagues ( {commit} ) {
        return new Promise((resolve, reject) => {
            api
                .get('/localsalesorders/sales-colleagues')
                .then(response=>{
                    commit('setSalesOrderSalesColleagues',response.data.data)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getSalesOrderToUpdateById ( {commit}, salesorder_id) {
        return new Promise((resolve, reject) => {
            api
                .get('/localsalesorders/' + salesorder_id)
                .then(response => {
                    commit('setSalesOrderToBeUpdated', response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    resetAllSalesOrder ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
        })
    },
    resetAllActiveSalesOrders ( {commit} ) {
        return new Promise ((resolve, reject) => {
            resolve('done')
        })
    },
    resetCurrentSalesOrder ( {commit} ) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    resetOverviewState ( {commit} ) {
        commit('resetOverviewState')
    },
    updateSalesOrder ( {state, rootState} ) {
        let salesOrder = _.cloneDeep(state.update.SalesOrder)
        //remove unneeded properties
        delete salesOrder.Agent
        delete salesOrder.Bank
        delete salesOrder.Company
        delete salesOrder.Contact
        delete salesOrder.Customer
        delete salesOrder.Invoice
        delete salesOrder.Office
        delete salesOrder.Paymentstatement
        delete salesOrder.Salescontact
        delete salesOrder.Status

        return new Promise((resolve, reject) => {
            api
                .put('/localsalesorders/' + state.update.SalesOrder.id,{
                        salesorder: salesOrder,
                        salesorderitems: rootState.appic.salesorderitem.update.SalesOrderItems
                    }
                )
                .then((response)=>{
                    this.updatedSalesOrderSaved = true
                    this.updatedSalesOrderUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                // .finally(()=>{
                //     resolve('done')
                // })
        })
    }
}

export const salesorder = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}